import { IAddress } from '@/types/address.d'
import { EPermission } from '@/types/permission.d'

import { IAccount } from './account.d'
import { IFile } from './file.d'
import { IParams } from './param.d'
import { IUserLight } from './user.d'

interface ICardLight {
  uuid: string
  digits: string
}

export enum ECardStatus {
  StatusActive = 'active',
  StatusBlocked = 'blocked',
  StatusCancelled = 'cancelled',
  StatusLocked = 'locked',
  StatusLockedPartner = 'locked_partner',
  StatusLockedProof = 'locked_proof',
  StatusLockedService = 'locked_service',
  StatusPending = 'pending',
  StatusToValidate = 'to_validate',
  StatusToValidateByBank = 'to_validate_by_bank',
}

export enum EPhysicalCardType {
  Card = 'card',
  GoldCard = 'gold_card',
  CardConsumer = 'card_consumer',
  InfiniteCardConsumer = 'infinite_card_consumer',
  PremierCardConsumer = 'premier_card_consumer',
}

export enum EClassicVirtualCardType {
  VirtualCard = 'virtual_card',
  VirtualCardConsumer = 'virtual_card_consumer',
}

export enum EOneTimeVirtualCardType {
  OneTimeVirtualCard = 'one_time_virtual_card',
  OneTimeVirtualCardConsumer = 'one_time_virtual_card_consumer',
}

export enum ECappedVirtualCardType {
  CappedVirtualCard = 'capped_virtual_card',
  CappedVirtualCardConsumer = 'capped_virtual_card_consumer',
}

export enum ERecurringVirtualCardType {
  RecurringVirtualCard = 'recurring_virtual_card',
  RecurringVirtualCardConsumer = 'recurring_virtual_card_consumer',
}

export const EVirtualCardType = {
  ...EClassicVirtualCardType,
  ...EOneTimeVirtualCardType,
  ...ERecurringVirtualCardType,
  ...ECappedVirtualCardType
}

export type EVirtualCardType = (typeof EVirtualCardType)[keyof typeof EVirtualCardType]

export function isPhysicalCardType (type: string) {
  return Object.values(EPhysicalCardType).includes(type)
}

export function isVirtualCardType (type: string) {
  return Object.values(EVirtualCardType).includes(type)
}

export function isClassicVirtualCardType (type: string) {
  return Object.values(EClassicVirtualCardType).includes(type)
}

export function isOneTimeVirtualCardType (type: string) {
  return Object.values(EOneTimeVirtualCardType).includes(type)
}

export function isCappedVirtualCardType (type: string) {
  return Object.values(ECappedVirtualCardType).includes(type)
}

export function isRecurringVirtualCardType (type: string) {
  return Object.values(ERecurringVirtualCardType).includes(type)
}

export interface ICardsParams extends IParams {
  account_uuid: string
  type?: (EPhysicalCardType | EVirtualCardType)[]
  status?: ECardStatus[]
  expiration_date?: string
}

export interface ICard {
  uuid: string
  first_name: string
  last_name: string
  digits: string
  type: EPhysicalCardType | EVirtualCardType
  expiration_date: string
  status: ECardStatus
}

export interface ICardData {
  uuid: string
  name: string
  digits: string
  type: EPhysicalCardType | EVirtualCardType
  expiration_date: string
  status: ECardStatus
}

// Security setting
export enum ECardSecuritySetting {
  Nfc = 'nfc',
  MobilePayment = 'mobile_payment',
  MagneticStrip = 'magnetic_strip',
  Atm = 'atm',
  DistanceSelling = 'distance_selling',
  Fx = 'fx',
}

export interface ICardSecuritySetting {
  [ECardSecuritySetting.Nfc]: boolean
  [ECardSecuritySetting.MobilePayment]: boolean
  [ECardSecuritySetting.MagneticStrip]: boolean
  [ECardSecuritySetting.Atm]: boolean
  [ECardSecuritySetting.DistanceSelling]: boolean
  [ECardSecuritySetting.Fx]: boolean
  [key in ECardSecuritySetting]: boolean
}

// Limit setting
export enum ECardPeriod {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Annually = 'annually'
}

interface ICardTransactionSetting {
  enabled: boolean
  limit: number
  consumed?: number
}

interface IPaymentWithdrawalSetting {
  [ECardPeriod.Daily]: ICardTransactionSetting
  [ECardPeriod.Weekly]: ICardTransactionSetting
  [ECardPeriod.Monthly]: ICardTransactionSetting
  [ECardPeriod.Annually]: ICardTransactionSetting
}

export interface ICardLimitSetting {
  amount?: number
  initial_amount?: number
  payment?: IPaymentWithdrawalSetting
  withdrawal?: IPaymentWithdrawalSetting
  payment_transaction?: ICardTransactionSetting
  withdrawal_transaction?: ICardTransactionSetting
  display_limit: boolean
}

// Control setting
enum ECardControlType {
  Account = 'account',
  Card = 'card',
  None = 'none',
}

enum ECardControlApplyDelay {
  Instant = 'instant',
  OneDay = 'one_day',
  TwoDay = 'two_day',
  OneWeek = 'one_week',
}

enum ECardControlFeature {
  Operation = 'operation'
}

interface ICardControlParamCategory {
  id: number
  label: string
  name: string
  default: boolean
  resource_type: string
  card: boolean
  transfer: boolean
  payment: boolean
  refund: boolean
  direct_debit: boolean
}

interface ICardControlParam {
  enabled: boolean
  name: string
  label: string
  params: { categories: ICardControlParamCategory[] } | null
}

export interface ICardControlSetting {
  type: ECardControlType
  uuid: string
  apply_from: number
  apply_delay: ECardControlApplyDelay
  apply_delay_label: string
  feature: ECardControlFeature
  params: ICardControlParam[] | null
}

// Alert setting
export interface ICardAlertSetting {
  alert_limit_enabled: boolean
  alert_spent: {
    limit: number
    enabled: boolean
  }
  alert_card_used_enabled: boolean
}

export enum ESmartCardOption {
  File = 'file',
  Link = 'link',
  Vcard = 'vcard',
  Operation = 'operation',
}

interface ISmartCardVcard {
  first_name: string | null
  last_name: string | null
  company_name: string | null
  role: null
  email: string | null
  phone: string | null
  phone2: string | null
  picture: IFile | null
  social_media: {
    linkedin: string | null
    twitter: string | null
  }
  website: string | null
}

export interface ICardSmartCardSetting {
  smart_card: boolean
  smart_card_file: IFile | null
  smart_card_link: string
  smart_card_option: ESmartCardOption
  smart_card_vcard: ISmartCardVcard
}

// Authorization category setting
export enum EAuthorizationCategoryPaymentPeriod {
  PaymentUnitLimit = 'payment_unit_limit',
  PaymentDailyLimit = 'payment_daily_limit',
  PaymentWeeklyLimit = 'payment_weekly_limit',
  PaymentMonthlyLimit = 'payment_monthly_limit',
  PaymentAnnuallyLimit = 'payment_annually_limit',
}

interface IAuthorizationCategoryLimit {
  [EAuthorizationCategoryPaymentPeriod.PaymentUnitLimit]: number|null
  [EAuthorizationCategoryPaymentPeriod.PaymentDailyLimit]: number|null
  [EAuthorizationCategoryPaymentPeriod.PaymentWeeklyLimit]: number|null
  [EAuthorizationCategoryPaymentPeriod.PaymentMonthlyLimit]: number|null
  [EAuthorizationCategoryPaymentPeriod.PaymentAnnuallyLimit]: number|null
}

interface IAuthorizationCategory {
  name: string
  enabled: boolean
  limit: IAuthorizationCategoryLimit
  label: string
}

export type ICardAuthorizationCategorySetting = {
  authorization_categories: IAuthorizationCategory[]
}

// Range setting
enum EDay {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

interface ICardAuthorizationDaySetting {
  enabled: boolean
  start: string
  end: string
}

export interface ICardAuthorizationRangeSetting {
  authorization_range: {
    [EDay.Monday]: ICardAuthorizationDaySetting
    [EDay.Tuesday]: ICardAuthorizationDaySetting
    [EDay.Wednesday]: ICardAuthorizationDaySetting
    [EDay.Thursday]: ICardAuthorizationDaySetting
    [EDay.Friday]: ICardAuthorizationDaySetting
    [EDay.Saturday]: ICardAuthorizationDaySetting
    [EDay.Sunday]: ICardAuthorizationDaySetting
  }
  non_working_day: boolean
  country_holidays: string
}

// Advanced setting
export interface ICardAdvancedSetting {
  blocked_keywords: string[]
  authorization_merchants: string[]
  auto_block_spent: {
    enabled: boolean
    limit: number
  }
  limit_transaction: IPaymentWithdrawalSetting
  blocked_countries: string[]
  check_countries: string[]
}

interface ICardSetting {
  payment: IPaymentWithdrawalSetting
  withdrawal: IPaymentWithdrawalSetting
}

export interface ICardDetails extends ICard {
  account: IAccount
  last_digits: string
  activated_at: number | null
  created_at: number
  settings?: ICardSetting
  user?: IUserLight
  address: {
    uuid: string
    street: string
    details: string
    city: string
    zip: string
    country: string
  }
  permissions: EPermission[]
}

export enum ECardAction {
  Lock = 'lock',
  Unlock = 'unlock'
}

export interface ICardOrderForm {
  address?: IAddress
  settings: {
    initial_amount?: number | null
    payment?: {
      [key: string]: {
        enabled: boolean | null
        consumed: number | null
        limit: number | null
      }
    }
    withdrawal?: {
      [key: string]: {
        enabled: boolean | null
        consumed: number | null
        limit: number | null
      }
    }
  }
  worldline_membership_fee?: string
  type: EPhysicalCardType | EVirtualCardType | null
  user_uuid: string
  account_uuid: string
  months_before_expiration?: number
}
