<template>
  <id-layout v-if="cardDetails"
             :badges="[cardDetails?.status || '']"
             :badge-resource="EBadgeResource.Cards"
             go-back-route="account-cards"
             :go-back-route-params="{ uuid: cardDetails?.account.uuid }"
             :title="title"
             :content-loaded="!!cardDetails">
    <template #view>
      <router-view :card-details="cardDetails"
                   :has-bank-validation-request="hasBankValidationRequest" />
    </template>
    <template v-if="items.length"
              #actions>
      <core-button-actions :append-icon="ECoreIcon.BoChevronDown"
                           :theme="ECoreButtonTheme.GrayHigh"
                           data-cy="card-detail.button-actions"
                           :text="t('general.action')"
                           :items="items" />
    </template>
  </id-layout>
</template>

<script setup lang="ts">
import { Component, computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButtonActions, ECoreButtonTheme, ECoreIcon } from '@common/core-ui'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'

import { EBadgeResource } from '@/helpers/badge'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ICardDetails, isVirtualCardType } from '@/types/card.d'
import { ECardEvent } from '@/types/eventBus.d'
import { EResponseHeader } from '@/types/headers.d'
import { EPermission } from '@/types/permission.d'

import ModalCardBlock from '@/pages/cards/modal/ModalCardBlock.vue'
import ModalCardLock from '@/pages/cards/modal/ModalCardLock.vue'
import ModalCardUnlock from '@/pages/cards/modal/ModalCardUnlock.vue'

import IdLayout from '@/components/layout/IdLayout.vue'

const { t } = useI18n()
const cardStore = useCardStore()
const appStore = useAppStore()
const props = defineProps<{
  uuid: string
}>()

const cardDetails = ref<ICardDetails | null>(null)
const responseHeaders = ref<RawAxiosResponseHeaders | AxiosResponseHeaders | null>(null)

onMounted(async () => {
  const { data, headers } = await cardStore.getCard(props.uuid)
  cardDetails.value = data
  responseHeaders.value = headers
  bus.on(ECardEvent.Lock, updateCardDetails)
  bus.on(ECardEvent.Unlock, updateCardDetails)
  bus.on(ECardEvent.Block, updateCardDetails)
})

onUnmounted(() => {
  bus.off(ECardEvent.Lock, updateCardDetails)
  bus.off(ECardEvent.Unlock, updateCardDetails)
  bus.off(ECardEvent.Block, updateCardDetails)
})

const title = computed(() => {
  const fullName = `${cardDetails.value?.first_name} ${cardDetails.value?.last_name}`
  return `${t('general.card_of_user', { name: fullName })} **${cardDetails.value?.last_digits}`
})

const items = computed(() => {
  return [
    {
      label: t('action.card_lock'),
      icon: ECoreIcon.BoLock,
      onClick: () => showModal(ModalCardLock),
      permission: hasCardPermission(EPermission.CardLock)
    },
    {
      label: t('action.card_unlock'),
      icon: ECoreIcon.BoUnlock,
      onClick: () => showModal(ModalCardUnlock),
      permission: hasCardPermission(EPermission.CardUnlock)
    },
    {
      label: actionBlockLabel.value,
      icon: ECoreIcon.BoSlash,
      theme: ECoreButtonTheme.Danger,
      onClick: () => showModal(ModalCardBlock),
      permission: hasCardPermission(EPermission.CardBlock)
    }
  ].filter(item => item.permission)
})

const actionBlockLabel = computed(() => {
  return cardDetails.value?.type && isVirtualCardType(cardDetails.value.type)
    ? t('action.card_delete')
    : t('action.card_block')
})

const hasBankValidationRequest = computed(() => {
  return !!responseHeaders.value?.[EResponseHeader.BankValidationRequest]
})

function hasCardPermission (permission: EPermission) {
  if (!cardDetails.value) return false
  return hasPermission(permission, cardDetails.value)
}

function showModal (modal: Component) {
  appStore.showModal(modal, {
    card: cardDetails.value
  })
}

function updateCardDetails (updatedCard: ICardDetails) {
  cardDetails.value = updatedCard
}
</script>
