import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import { EInvitationEvent } from '@/types/eventBus.d'
import { IInvitation, IInvitationDetails, IInvitationParams, IInvitationRights } from '@/types/invitation.d'

const { t } = i18n.global

export const useInvitationStore = defineStore('invitation', {

  actions: {
    async getInvitations (params: IInvitationParams): Promise<{ data: IInvitation[], headers: RawAxiosResponseHeaders | AxiosResponseHeaders, error: boolean }> {
      try {
        const response = await axiosClient.get('/invitations', { params })

        return { data: response.data, headers: response.headers, error: false }
      } catch (error) {
        console.error(error)
        return { data: [], headers: {}, error: true }
      }
    },

    async getInvitationDetails (uuid: string, account_uuid: string): Promise<{data: IInvitationDetails | null, headers: RawAxiosResponseHeaders | AxiosResponseHeaders}> {
      try {
        const { data, headers } = await axiosClient.get(`/invitations/${uuid}`, { params: { account_uuid } })
        return { data, headers }
      } catch (error) {
        console.error(error)
        return { data: null, headers: {} }
      }
    },

    async getInvitationRights (uuid: string): Promise<IInvitationRights | null> {
      try {
        const { data } = await axiosClient.get(`/invitations/${uuid}/rights`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async resendInvitation (uuid: string): Promise<IInvitationDetails | null> {
      try {
        const { data } = await axiosClient.patch(`/invitations/${uuid}/renew`)
        bus.emit(EInvitationEvent.Resend, data)
        showToastSuccess(t('success.resend_invitation'))
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    }
  }
})
