<template>
  <div :class="[hasAnySlot() ? 'settings--line' : 'settings--section',
                { 'settings--header': hasHeader() }, { 'settings--header--input': hasHeader() && hasInput() }]">
    <span class="title">
      {{ title }}
    </span>
    <div v-if="hasAnySlot()"
         class="settings__content">
      <slot name="input-header" />
      <div class="settings__input-wrapper">
        <div class="settings__input-container">
          <slot name="input" />
        </div>
        <slot name="input-addon" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

defineProps<{
  title: string
}>()

const slots = useSlots()

function hasHeader () { return !!slots['input-header'] }
function hasInput () { return !!slots.input }
function hasAddon () { return !!slots['input-addon'] }
function hasAnySlot () { return hasHeader() || hasInput() || hasAddon() }
</script>

<style scoped lang="stylus">
.settings
  &--line
    display flex
    align-items center
    height 6.8rem
    padding 1.4rem 0

    & .title
      width 24rem
      font-size 1.55rem
      color $color-gray-800

  &--section
    display flex
    align-items center
    height 3.7rem
    padding 1rem 0
    margin-top 2.8rem

    & .title
      font-size 1.3rem
      font-weight 500
      color $color-gray-500
      text-transform uppercase

  &--header
    align-items flex-start
    margin 2rem 0

    &--input
      height 12.2rem

  &__content
    display flex
    flex-direction column
    flex 1
    gap 2.4rem

  &__input-wrapper
    display flex
    align-items center
    gap 2rem

  &__input-container
    max-width 45rem
    flex 1
</style>
