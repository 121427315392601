<template>
  <core-dropdown :model-value="categories"
                 min-width="100%"
                 clearable
                 :size="ECoreDropdownSize.Small"
                 :items="getCategoriesFilter()"
                 :placeholder="t('placeholder.type')"
                 @update:model-value="updateFilterCategories" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownValue } from '@common/core-ui'

import { useOperationStore } from '@/stores/operation'

const { t } = useI18n()
const operationsStore = useOperationStore()
const categoriesLabel = ref<{ label: string, value: string }[]>([])

defineProps<{
  categories?: string[]
}>()

onMounted(async () => {
  categoriesLabel.value = await operationsStore.getCategories()
})

const emit = defineEmits<{
  'update:categories': [value: TDropdownValue[]]
}>()

function updateFilterCategories (item: TDropdownValue) {
  emit('update:categories', item ? [item] : [])
}

function getCategoriesFilter () {
  return categoriesLabel.value.map((category, index) => {
    return { id: index, label: category.label, value: category.value }
  })
}
</script>
