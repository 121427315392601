import { merge } from 'lodash'

import { TBrandConfig, TConfig } from '@/config/config.d'

import bred from './brand/bred'
import qnb from './brand/qnb'
import sbe from './brand/sbe'
import globalConfig from './global'

const brandConfig: TBrandConfig = {
  bred,
  qnb,
  sbe
}[(import.meta.env.VITE_CLIENT_BRAND) as string] || bred

const config: TConfig = merge(globalConfig, brandConfig)

export default config
