<template>
  <details-block data-cy="card.settings.control-rules"
                 :title="t('menu.control_rules')"
                 :loading="loading">
    <details-line>.</details-line>
    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveControlSetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, ECoreButtonTheme } from '@common/core-ui'
import _ from 'lodash'

import { useCardStore } from '@/stores/card'
import { ICardControlSetting } from '@/types/card.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
}>()

const loading = ref<boolean>(true)
const controlSetting = ref<ICardControlSetting | null>(null)
const controlSettingModel = ref<ICardControlSetting | null>(null)

onMounted(async () => {
  controlSetting.value = await cardStore.getCardControlSetting(props.cardUuid)
  controlSettingModel.value = _.cloneDeep(controlSetting.value)
  loading.value = false
})

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(controlSetting.value, controlSettingModel.value)
})

function cancel () {
  controlSettingModel.value = _.cloneDeep(controlSetting.value)
}

async function saveControlSetting () {
  if (!controlSettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardControlSetting(props.cardUuid, controlSettingModel.value)
  if (data) controlSettingModel.value = data
  loading.value = false
}
</script>
