<template>
  <login-block :title="t('password.recovery.title')"
               data-cy="password.recovery.container"
               :subtitle="t('password.recovery.subtitle')">
    <template #form>
      <ValidationForm v-slot="{ isSubmitting }"
                      :validation-schema="schema"
                      @invalid-submit="onInvalidSubmit"
                      @submit="onSubmit">
        <div>
          <input-text v-model:model-value="values.email"
                      name="email"
                      data-cy="password.recovery.email"
                      :placeholder="t('placeholder.email')" />
        </div>
        <core-button block
                     data-cy="password.recovery.submit"
                     :loading="isSubmitting"
                     :size="ECoreButtonSize.Large"
                     :text="t('action.send')"
                     type="submit" />
      </ValidationForm>
    </template>
    <template #link>
      <router-link :to="{ name: 'login' }"
                   class="core-link core-link--theme-gray-light">
        {{ t('general.back') }}
      </router-link>
    </template>
  </login-block>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, ECoreButtonSize } from '@common/core-ui'
import * as yup from 'yup'

import router from '@/router'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import LoginBlock from '@/pages/login/components/LoginBlock.vue'

import InputText from '@/components/inputs/InputText.vue'

const { t } = useI18n()

// DATA
const appStore = useAppStore()
const authStore = useAuthStore()

const schema = yup.object({
  email: yup.string().required().email()
})

const values = reactive({
  email: import.meta.env.VITE_LOGIN_USER || ''
})

function onInvalidSubmit () {
  appStore.showBannerError(t('error.login'))
}

async function onSubmit () {
  const success = await authStore.passwordRecovery(values.email)
  if (success) {
    await router.push({ name: 'login' })
  }
}
</script>
