<template>
  <sidepanel-block :title="t('general.card_limits', 2)"
                   collapsable>
    <template #content>
      <core-loader v-if="loading" />
      <template v-else-if="limitSetting">
        <template v-if="isRecurringCard">
          <sidepanel-line :label="t('general.periodicity')"
                          :data="t(`general.${periodicity}`)" />
          <sidepanel-line :label="t('general.ceiling')"
                          :data="formatAmountLimit(limit)" />
        </template>
        <template v-else>
          <sidepanel-line v-if="limitSetting.initial_amount"
                          :label="t('card.setting.initial_amount')"
                          :data="formatAmountLimit(limitSetting.initial_amount)" />
          <sidepanel-line v-if="limitSetting.payment_transaction && limitSetting.payment"
                          :label="t('general.payment_limit', 2)"
                          :data-list="getLimits(limitSetting.payment_transaction, limitSetting.payment)" />
          <sidepanel-line v-if="limitSetting.withdrawal_transaction && limitSetting.withdrawal"
                          :label="t('general.withdrawal_limit', 2)"
                          :data-list="getLimits(limitSetting.withdrawal_transaction, limitSetting.withdrawal)" />
        </template>
      </template>
    </template>
  </sidepanel-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreLoader } from '@common/core-ui'

import { formatAmount } from '@/helpers/utils/number'
import { useCardStore } from '@/stores/card'
import {
  ECardPeriod,
  ICardDetails,
  ICardLimitSetting,
  ICardTransactionSetting,
  IPaymentWithdrawalSetting, isRecurringVirtualCardType
} from '@/types/card.d'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'
const { t } = useI18n()

const cardStore = useCardStore()

const props = defineProps<{
  card: ICardDetails
}>()

const limitSetting = ref<ICardLimitSetting | null>(null)
const currency = { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
const loading = ref<boolean>(true)

const getLimits = function (transactionSetting: ICardTransactionSetting, periodSetting: IPaymentWithdrawalSetting) {
  return transactionSetting && periodSetting
    ? [
        {
          label: t('general.per_transaction'),
          value: formatAmountLimit(transactionSetting.limit, transactionSetting.enabled)
        },
        {
          label: t('general.per_day'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Daily].limit, periodSetting[ECardPeriod.Daily].enabled)
        },
        {
          label: t('general.per_week'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Weekly].limit, periodSetting[ECardPeriod.Weekly].enabled)
        },
        {
          label: t('general.per_month'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Monthly].limit, periodSetting[ECardPeriod.Monthly].enabled)
        },
        {
          label: t('general.per_year'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Annually].limit, periodSetting[ECardPeriod.Annually].enabled)
        }
      ]
    : []
}

function formatAmountLimit (amount?: number, enabled = true) {
  if (amount === undefined || !enabled) return t('general.none_m')
  return formatAmount(amount, currency)
}

onMounted(async () => {
  const { data } = await cardStore.getCardLimitSetting(props.card.uuid)
  if (data) {
    limitSetting.value = data
  }
  loading.value = false
})

// Recurring card
const isRecurringCard = isRecurringVirtualCardType(props.card.type)
const periodicity = computed(() => {
  return limitSetting.value ? Object.entries(limitSetting.value.payment).find(([key, value]) => key && value.enabled)?.[0] as ECardPeriod || null : null
})
const limit = computed(() => limitSetting.value?.payment[periodicity.value].limit)
</script>
