import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'

import { EResponseHeader } from '@/types/headers.d'
import { Pagination } from '@/types/pagination.d'

export function parsePagination (headers: RawAxiosResponseHeaders | AxiosResponseHeaders): Pagination {
  return {
    current: parseInt(headers[EResponseHeader.PaginationCurrentPage] || '1'),
    count: parseInt(headers[EResponseHeader.PaginationPageCount] || '1'),
    perPage: parseInt(headers[EResponseHeader.PaginationPerPage] || '50'),
    totalItems: parseInt(headers[EResponseHeader.PaginationTotalCount] || '0')
  }
}

export const defaultPagination: Pagination = {
  current: 1,
  count: 1,
  perPage: 50,
  totalItems: 0
}
