<template>
  <id-layout :badges="[userDetails?.status || '']"
             :badge-resource="EBadgeResource.Users"
             go-back-route="account-users"
             :go-back-route-params="{ uuid: accountUuid }"
             :title="userDetails?.full_name || ''"
             :content-loaded="!!userDetails">
    <template #view>
      <router-view :user-details="userDetails" />
    </template>
    <template v-if="items.length"
              #actions>
      <core-button-actions :append-icon="ECoreIcon.BoChevronDown"
                           :theme="ECoreButtonTheme.GrayHigh"
                           data-cy="account-users.detail.actions"
                           :text="t('general.action')"
                           :items="items" />
    </template>
  </id-layout>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButtonActions, ECoreButtonTheme, ECoreIcon } from '@common/core-ui'

import { EBadgeResource } from '@/helpers/badge'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { useAppStore } from '@/stores/app'
import { useUsersStore } from '@/stores/user'
import { EUserEvent } from '@/types/eventBus.d'
import { EPermission } from '@/types/permission.d'
import { IUserDetails } from '@/types/user.d'

import IdLayout from '@/components/layout/IdLayout.vue'

import ModalRemoveFullAccess from './modal/ModalRemoveFullAccess.vue'
import ModalSetFullAccess from './modal/ModalSetFullAccess.vue'
import ModalSetMainOwner from './modal/ModalSetMainOwner.vue'

const { t } = useI18n()
const userStore = useUsersStore()
const appStore = useAppStore()
const props = defineProps<{
    uuid: string,
    accountUuid: string
}>()

const userDetails = ref<IUserDetails | null>(null)

onMounted(async () => {
  userDetails.value = await userStore.getUserDetails(props.uuid, props.accountUuid)
  bus.on(EUserEvent.SetMainOwner, updateUser)
  bus.on(EUserEvent.SetFullAccess, updateUser)
  bus.on(EUserEvent.RemoveFullAccess, updateUser)
})

onUnmounted(() => {
  bus.off(EUserEvent.SetMainOwner, updateUser)
  bus.off(EUserEvent.SetFullAccess, updateUser)
  bus.off(EUserEvent.RemoveFullAccess, updateUser)
})

const items = computed(() => {
  if (!userDetails.value) return []
  return [
    {
      label: t('action.set_main_owner'),
      icon: ECoreIcon.BoCrown,
      onClick: () => appStore.showModal(ModalSetMainOwner, {
        previousOwner: userDetails.value?.company.user?.full_name,
        newOwner: userDetails.value?.full_name,
        userUuid: userDetails.value?.uuid,
        accountUuid: props.accountUuid
      }),
      permission: hasPermission(EPermission.UserSetMainOwner, userDetails.value)
    },
    {
      label: t('action.set_full_access'),
      icon: ECoreIcon.BoUserCheck,
      onClick: () => appStore.showModal(ModalSetFullAccess, {
        userName: userDetails.value?.full_name,
        userUuid: userDetails.value?.uuid,
        accountUuid: props.accountUuid
      }),
      permission: hasPermission(EPermission.UserSetFullAccess, userDetails.value)
    },
    {
      label: t('action.remove_full_access'),
      icon: ECoreIcon.Remove,
      onClick: () => appStore.showModal(ModalRemoveFullAccess, {
        userName: userDetails.value?.full_name,
        userUuid: userDetails.value?.uuid,
        accountUuid: props.accountUuid
      }),
      permission: hasPermission(EPermission.UserRemoveFullAccess, userDetails.value)
    }
  ].filter(item => item.permission)
})

function updateUser (updatedUser: IUserDetails) {
  userDetails.value = updatedUser
}
</script>
