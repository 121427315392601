<template>
  <slot :error="errorMessage"
        :model-value="modelValue"
        @blur="event => handleBlur(event, true)"
        @change="handleChange"
        @update:model-value="value => handleChange(value, !!errorMessage)" />
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps<{
  name: string
  modelValue: unknown
}>()

const { errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)
</script>
