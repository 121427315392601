import { IParams } from '@/types/params.d'
import { EUserGender, IUser, IUserDetails } from '@/types/user.d'

export enum EInvitationStatus {
  Deleted = 'deleted',
  Expired = 'expired',
  Pending = 'pending',
  Refused = 'refused',
  ToValidate = 'to_validate',
  Validated = 'validated',
}

export interface IInvitationParams extends IParams {
  account_uuid: string
  search: string
  status?: EInvitationStatus[]
}

export interface IInvitation extends IUser {
  status: EInvitationStatus
}

export interface IInvitationDetails extends IInvitation {
  gender: EUserGender | null
  company: {
    uuid: string
    name: string
  }
  created_at: number
  phone: string | null
  user: IUserDetails | null
  sent_at: number
}

export interface IInvitationRights {
  accounting: boolean
  card_virtual_request_user: boolean
  cards: boolean
  expense_requests: boolean
  operations: boolean
  owner: boolean
  statements?: boolean
  [key: string]: boolean | undefined
}
