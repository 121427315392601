<template>
  <core-input v-bind="props"
              :error="error"
              @blur="onBlur"
              @change="onChange"
              @update:model-value="value => handleChange(value, !!errorMessage)">
    <template v-for="(_, slotName) in $slots"
              #[slotName]="slotData">
      <slot :name="slotName"
            v-bind="slotData" />
    </template>
  </core-input>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CoreInput, ECoreInputSize, IInputProps } from '@common/core-ui'
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<
    IInputProps
>(), {
  bordered: true,
  centered: false,
  clearable: false,
  disabled: false,
  error: '',
  maxLength: -1,
  name: '',
  size: ECoreInputSize.Medium,
  type: 'text'
})

const { errorMessage, handleChange, handleBlur, meta } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)

const error = computed(() => {
  if (meta.valid) return ''
  if (!errorMessage.value) return ''
  return errorMessage.value
})

function onBlur (event: Event) {
  handleBlur(event, meta.dirty && !!(event.target as HTMLInputElement).value)
}

function onChange (event: Event) {
  handleChange(event, true)
}
</script>
