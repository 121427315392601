<template>
  <core-input-phone-number v-bind="props"
                           :error="errorMessage"
                           @blur="value => handleBlur(value, true)"
                           @change="handleChange"
                           @update:model-value="value => handleChange(value, !!errorMessage)" />
</template>

<script setup lang="ts">
import { CoreInputPhoneNumber, ECoreInputSize, IInputPhoneNumberProps } from '@common/core-ui'
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<
    IInputPhoneNumberProps
>(), {
  bordered: true,
  clearable: false,
  disabled: false,
  error: '',
  name: '',
  size: ECoreInputSize.Medium
})

const { errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)
</script>
