import { IBankValidationRequestDetails } from './bank-validation-request.d'
import { IAuthorizationCategory, ICardDetails } from './card.d'
import { IInvitationDetails } from './invitation.d'
import { IUserDetails } from './user.d'

export enum ECardEvent {
  Block = 'card-block',
  Lock = 'card-lock',
  Unlock = 'card-unlock'
}

export enum ECardSettingEvent {
  UpdateAuthorizationCategory = 'update-authorization-category',
}

export enum EAccountCreateEvent {
  ConfirmAccountCreate = 'confirm-account-create',
}

export enum EInvitationEvent {
  Resend = 'resend-invitation',
}

export enum EScaEvent {
  Revoke = 'sca-revoke',
}

export enum EUserEvent {
  SetMainOwner = 'set-main-owner',
  SetFullAccess = 'set-full-access',
  RemoveFullAccess = 'remove-full-access',
}

export enum EBankValidationRequestEvent {
  Validate = 'validate',
  Reject = 'reject',
}

export type TEvents = {
  [ECardEvent.Block]: ICardDetails,
  [ECardEvent.Lock]: ICardDetails,
  [ECardEvent.Unlock]: ICardDetails,
  [ECardSettingEvent.UpdateAuthorizationCategory]: IAuthorizationCategory
  [EAccountCreateEvent.ConfirmAccountCreate]: unknown
  [EInvitationEvent.Resend]: IInvitationDetails
  [EScaEvent.Revoke]: string,
  [EUserEvent.SetMainOwner]: IUserDetails
  [EUserEvent.SetFullAccess]: IUserDetails
  [EUserEvent.RemoveFullAccess]: IUserDetails
  [EBankValidationRequestEvent.Validate]: IBankValidationRequestDetails
  [EBankValidationRequestEvent.Reject]: IBankValidationRequestDetails
}
