<template>
  <div class="access-rights">
    <span v-if="isMainOwner || isOwner"
          class="access-rights__owner">
      <core-icon :name="ECoreIconList.CheckCircle"
                 :theme="ECoreIconTheme.GrayHigh"
                 :size="18" />
      {{ isMainOwner ? t('user.access.owner') : t('user.access.full_access') }}
    </span>
    <template v-else>
      <core-icon v-for="(item, i) in accessList"
                 :key="i"
                 v-tooltip="{ content: item.tooltipMessage, theme: 'tooltip' }"
                 class="access-rights__icon"
                 :name="item.icon"
                 :theme="item.active ? ECoreIconTheme.GrayHigh : ECoreIconTheme.GrayLower" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreIcon, ECoreIconList, ECoreIconTheme } from '@common/core-ui'

import { EUserAccess, IUserRights } from '@/types/user.d'

const { t } = useI18n()

const props = defineProps<{
  accessRights: IUserRights,
  isMainOwner: boolean,
}>()

function isAccessRightVisible (accessRight: EUserAccess) {
  return Object.keys(props.accessRights).includes(accessRight)
}

function isAccessRightActive (accessRight: EUserAccess) {
  return props.accessRights[accessRight as keyof typeof props.accessRights]
}

const isOwner = computed(() => isAccessRightActive(EUserAccess.Owner))

const accessList = computed(() => {
  return [
    {
      visible: isAccessRightVisible(EUserAccess.Dashboard),
      active: isAccessRightActive(EUserAccess.Dashboard),
      tooltipMessage: t('user.access.dashboard'),
      icon: ECoreIconList.SectionDashboard
    },
    {
      visible: isAccessRightVisible(EUserAccess.Operations),
      active: isAccessRightActive(EUserAccess.Operations),
      tooltipMessage: t('user.access.operations'),
      icon: ECoreIconList.SectionOperation
    },
    {
      visible: isAccessRightVisible(EUserAccess.Transfers),
      active: isAccessRightActive(EUserAccess.Transfers),
      tooltipMessage: t('user.access.transfers'),
      icon: ECoreIconList.SectionTransfer
    },
    {
      visible: isAccessRightVisible(EUserAccess.Debits),
      active: isAccessRightActive(EUserAccess.Debits),
      tooltipMessage: t('user.access.debits'),
      icon: ECoreIconList.SectionDebit
    },
    {
      visible: isAccessRightVisible(EUserAccess.Credits),
      active: isAccessRightActive(EUserAccess.Credits),
      tooltipMessage: t('user.access.credits'),
      icon: ECoreIconList.SectionCredit
    },
    {
      visible: isAccessRightVisible(EUserAccess.Statements),
      active: isAccessRightActive(EUserAccess.Statements),
      tooltipMessage: t('user.access.statements'),
      icon: ECoreIconList.SectionStatement
    },
    {
      visible: isAccessRightVisible(EUserAccess.Cards),
      active: isAccessRightActive(EUserAccess.Cards),
      tooltipMessage: t('user.access.cards'),
      icon: ECoreIconList.SectionCard
    },
    {
      visible: isAccessRightVisible(EUserAccess.CardVirtualRequestUser),
      active: isAccessRightActive(EUserAccess.CardVirtualRequestUser),
      tooltipMessage: t('user.access.virtual_card_request'),
      icon: ECoreIconList.SectionCardVirtual
    },
    {
      visible: isAccessRightVisible(EUserAccess.Refunds),
      active: isAccessRightActive(EUserAccess.Refunds),
      tooltipMessage: t('user.access.refunds'),
      icon: ECoreIconList.SectionRefund
    },
    {
      visible: isAccessRightVisible(EUserAccess.Mileages),
      active: isAccessRightActive(EUserAccess.Mileages),
      tooltipMessage: t('user.access.mileages'),
      icon: ECoreIconList.SectionMileage
    },
    {
      visible: isAccessRightVisible(EUserAccess.CardHolder),
      active: isAccessRightActive(EUserAccess.CardHolder),
      tooltipMessage: t('user.access.card_holder'),
      icon: ECoreIconList.SectionCardHolder
    },
    {
      visible: isAccessRightVisible(EUserAccess.Accounting),
      active: isAccessRightActive(EUserAccess.Accounting),
      tooltipMessage: t('user.access.accounting'),
      icon: ECoreIconList.SectionAccounting
    },
    {
      visible: isAccessRightVisible(EUserAccess.Owner),
      active: isAccessRightActive(EUserAccess.Owner),
      tooltipMessage: t('user.access.users'),
      icon: ECoreIconList.SectionUser
    }
  ].filter(access => access.visible)
})

</script>

<style lang="stylus" scoped>
.access-rights
  &__owner svg
    vertical-align sub
    margin-right 0.4rem
  &__icon
    display inline-block
    margin-right 0.8rem
</style>
