<template>
  <core-input-number v-bind="props"
                     :error="errorMessage"
                     @blur="value => handleChange(value, true)"
                     @change="handleChange"
                     @update:model-value="value => handleChange(value, !!errorMessage)">
    <template v-for="(_, slotName) in $slots"
              #[slotName]="slotData">
      <slot :name="slotName"
            v-bind="slotData" />
    </template>
  </core-input-number>
</template>

<script setup lang="ts">
import { CoreInputNumber, IInputNumberProps } from '@common/core-ui'
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<
    IInputNumberProps
>(), {
  addon: '',
  bordered: true,
  clearable: false,
  decimal: false,
  defaultZero: true,
  disabled: false,
  error: '',
  localString: true,
  max: null,
  maxLength: 15,
  min: null,
  minimumFractionDigits: null,
  modelValue: null,
  name: '',
  placeholder: ''
})

const { errorMessage, handleChange } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)
</script>
