<template>
  <login-block v-if="validToken"
               data-cy="password.update.container"
               :title="t('password.update.title')"
               :subtitle="t('password.update.subtitle')">
    <template #form>
      <ValidationForm v-slot="{ isSubmitting }"
                      :validation-schema="schema"
                      @invalid-submit="onInvalidSubmit"
                      @submit="onSubmit">
        <div>
          <input-text v-model:model-value="values.password"
                      name="password"
                      data-cy="password.update.password"
                      :placeholder="t('placeholder.new_password')"
                      type="password" />
          <input-text v-model:model-value="values.passwordRepeat"
                      name="password_repeat"
                      data-cy="password.update.password_repeat"
                      :placeholder="t('placeholder.confirm_password')"
                      type="password" />
        </div>
        <core-button block
                     data-cy="password.update.submit"
                     :loading="isSubmitting"
                     :size="ECoreButtonSize.Large"
                     :text="t('action.send')"
                     type="submit" />
      </ValidationForm>
    </template>
  </login-block>
  <login-block v-else
               data-cy="password.update.fail"
               :title="t('password.update.fail')"
               :subtitle="t('error.invalid_link')">
    <template #link>
      <router-link :to="{ name: 'login' }"
                   class="core-link core-link--theme-gray-light">
        {{ t('general.back') }}
      </router-link>
    </template>
  </login-block>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, ECoreButtonSize } from '@common/core-ui'
import { decodeJwt } from 'jose'
import * as yup from 'yup'

import router from '@/router'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import LoginBlock from '@/pages/login/components/LoginBlock.vue'

import InputText from '@/components/inputs/InputText.vue'
const { t } = useI18n()

// DATA
const appStore = useAppStore()
const authStore = useAuthStore()

const schema = yup.object({
  password: yup
    .string()
    .min(8)
    .required(),
  password_repeat: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required()
})

const validToken = ref(false)

const values = reactive({
  password: '',
  passwordRepeat: ''
})

const props = defineProps<{
  token?: string
}>()

onMounted(() => {
  if (!props.token) {
    return
  }

  const decodedToken = decodeJwt(props.token)

  if (!decodedToken || !decodedToken.exp || decodedToken.exp < Math.floor(new Date().getTime() / 1000)) {
    return
  }

  validToken.value = true
})

function onInvalidSubmit () {
  appStore.showBannerError(t('error.login'))
}

async function onSubmit () {
  if (props.token) {
    const success = await authStore.passwordUpdate(props.token, values.password, values.passwordRepeat)
    if (success) {
      await router.push({ name: 'login' })
    }
  }
}
</script>
