import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { IDepartment } from '@/types/department.d'

export const useDepartmentStore = defineStore('department', {

  actions: {
    async getDepartments (): Promise<{ data: IDepartment[], error: unknown|null }> {
      try {
        const { data } = await axiosClient.get('/departments')
        return { data, error: null }
      } catch (error) {
        console.error(error)
        return { data: [], error }
      }
    }
  }
})
