<template>
  <div class="filter-amounts">
    <core-input-number :model-value="amountMin"
                       :placeholder="t('placeholder.amount_min')"
                       :size="ECoreInputSize.Small"
                       :default-zero="false"
                       name="amount_min"
                       clearable
                       addon="€"
                       @blur="value => updateAmountMin(value)"
                       @clear="clearAmountMin" />
    <core-input-number :model-value="amountMax"
                       :placeholder="t('placeholder.amount_max')"
                       :default-zero="false"
                       :size="ECoreInputSize.Small"
                       name="amount_max"
                       clearable
                       addon="€"
                       @blur="value => updateAmountMax(value)"
                       @clear="clearAmountMax" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CoreInputNumber, ECoreInputSize } from '@common/core-ui'

const { t } = useI18n()

defineProps<{
  amountMin?: number | null
  amountMax?: number | null
}>()

const emit = defineEmits<{
  'update:amount-min': [value: number | null],
  'update:amount-max': [value: number | null]
}>()

function updateAmountMin (value: number | null) {
  if (value === null || value === undefined) return
  emit('update:amount-min', value)
}

function updateAmountMax (value: number | null) {
  if (value === null || value === undefined) return
  emit('update:amount-max', value)
}

function clearAmountMin () {
  emit('update:amount-min', null)
}

function clearAmountMax () {
  emit('update:amount-max', null)
}
</script>

<style scoped lang="stylus">
.filter-amounts
  display flex

  & > :not(:last-child)
    margin-right -.1rem
</style>
