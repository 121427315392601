<template>
  <div class="sidepanel-footer">
    <slot />
  </div>
</template>

<style lang="stylus" scoped>
.sidepanel-footer
  margin-top auto
  padding 3rem
  display flex
</style>
