<template>
  <div class="id-layout__header">
    <section-header :go-back-route="goBackRoute"
                    :go-back-route-params="goBackRouteParams"
                    :badges="badges"
                    :badge-resource="badgeResource"
                    :title="title">
      <template #actions>
        <slot name="actions" />
      </template>
    </section-header>
    <div class="id-layout__header__tabs">
      <core-tabs :items="tabsItems"
                 :is-section="false" />
    </div>
  </div>
  <section-body>
    <template v-if="contentLoaded"
              #body>
      <slot name="view" />
    </template>
  </section-body>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteRecordName, useRoute } from 'vue-router'
import { CoreTabs } from '@common/core-ui'

import { EBadgeResource } from '@/helpers/badge'
import hasPermission from '@/helpers/permissions'

import SectionBody from '@/components/section/SectionBody.vue'
import SectionHeader from '@/components/section/SectionHeader.vue'

interface IQuery {
  [key: string]: string
}

const { t } = useI18n()
defineProps<{
    badges?: string[]
    badgeResource?: EBadgeResource
    contentLoaded?: boolean
    goBackRoute?: string
    goBackRouteParams?: Record<string, string>,
    title: string
}>()

const currentRoute = useRoute()

function getQuery (route): IQuery|null {
  if (!(route?.meta?.keepQuery)?.length) return null
  const query: IQuery = {}
  ;(route.meta.keepQuery).forEach(name => {
    query[name] = currentRoute.query[name] as string
  })
  return query
}

function getTabLabel (itemName = ''): string {
  return t(`menu.${itemName}`)
}

const tabsItems = computed(() => {
  const route = useRoute()
  const routeTabs = route.matched.find(route => route.meta.tabs)?.children
  const tabs: Array<{ routeName: string, label: string, query: IQuery|null, isActive?: boolean }> = []

  if (routeTabs === undefined) {
    return tabs
  }
  for (const r of routeTabs) {
    if (r.meta?.tab?.label && hasPermission(r.meta?.permissions || [], undefined, true)) {
      const isActive = r?.children?.some(item => item.name === route.name) || false
      tabs.push({
        routeName: r.name || r?.children?.[0]?.name || '' as RouteRecordName,
        label: getTabLabel(r.meta?.tab?.label),
        query: getQuery(r),
        isActive
      })
    }
  }
  return tabs
})
</script>

<style scoped lang="stylus">
.id-layout__header
  background-color $white
  &__tabs
    height 5.6rem
    padding 1rem 4rem 0 4rem
</style>
