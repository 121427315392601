<template>
  <div class="time-range-selector">
    <core-dropdown :model-value="start"
                   class="time-range-selector__start"
                   :items="startSelectionList"
                   min-width="100%"
                   :disabled="disabled"
                   @update:model-value="value => emit('update:start', value)">
      <template #trigger="{ selectedValue, dropdownToggle }">
        <div class="core-input-group"
             @click="dropdownToggle">
          <div class="core-input-group__addons core-input-group__addons--bordered">
            <div class="core-input"
                 :class="{ placeholder: !selectedValue }">
              {{ selectedValue ? timeSelectionList.find(time => time.value === selectedValue)?.label : t('general.start_hour') }}
            </div>
          </div>
        </div>
      </template>
      <template #item="{ label }">
        <div class="text-center">
          {{ label }}
        </div>
      </template>
    </core-dropdown>
    <core-dropdown :model-value="end"
                   class="time-range-selector__end"
                   :items="endSelectionList"
                   min-width="100%"
                   :disabled="disabled"
                   @update:model-value="value => emit('update:end', value)">
      <template #trigger="{ selectedValue, dropdownToggle }">
        <div class="core-input-group"
             @click="dropdownToggle">
          <div class="core-input-group__addons core-input-group__addons--bordered">
            <div class="core-input"
                 :class="{ placeholder: !selectedValue }">
              {{ selectedValue ? timeSelectionList.find(time => time.value === selectedValue)?.label : t('general.end_hour') }}
            </div>
          </div>
        </div>
      </template>
      <template #item="{ label }">
        <div class="text-center">
          {{ label }}
        </div>
      </template>
    </core-dropdown>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown } from '@common/core-ui'
import { range } from 'lodash/util'

const { t } = useI18n()

const props = defineProps<{
  start: string|null,
  end: string|null,
  disabled: boolean
}>()

const emit = defineEmits<{
  'update:start': [string|null]
  'update:end': [string|null]
}>()

function getTimesOfDay (): string[] {
  let times: string[] = []
  range(0, 24).forEach(n => {
    const h = parseInt(n).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    times = times.concat([`${h}:00`, `${h}:30`])
  })
  return times
}

const timeSelectionList = computed<Array<{label: string, value: string|null}>>(() => {
  return [null, ...getTimesOfDay()].map(time => ({
    label: time || t('general.none_f'),
    value: time
  }))
})

const startSelectionList = computed(() => {
  if (!props.end) {
    return timeSelectionList.value
  } else {
    const index = timeSelectionList.value.findIndex(time => time.value === props.end)
    return timeSelectionList.value.slice(0, index)
  }
})

const endSelectionList = computed(() => {
  if (!props.start) {
    return timeSelectionList.value
  } else {
    const index = timeSelectionList.value.findIndex(time => time.value === props.start)
    return [timeSelectionList.value[0], ...timeSelectionList.value.slice(index + 1, timeSelectionList.value.length)]
  }
})
</script>

<style scoped lang="stylus">
.time-range-selector
  width 100%
  .core-dropdown
    z-index auto

  .core-input-group
    min-width 9rem
    width 100%

    .core-input
      padding 1.1rem 1.4rem !important
      text-align center

      &:after
        content none

  &__start .core-input-group__addons
    border-bottom-left-radius 0
    border-bottom-right-radius 0
  &__end .core-input-group__addons
    border-top none
    border-top-left-radius 0
    border-top-right-radius 0
</style>
