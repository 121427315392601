<template>
  <component-filters :show-result="showFiltersResult && !loading"
                     :result-count="resultCount"
                     @clear="clearFilters">
    <filter-departments v-if="canReadDepartment"
                        :departments="filters.departments"
                        @update:departments="value => updateFilter('departments', value)" />
    <filter-status :status="filters.status"
                   @update:status="value => updateFilter('status', value)" />
    <filter-transaction-monthly-limit v-if="canReadTransactionLimit"
                                      :transaction-monthly-limit="filters.transaction_monthly_limit"
                                      @update:transaction-monthly-limit="value => updateFilter('transaction_monthly_limit', value)" />
  </component-filters>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TDropdownValue } from '@common/core-ui'

import hasPermission from '@/helpers/permissions'
import { EPermission } from '@/types/permission.d'

import FilterDepartments from '@/pages/accounts/list/filters/FilterDepartments.vue'
import FilterStatus from '@/pages/accounts/list/filters/FilterStatus.vue'
import FilterTransactionMonthlyLimit from '@/pages/accounts/list/filters/FilterTransactionMonthlyLimit.vue'

import ComponentFilters from '@/components/ComponentFilters.vue'

type TAccountFilters = {
  departments: string[]
  status: string[]
  transaction_monthly_limit?: string
}

const props = defineProps<{
  filters: TAccountFilters,
  loading: boolean,
  resultCount: number
}>()

const emit = defineEmits<{
  'update:filters': [value: TAccountFilters]
}>()

const canReadTransactionLimit = computed(() => {
  return hasPermission(EPermission.AccountTransactionAmountMonthlyLimitRead)
})
const canReadDepartment = computed(() => {
  return hasPermission(EPermission.DepartmentRead)
})

function updateFilter (key: 'departments' | 'status' | 'transaction_monthly_limit', value: TDropdownValue) {
  const newFilters = {
    ...props.filters,
    [key]: value
  }

  emit('update:filters', newFilters)
}

function clearFilters () {
  emit('update:filters', {
    departments: [],
    status: [],
    transaction_monthly_limit: undefined
  })
}

const showFiltersResult = computed(() => {
  return !!props.filters.departments.length ||
         !!props.filters.status.length ||
         !!props.filters.transaction_monthly_limit
})
</script>
