<template>
  <component-banner />
  <router-view />
  <core-sidepanel-transition>
    <core-sidepanel v-if="sidePanel.active"
                    :size="ECoreSidepanelSize.Small"
                    @close="appStore.closeSidePanel()">
      <template #body>
        <component :is="sidePanel.component"
                   v-bind="sidePanel.props" />
      </template>
    </core-sidepanel>
  </core-sidepanel-transition>
  <core-file-viewer v-if="fileViewer.active"
                    :files="fileViewer.files"
                    :axios-client="axiosClient"
                    :locale="locale"
                    @close="appStore.closeFileViewer" />
  <component :is="modal.component"
             :active="modal.active"
             v-bind="modal.props"
             @close="appStore.closeModal"
             @after-leave="appStore.resetModal" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CoreFileViewer, CoreSidepanel, CoreSidepanelTransition, ECoreSidepanelSize } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import ComponentBanner from '@/components/ComponentBanner.vue'

import { useAppStore } from './stores/app'
import axiosClient from './api'

import '@modules/@common/core-ui/dist/assets/theme-bo-qnb.css'

const appStore = useAppStore()
const { sidePanel, modal, fileViewer } = storeToRefs(appStore)
const { locale } = useI18n()
</script>

<style lang="stylus">
@import './assets/stylesheets/style.styl'
</style>
