<template>
  <component-filters :show-result="showFiltersResult && !loading"
                     :result-count="resultCount"
                     @clear="clearFilters">
    <filter-date-range-picker class="operations-filters__date-range-picker"
                              :from="filters.created_at_from"
                              :to="filters.created_at_to"
                              @update:from="value => updateFilter('created_at_from', value)"
                              @update:to="value => updateFilter('created_at_to', value)" />
    <filter-amounts class="operations-filters__amounts"
                    :amount-min="filters.amount_min"
                    :amount-max="filters.amount_max"
                    @update:amount-min="value => updateFilter('amount_min', value)"
                    @update:amount-max="value => updateFilter('amount_max', value)" />
    <filter-categories :categories="filters.categories"
                       class="operations-filters__categories"
                       @update:categories="value => updateFilter('categories', value)" />
    <filter-status :status="filters.status"
                   class="operations-filters__status"
                   @update:status="value => updateFilter('status', value)" />
  </component-filters>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TDropdownValue } from '@common/core-ui'

import { IOperationParams } from '@/types/operation.d'

import ComponentFilters from '@/components/ComponentFilters.vue'

import FilterAmounts from './filters/FilterAmounts.vue'
import FilterCategories from './filters/FilterCategories.vue'
import FilterDateRangePicker from './filters/FilterDateRangePicker.vue'
import FilterStatus from './filters/FilterStatus.vue'

const props = defineProps<{
  filters: IOperationParams,
  loading: boolean,
  resultCount: number
}>()

const emit = defineEmits<{
  'update:filters': [value: IOperationParams]
}>()

function updateFilter (key: 'created_at_from' | 'created_at_to' | 'amount_min' | 'amount_max' | 'categories' | 'status', value: string | number | TDropdownValue[] | null) {
  const newFilters = {
    ...props.filters,
    [key]: value
  }

  emit('update:filters', newFilters)
}

function clearFilters () {
  emit('update:filters', {
    created_at_from: undefined,
    created_at_to: undefined,
    amount_min: undefined,
    amount_max: undefined,
    categories: [],
    status: []
  })
}

const showFiltersResult = computed(() => {
  return !!props.filters.created_at_from ||
         !!props.filters.created_at_to ||
         !!props.filters.amount_min ||
         !!props.filters.amount_max ||
         !!props.filters.categories?.length ||
         !!props.filters.status?.length
})
</script>

<style scoped lang="stylus">
.operations-filters
  &__date-range-picker,
  &__amounts
    grid-column span 2

</style>
