export enum ELoginMethod {
  Oauth = 'oauth',
  Credentials = 'credentials'
}

export enum ELanguage {
  Fr = 'fr'
}

export type TGlobalConfig = {
  results: {
    perPage: number
    perPageList: number[]
  }
}

export type TBrandConfig = {
  availableLanguages: ELanguage[],
  loginMethod: ELoginMethod,
  results?: {
    perPage?: number
    perPageList?: number[]
  }
}

export type TConfig = TGlobalConfig & TBrandConfig
