<template>
  <div v-if="isLoading"
       class="page-loading">
    <core-loader :size="32" />
  </div>
  <validation-form v-else
                   ref="orderCardForm"
                   v-slot="{ errors, meta, isSubmitting }"
                   :validation-schema="cardOrderSchema"
                   @submit="onSubmit">
    <details-block :header-size="EDefaultSize.Large"
                   :title="t('general.card_order')"
                   :columns="1">
      <template #col-1>
        <form-line :title="t('general.card_owner')">
          <template #input>
            <select-dropdown v-model:model-value="cardOrderForm.user_uuid"
                             name="user_uuid"
                             data-cy="order-card.user-dropdown"
                             min-width="100%"
                             :placeholder="t('placeholder.select')"
                             :list-placeholder-text="t('placeholder.no_user_found')"
                             :items="users" />
          </template>
        </form-line>
        <form-line :title="t('general.card_type')">
          <template #input>
            <select-dropdown v-model:model-value="cardOrderForm.type"
                             name="type"
                             data-cy="order-card.card-type-dropdown"
                             min-width="100%"
                             :placeholder="t('placeholder.select')"
                             :items="cardTypes"
                             @update:model-value="onTypeChange" />
          </template>
        </form-line>
        <form-line v-if="isWorldlineFeeSettingVisible"
                   :title="t('general.annual_fee')">
          <template #input>
            <select-dropdown v-model="cardOrderForm.worldline_membership_fee"
                             name="worldline_membership_fee"
                             min-width="100%"
                             :placeholder="t('placeholder.select')"
                             :items="feeList[cardOrderForm.type]"
                             :list-loading="feeLoading" />
          </template>
        </form-line>
        <form-line v-if="isVirtualCardSelected"
                   :title="t('general.period_of_validity')">
          <template #input>
            <select-dropdown v-model="cardOrderForm.months_before_expiration"
                             name="months_before_expiration"
                             data-cy="order-card.expiration-dropdown"
                             :size="ECoreInputSize.Small"
                             min-width="100%"
                             :placeholder="t('placeholder.select')"
                             :items="availableExpirationFilter" />
          </template>
        </form-line>
        <form-line :title="t('general.card_limits', 2)" />
        <form-line v-if="isInitialAmountVisible"
                   :title="t('general.card_limits', 1)">
          <template #input>
            <input-number v-model="cardOrderForm.settings.initial_amount"
                          name="settings_initial_amount"
                          data-cy="order-card.initial-amount"
                          :default-zero="false"
                          local-string
                          :placeholder="t('placeholder.amount')">
              <template #append>
                <span class="account-card-order__currency">€</span>
              </template>
            </input-number>
          </template>
        </form-line>
        <form-line v-if="isRecurringVirtualCardSelected"
                   :title="t('general.periodicity', 1)">
          <template #input>
            <select-dropdown v-model="selectedPeriodicity"
                             name="settings_periodicity"
                             data-cy="order-card.periodicity-dropdown"
                             :size="ECoreInputSize.Small"
                             :error="errors.settings_periodicity"
                             min-width="100%"
                             :placeholder="t('placeholder.select')"
                             :items="periodicityList" />
          </template>
        </form-line>
        <form-line v-if="!isOneTimeVirtualCardSelected"
                   :key="selectedPeriodicity"
                   :title="t(`general.${selectedPeriodicity}_payment_limit`)">
          <template #input>
            <input-number v-model="cardOrderForm.settings.payment[selectedPeriodicity].limit"
                          :name="`settings_payment_${selectedPeriodicity}_limit`"
                          data-cy="order-card.periodicity-payment-limit"
                          :default-zero="false"
                          local-string
                          :placeholder="t('placeholder.amount')">
              <template #append>
                <span class="account-card-order__currency">€</span>
              </template>
            </input-number>
          </template>
        </form-line>
        <form-line v-if="!isVirtualCardSelected"
                   :title="t('general.monthly_withdrawal_limit')">
          <template #input>
            <input-number v-if="cardOrderForm.settings.withdrawal"
                          v-model="cardOrderForm.settings.withdrawal.monthly.limit"
                          name="settings_withdrawal_monthly_limit"
                          :default-zero="false"
                          :local-string="true"
                          :placeholder="t('placeholder.amount')">
              <template #append>
                <span class="account-card-order__currency">€</span>
              </template>
            </input-number>
          </template>
        </form-line>
        <template v-if="!isVirtualCardSelected && cardOrderForm.address">
          <form-line :title="t('general.delivery_information')" />
          <form-line :title="t('general.delivery_place')">
            <template #input>
              <select-dropdown v-model:model-value="deliveryPlaceForm"
                               :size="ECoreInputSize.Small"
                               :error="errors.delivery_place"
                               min-width="100%"
                               name="delivery_place"
                               :placeholder="t('placeholder.select')"
                               :items="deliveryPlace" />
            </template>
          </form-line>
          <form-line v-if="!customAddressSelected"
                     :title="t('general.company_name')">
            <template #input>
              <input-text v-model:model-value="cardOrderForm.address.label"
                          name="address_label"
                          :placeholder="t('placeholder.company_name')"
                          :disabled="!customAddressSelected"
                          :size="ECoreInputSize.Small"
                          @update:model-value="cardOrderForm.address.label = $event" />
            </template>
          </form-line>
          <form-line :title="t('general.address')">
            <template #input>
              <input-text v-model:model-value="cardOrderForm.address.street"
                          name="address_street"
                          :placeholder="t('placeholder.address')"
                          :size="ECoreInputSize.Small"
                          :disabled="!customAddressSelected"
                          @update:model-value="cardOrderForm.address.street = $event" />
            </template>
          </form-line>
          <form-line :title="t('general.address_details')">
            <template #input>
              <input-text v-model:model-value="cardOrderForm.address.details"
                          name="address_details"
                          :placeholder="t('placeholder.address_details')"
                          :size="ECoreInputSize.Small"
                          :disabled="!customAddressSelected"
                          @update:model-value="cardOrderForm.address.details = $event" />
            </template>
          </form-line>
          <form-line :title="t('general.zip_and_city')">
            <template #input>
              <div class="account-card-order__double-input">
                <input-text :model-value="cardOrderForm.address.zip"
                            name="address_zip"
                            :placeholder="t('placeholder.zip')"
                            :disabled="!customAddressSelected"
                            :size="ECoreInputSize.Small"
                            @update:model-value="cardOrderForm.address.zip = $event" />
                <input-text :model-value="cardOrderForm.address.city"
                            name="address_city"
                            :placeholder="t('placeholder.city')"
                            :disabled="!customAddressSelected"
                            :size="ECoreInputSize.Small"
                            @update:model-value="cardOrderForm.address.city = $event" />
              </div>
            </template>
          </form-line>
          <form-line :title="t('general.country')">
            <template #input>
              <select-dropdown-api v-model:model-value="cardOrderForm.address.country"
                                   name="address_country"
                                   :size="ECoreInputSize.Small"
                                   min-width="100%"
                                   :list-placeholder-text="t('placeholder.no_country_found')"
                                   :async-request="getCountryList"
                                   :disabled="!customAddressSelected"
                                   search-enabled>
                <template #label="{ selectedItems, isEmpty }">
                  <template v-if="isEmpty">
                    {{ t('placeholder.select') }}
                  </template>
                  <template v-else-if="selectedItems[0]">
                    <core-country-flag-icon :name="selectedItems[0].flagIcon" />
                    <span class="ml-1">{{ selectedItems[0].label }}</span>
                  </template>
                </template>
              </select-dropdown-api>
            </template>
          </form-line>
        </template>
      </template>
      <template #footer-right>
        <div class="account-card-order__footer">
          <core-button :text="t('action.cancel')"
                       :theme="ECoreButtonTheme.Transparent"
                       @click="cancelForm" />
          <core-button :text="t('action.validate')"
                       type="submit"
                       :disabled="!meta.valid || isSubmitting"
                       :theme="ECoreButtonTheme.Primary"
                       :loading="isSubmitting" />
        </div>
      </template>
    </details-block>
  </validation-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  CoreButton,
  CoreCountryFlagIcon,
  CoreLoader,
  ECoreButtonTheme,
  ECoreCountryFlagIconList,
  ECoreInputSize
} from '@common/core-ui'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as yup from 'yup'

import hasPermission from '@/helpers/permissions'
import { formatDateText } from '@/helpers/utils/date'
import { getCountryName } from '@/helpers/utils/intl'
import { useCardStore } from '@/stores/card'
import { useCountryStore } from '@/stores/country'
import { useUsersStore } from '@/stores/user'
import { useWorldlineStore } from '@/stores/worldline'
import { IAccountDetails } from '@/types/account.d'
import { IAddress } from '@/types/address.d'
import {
  ECardPeriod,
  ICardOrderForm,
  isCappedVirtualCardType,
  isOneTimeVirtualCardType,
  isRecurringVirtualCardType,
  isVirtualCardType
} from '@/types/card.d'
import { EPermission } from '@/types/permission.d'
import { EDefaultSize } from '@/types/size.d'
import { IUserLight } from '@/types/user.d'
import { IWorldlineFeeResponse } from '@/types/worldline.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import FormLine from '@/components/form/FormLine.vue'
import InputNumber from '@/components/inputs/InputNumber.vue'
import InputText from '@/components/inputs/InputText.vue'
import SelectDropdown from '@/components/inputs/SelectDropdown.vue'
import SelectDropdownApi from '@/components/inputs/SelectDropdownApi.vue'

const { t } = useI18n()
const router = useRouter()
const usersStore = useUsersStore()
const countryStore = useCountryStore()
const cardsStore = useCardStore()

const props = defineProps<{
  accountUuid: string
  accountDetails: IAccountDetails
}>()

const orderCardForm = ref(null)
const isLoading = ref<boolean>(true)
const usersLight = ref<IUserLight[]>([])
const expirationDates = ref<{ expiration_date: string, value: number }[]>([])
const deliveryAddresses = ref<IAddress[]>([])

const cardOrderForm = reactive<ICardOrderForm>({
  address: {
    uuid: '',
    label: '',
    street: '',
    details: '',
    zip: '',
    city: '',
    country: ''
  },
  settings: {
    initial_amount: null,
    payment: {
      daily: {
        enabled: false,
        limit: null,
        consumed: 0
      },
      weekly: {
        enabled: false,
        limit: null,
        consumed: 0
      },
      monthly: {
        enabled: false,
        limit: null,
        consumed: 0
      },
      annually: {
        enabled: false,
        limit: null,
        consumed: 0
      }
    },
    withdrawal: {
      daily: {
        enabled: false,
        limit: null,
        consumed: 0
      },
      weekly: {
        enabled: false,
        limit: null,
        consumed: 0
      },
      monthly: {
        enabled: false,
        limit: null,
        consumed: 0
      },
      annually: {
        enabled: false,
        limit: null,
        consumed: 0
      }
    }
  },
  type: null,
  user_uuid: '',
  account_uuid: props.accountUuid
})
const deliveryPlaceForm = ref<string>('')
const selectedPeriodicity = ref<ECardPeriod>(ECardPeriod.Monthly)

onMounted(async () => {
  if (!hasPermission(EPermission.AccountCardCreate, props.accountDetails)) {
    router.push({ name: 'account-information', params: { uuid: props.accountUuid } })
  }
  if (isWorldlineFeeEnabled.value) {
    await getFeeList()
  }
  deliveryAddresses.value = await cardsStore.getDeliveryAddresses(props.accountUuid)
  deliveryPlaceForm.value = deliveryAddresses.value.length
    ? deliveryAddresses.value[0].uuid as string
    : 'custom'
  usersLight.value = await usersStore.getUsersLight(props.accountUuid)
  isLoading.value = false
})

watch(deliveryPlaceForm, (value, oldValue) => {
  if (value === 'custom' && oldValue !== 'custom' && cardOrderForm.address) {
    delete cardOrderForm.address.uuid
  } else {
    const foundAddress = deliveryAddresses.value.find(address => address.uuid === value)
    cardOrderForm.address = foundAddress
      ? { ...foundAddress }
      : {
          label: '',
          street: '',
          details: '',
          zip: null,
          city: '',
          country: ''
        }
  }
})

watch(selectedPeriodicity, (value, oldValue) => {
  if (cardOrderForm?.settings?.payment) { cardOrderForm.settings.payment[oldValue].limit = null }
})

watch(() => cardOrderForm.type, async value => {
  if (value && isVirtualCardType(value)) {
    expirationDates.value = await cardsStore.getAvailableExpirationDates(value)
  }
})

const users = computed(() => {
  return usersLight.value.map(user => {
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.uuid
    }
  })
})

const cardTypes = computed(() => {
  return [...props.accountDetails.card_types.physical, ...props.accountDetails.card_types.virtual].map(type => {
    return {
      label: t(`card.${type}`),
      value: type
    }
  })
})

const hasCardPhysicalDeliveryAddressWritePermission = computed(() => {
  return hasPermission(EPermission.CardPhysicalDeliveryAddressWrite)
})

const deliveryPlace = computed(() => {
  return deliveryAddresses.value.map(address => {
    return {
      label: address.label,
      value: address.uuid
    }
  }).concat(hasCardPhysicalDeliveryAddressWritePermission.value
    ? [{ label: t('general.other_address'), value: 'custom' }]
    : [])
})

const periodicityList = computed(() => {
  return Object.values(ECardPeriod).map(periodicity => ({ label: t(`general.${periodicity}`), value: periodicity }))
})

const isVirtualCardSelected = computed(() => {
  return cardOrderForm.type ? isVirtualCardType(cardOrderForm.type) : false
})

const isCappedVirtualCardSelected = computed(() => {
  return cardOrderForm.type ? isCappedVirtualCardType(cardOrderForm.type) : false
})

const isOneTimeVirtualCardSelected = computed(() => {
  return cardOrderForm.type ? isOneTimeVirtualCardType(cardOrderForm.type) : false
})

const isRecurringVirtualCardSelected = computed(() => {
  return cardOrderForm.type ? isRecurringVirtualCardType(cardOrderForm.type) : false
})

const isInitialAmountVisible = computed(() => {
  return isCappedVirtualCardSelected.value || isOneTimeVirtualCardSelected.value
})

const customAddressSelected = computed(() => {
  return deliveryPlaceForm.value === 'custom'
})

const cardOrderSchema = computed(() => {
  return yup.object({
    user_uuid: yup.string().required(),
    type: yup.string().required(),
    worldline_membership_fee: isWorldlineFeeSettingVisible.value
      ? yup.string().required()
      : yup.string().nullable(),
    months_before_expiration: yup.number().when('type', (type, schema) => {
      return isVirtualCardSelected.value
        ? schema.required()
        : schema.nullable()
    }),
    settings_initial_amount: yup.number().when('type', (type, schema) => {
      return (isInitialAmountVisible.value)
        ? schema.required()
        : schema.nullable()
    }),
    settings_payment_daily_limit: yup.number().when('type', (type, schema) => {
      return isRecurringVirtualCardSelected.value && selectedPeriodicity.value === 'daily'
        ? schema.required()
        : schema.nullable()
    }),
    settings_payment_weekly_limit: yup.number().when('type', (type, schema) => {
      return isRecurringVirtualCardSelected.value && selectedPeriodicity.value === 'weekly'
        ? schema.required()
        : schema.nullable()
    }),
    settings_payment_monthly_limit: yup.number().when('type', (type, schema) => {
      return isRecurringVirtualCardSelected.value && selectedPeriodicity.value === 'monthly'
        ? schema.required()
        : schema.nullable()
    }),
    settings_payment_annually_limit: yup.number().when('type', (type, schema) => {
      return isRecurringVirtualCardSelected.value && selectedPeriodicity.value === 'annually'
        ? schema.required()
        : schema.nullable()
    }),
    settings_withdrawal_monthly_limit: yup.number().nullable(),
    address_label: isVirtualCardSelected.value || customAddressSelected.value ? yup.string().nullable() : yup.string().required(),
    address_street: isVirtualCardSelected.value ? yup.string().nullable() : yup.string().required(),
    address_details: isVirtualCardSelected.value ? yup.string().nullable() : yup.string().nullable(),
    address_zip: isVirtualCardSelected.value ? yup.string().nullable() : yup.number().required(),
    address_city: isVirtualCardSelected.value ? yup.string().nullable() : yup.string().required(),
    address_country: isVirtualCardSelected.value ? yup.string().nullable() : yup.string().required()
  })
})

const availableExpirationFilter = computed(() => {
  if (!expirationDates.value.length) {
    return []
  }
  return expirationDates.value.map(date => {
    const label = date.value % 12 !== 0
      ? t('general.months', { n: date.value })
      : `${date.value / 12} ${t('general.years', date.value / 12).toLowerCase()}`
    return {
      label: `${label} (${t('general.end_of').toLowerCase()} ${formatDateText(dayjs(date.expiration_date), 'MMMM YYYY')})`,
      value: date.value
    }
  })
})

async function getCountryList (params: Record<string, unknown>, signal: AbortSignal) {
  let { data, error } = await countryStore.getCountries(params, signal)
  data = data.map(country => {
    return {
      label: getCountryName(country.iso) || country.iso,
      value: country.iso,
      flagIcon: country.iso as ECoreCountryFlagIconList
    }
  }).sort((a, b) => a.label.localeCompare(b.label))
  return { data, error }
}

function onTypeChange () {
  selectedPeriodicity.value = ECardPeriod.Monthly
  if (isWorldlineFeeEnabled.value) {
    cardOrderForm.worldline_membership_fee = props.accountDetails?.worldline_membership_fees?.[cardOrderForm.type] || null
  }
}

function cancelForm () {
  router.go(-1)
}

async function onSubmit () {
  const payload = getPayload(_.cloneDeep(cardOrderForm))

  const result = await cardsStore.orderCard(payload)
  if (!result.error) await router.push({ name: 'card-information', params: { uuid: result.uuid } })
}

function getPayload (form: ICardOrderForm) {
  if (isRecurringVirtualCardSelected.value) {
    Object.values(ECardPeriod).forEach(period => {
      if (!form.settings.payment) return
      if (selectedPeriodicity.value !== period) {
        form.settings.payment[period].enabled = false
        form.settings.payment[period].limit = null
      } else {
        form.settings.payment[period].enabled = true
      }
    })
  }

  if (!isInitialAmountVisible.value) delete form.settings.initial_amount

  if (isOneTimeVirtualCardSelected.value) delete form.settings.payment

  if (isVirtualCardSelected.value) {
    delete form.settings.withdrawal
    delete form.address
  } else {
    delete form.months_before_expiration
  }

  if (form.settings.payment?.monthly.limit) form.settings.payment.monthly.enabled = true
  if (form.settings.withdrawal?.monthly.limit) form.settings.withdrawal.monthly.enabled = true
  if (customAddressSelected.value) delete form.address?.label

  return form
}

// Worldline Fee
const feeList = ref<IWorldlineFeeResponse>({})
const feeLoading = ref<boolean>(false)

const isWorldlineFeeEnabled = computed(() => {
  return hasPermission(EPermission.WorldlineFeeRead)
})

const isWorldlineFeeSettingVisible = computed(() => {
  return isWorldlineFeeEnabled.value && cardOrderForm.type && feeList.value[cardOrderForm.type]?.length > 0
})

async function getFeeList () {
  feeLoading.value = true
  const { data } = await useWorldlineStore().getFees({ company_type: props.accountDetails.company.type })
  feeList.value = data
  feeLoading.value = false
}
</script>

<style scoped lang="stylus">
.account-card-order
  &__double-input
    display flex
    justify-content space-between
    gap 1rem
    width: 100%

  &__footer
    display flex
    justify-content flex-end
    align-items center
    gap 1rem

  &__currency
    color $color-gray-600

</style>
