<template>
  <div class="bank-validation-requests"
       data-cy="bank-validation-requests.view">
    <core-table v-model:sort="sort"
                v-model:pagination="pagination"
                v-model:per-page="perPage"
                :per-page-list="config.results.perPageList"
                data-cy="bank-validation-requests.table"
                :table-head="tableHead"
                :table-data="bankValidationRequestsData"
                with-search-filter
                :search-placeholder="t('placeholder.search')"
                :loading="loading"
                :route="route"
                :placeholder-text="t('placeholder.no_request_found')"
                :current-route="currentRoute"
                with-filter
                top-pagination
                :locale="locale"
                @update:per-page="getBankValidationRequestsData(true)"
                @search-filter="value => { filters.search = value; getBankValidationRequestsData(true) }"
                @row-click="showBankValidationRequestDetails"
                @update:sort="getBankValidationRequestsData(true)"
                @update:pagination="getBankValidationRequestsData()">
      <template #head-left>
        <span class="bank-validation-requests__title title--sm">{{ title }}</span>
        <component-nav-pills v-if="state !== EBankValidationRequestListState.Mine"
                             class="bank-validation-requests__nav-pills"
                             :routes="navPillsRoutes" />
      </template>
      <template #filter-list>
        <bank-validation-requests-filters v-model:filters="filters"
                                          :loading="loading"
                                          :result-count="pagination.totalItems"
                                          @update:filters="getBankValidationRequestsData(true)" />
      </template>
      <template #created_at="{ date }">
        <span class="bank-validation-requests__date">{{ formatDateText(date, 'L LT') }}</span>
      </template>
      <template #label="{ label }">
        <span class="bank-validation-requests__label">{{ label }}</span>
      </template>
      <template #account="{ account }">
        <span class="bank-validation-requests__user">{{ account }}</span>
      </template>
      <template #initiator="{ initiator }">
        <span class="bank-validation-requests__user">{{ initiator }}</span>
      </template>
      <template #status="{ status }">
        <core-badge :theme="getBadge(status, EBadgeResource.BankValidationRequests)?.theme"
                    :value="getBadge(status, EBadgeResource.BankValidationRequests)?.label || ''"
                    :fill="ECoreBadgeFill.Shaded" />
      </template>
      <template #action="{ clickable }">
        <core-icon v-if="clickable"
                   :name="ECoreIconBoList.BoChevronRight"
                   :size="16" />
      </template>
    </core-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { CoreBadge, CoreIcon, CoreTable, ECoreBadgeFill, ECoreIconBoList, ITableSort, SortOrder } from '@common/core-ui'

import config from '@/config'
import { EBadgeResource, getBadge } from '@/helpers/badge'
import bus from '@/helpers/eventBus'
import { defaultPagination, parsePagination } from '@/helpers/pagination'
import { formatDateText } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useBankValidationRequestStore } from '@/stores/bank-validation-request'
import {
  EBankValidationRequestListState,
  EBankValidationRequestMode,
  EBankValidationRequestStatus,
  getBankValidationRequestLabel,
  IBankValidationRequest,
  IBankValidationRequestData,
  IBankValidationRequestParams
} from '@/types/bank-validation-request.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'

import BankValidationRequestDetails from '@/pages/bank-validation-requests/BankValidationRequestDetails.vue'
import BankValidationRequestsFilters from '@/pages/bank-validation-requests/BankValidationRequestsFilters.vue'

import ComponentNavPills from '@/components/ComponentNavPills.vue'

const { t, locale } = useI18n()
const appStore = useAppStore()
const authStore = useAuthStore()
const bankValidationRequestStore = useBankValidationRequestStore()
const currentRoute = useRoute()
const router = useRouter()

const { count } = bankValidationRequestStore
function getCountLabel (mode: EBankValidationRequestMode) {
  return props.state === EBankValidationRequestListState.Pending && count[mode] ? ` (${count[mode]})` : ''
}
const bankValidationRequestsData: Ref<IBankValidationRequestData[]> = ref([])

const navPillsRoutes = computed(() => (
  [{
    name: props.route,
    label: t('general.client', 2) + getCountLabel(EBankValidationRequestMode.Client),
    query: { mode: EBankValidationRequestMode.Client },
    isActive: !currentRoute.query?.mode || currentRoute.query?.mode === EBankValidationRequestMode.Client
  },
  {
    name: props.route,
    label: t('general.bank') + getCountLabel(EBankValidationRequestMode.Bank),
    query: { mode: EBankValidationRequestMode.Bank },
    isActive: currentRoute.query?.mode === EBankValidationRequestMode.Bank
  }]
))

const props = defineProps<{
  page: number,
  state: EBankValidationRequestListState,
  route: string
}>()

const loading = ref(false)

const pagination = ref(defaultPagination)
const perPage = ref<number>(config.results.perPage)

const stateFilters = computed(() => {
  let status: EBankValidationRequestStatus[] | null = null
  let mode: string | null = null
  switch (props.state) {
    case EBankValidationRequestListState.Pending: status = [EBankValidationRequestStatus.ToValidate]; break
    case EBankValidationRequestListState.History: status = [EBankValidationRequestStatus.Validated, EBankValidationRequestStatus.Rejected, EBankValidationRequestStatus.Canceled]; break
    case EBankValidationRequestListState.Mine: return { bo_user_uuid: authStore.boUser.uuid }
  }
  switch (currentRoute.query?.mode) {
    case undefined:
    case EBankValidationRequestMode.Client: mode = EBankValidationRequestMode.Client; break
    case EBankValidationRequestMode.Bank: mode = EBankValidationRequestMode.Bank; break
  }
  return { status, mode }
})

const filters = ref<IBankValidationRequestParams>({
  start: null,
  end: null,
  type: [],
  'per-page': perPage.value
})

const sort = ref<ITableSort>({
  id: null,
  order: null
})

onMounted(() => {
  getBankValidationRequestsData()
  bus.on(EBankValidationRequestEvent.Validate, getBankValidationRequestsData)
  bus.on(EBankValidationRequestEvent.Reject, getBankValidationRequestsData)
})

onUnmounted(() => {
  bus.off(EBankValidationRequestEvent.Validate, getBankValidationRequestsData)
  bus.off(EBankValidationRequestEvent.Reject, getBankValidationRequestsData)
})

watch(() => props.page, () => getBankValidationRequestsData())
watch(() => props.state, () => getBankValidationRequestsData(true))
watch(() => currentRoute.query?.mode, () => getBankValidationRequestsData(true))

const title = computed(() => {
  switch (props.state) {
    case EBankValidationRequestListState.Pending: return t('title.requests_to_validate')
    case EBankValidationRequestListState.History: return t('title.requests_history')
    case EBankValidationRequestListState.Mine: return t('title.my_requests')
  }
  return ''
})

const tableHead = computed(() => {
  return [
    { id: 'created_at', label: t('general.date'), sortable: true, width: 10 },
    { id: 'label', label: t('general.label'), sortable: false, ellipsis: true, width: 30 },
    { id: 'account', label: t('general.client', 1), sortable: false, width: 25 },
    { id: 'initiator', label: t('general.initiator'), sortable: false, width: 15 },
    { id: 'status', label: t('general.status'), align: 'right', sortable: true, width: 10, isVisible: props.state !== EBankValidationRequestListState.Pending },
    { id: 'action', fitContent: true }
  ].filter(item => item.isVisible !== false)
})

const sortParam = computed(() => {
  if (!sort.value.id) {
    return null
  }

  return sort.value.order === SortOrder.Desc ? `-${sort.value.id}` : sort.value.id
})

async function getBankValidationRequestsData (reset = false) {
  loading.value = true

  const params = {
    ...stateFilters.value,
    ...filters.value,
    page: reset ? 1 : props.page,
    sort: sortParam.value
  }

  await bankValidationRequestStore.getBankValidationRequestsCount()

  const { data, headers, error } = await bankValidationRequestStore.getBankValidationRequests(params)
  if (!error) {
    pagination.value = parsePagination(headers)
    bankValidationRequestsData.value = formatBankValidationRequests(data)

    await router.push({ query: { ...router.currentRoute.value.query, page: pagination.value.current === 1 ? undefined : pagination.value.current } })
  }
  loading.value = false
}

function formatBankValidationRequests (requests: IBankValidationRequest[]): IBankValidationRequestData[] {
  return requests.map(request => {
    return {
      uuid: request.uuid,
      date: request.created_at,
      label: getBankValidationRequestLabel(request),
      account: `${request.account.company.name} - ${request.account.label}`,
      initiator: request.created_by.full_name,
      status: request.status,
      clickable: true
    }
  })
}

function showBankValidationRequestDetails (request: IBankValidationRequest) {
  appStore.showSidePanel(BankValidationRequestDetails, { bankValidationRequestUuid: request.uuid })
}

</script>

<style lang="stylus" scoped>
.bank-validation-requests
  &__title
    display inline-block
    min-width 20rem
    margin-right 4rem
</style>
