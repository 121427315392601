<template>
  <sidepanel-layout v-if="operationDetails"
                    data-cy="operation.detail.view">
    <sidepanel-header :amount="formatAmount(operationDetails.amount, operationDetails.currency, { signDisplay: 'exceptZero' })"
                      :label="operationDetails.label"
                      :status="operationDetails.status"
                      :badge-resource="EBadgeResource.Operations" />
    <sidepanel-block :title="t('general.operation_details')">
      <template #content>
        <sidepanel-line :label="t('general.operation_type')"
                        :data="operationDetails.category.label" />
        <sidepanel-line v-if="operationDetails.mcc_category"
                        :label="t('general.category')"
                        :data="operationDetails.mcc_category.label" />
        <sidepanel-line :label="t('general.payment_date')"
                        :data="formatDateText(operationDetails.created_at)" />
        <sidepanel-line v-if="operationDetails.accounting_at"
                        :label="t('general.accounting_date')"
                        :data="formatDateText(operationDetails.accounting_at)" />
        <sidepanel-line v-if="operationDetails.deferred_at"
                        :label="t('general.deferred_date')"
                        :data="formatDateText(operationDetails.deferred_at)" />
        <sidepanel-line v-if="operationDetails.reject_reason"
                        :label="t('general.reject_reason')"
                        :data="operationDetails.reject_reason" />
        <sidepanel-line v-if="operationDetails.card"
                        clickable
                        :label="t('general.card_number')"
                        :data="operationDetails.card.digits"
                        @click="redirectToCardDetails" />
        <sidepanel-line v-if="operationDetails.account"
                        :clickable="canSeeAccountDetails"
                        :label="t('general.customer_account')"
                        :data="`${operationDetails.account.company.name} - ${operationDetails.account.label}`"
                        @click="redirectToAccountDetails" />
        <sidepanel-line v-if="operationDetails.user"
                        :clickable="canSeeUserDetails"
                        :label="t('general.user')"
                        :data="operationDetails.user.full_name"
                        @click="canSeeUserDetails && redirectToUserDetails()" />
      </template>
    </sidepanel-block>
    <sidepanel-block v-if="isFeeDetailVisible"
                     :title="t('general.fee_details')"
                     fees-block>
      <template #content>
        <div v-if="arePartnerFeesVisible">
          <sidepanel-line :label="t('general.client_fees')" />
          <sidepanel-line v-for="item in operationDetails.partner_fees"
                          :key="item.operation_uuid"
                          :label="getFeeLabel(item.sub_category)"
                          :data="formatAmount(item.amount, operationDetails.currency, { signDisplay: 'exceptZero' })" />
        </div>
        <div v-if="areBankFeesVisible">
          <sidepanel-line :label="t('general.bank_fees')" />
          <sidepanel-line v-for="item in operationDetails.bank_fees_operations"
                          :key="item.uuid"
                          :label="getFeeLabel(item.sub_category)"
                          :data="formatAmount(item.amount, operationDetails.currency, { signDisplay: 'exceptZero' })" />
        </div>
      </template>
    </sidepanel-block>
  </sidepanel-layout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { EBadgeResource } from '@/helpers/badge'
import hasPermission from '@/helpers/permissions'
import { formatDateText } from '@/helpers/utils/date'
import { formatAmount } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { useOperationStore } from '@/stores/operation'
import { EBankFeesSubCategory, EClientFeesType, IOperationDetails } from '@/types/operation.d'
import { EPermission } from '@/types/permission.d'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelHeader from '@/components/sidepanel/SidepanelHeader.vue'
import SidepanelLayout from '@/components/sidepanel/SidepanelLayout.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const props = defineProps<{
  operationUuid: string
}>()

const { t } = useI18n()
const router = useRouter()
const appStore = useAppStore()
const operationStore = useOperationStore()
const operationDetails = ref<IOperationDetails | null>(null)

onMounted(() => {
  getOperationDetails()
})

const canSeeUserDetails = computed(() => {
  return operationDetails.value?.user ? hasPermission(EPermission.UserRead) : false
})

const canSeeAccountDetails = computed(() => {
  return operationDetails.value?.account ? hasPermission(EPermission.AccountRead) : false
})

const areBankFeesVisible = computed(() => {
  return operationDetails.value?.bank_fees_operations?.length
})

const arePartnerFeesVisible = computed(() => {
  return operationDetails.value?.partner_fees?.length
})

const isFeeDetailVisible = computed(() => {
  return areBankFeesVisible.value || arePartnerFeesVisible.value
})

function getFeeLabel (type: EBankFeesSubCategory | EClientFeesType) {
  if ([EClientFeesType.CurrencyExchange, EBankFeesSubCategory.CardFx, EBankFeesSubCategory.CreditCardFees].includes(type)) return t('general.exchange_commission')
  if ([EClientFeesType.Withdrawal, EBankFeesSubCategory.Withdrawal].includes(type)) return t('general.withdrawal_commission')
  return ''
}

async function getOperationDetails () {
  operationDetails.value = await operationStore.getOperationDetails(props.operationUuid)
}

function redirectToCardDetails () {
  if (!operationDetails.value?.card) return
  router.push({
    name: 'card-information',
    params: {
      uuid: operationDetails.value.card.uuid
    }
  })
  appStore.closeSidePanel()
}

function redirectToUserDetails () {
  if (!operationDetails.value?.user) return
  router.push({
    name: 'user-information',
    params: {
      uuid: operationDetails.value.user.uuid
    },
    query: {
      account_uuid: operationDetails.value.account.uuid
    }
  })
  appStore.closeSidePanel()
}

function redirectToAccountDetails () {
  if (!operationDetails.value?.account) return
  router.push({
    name: 'account-information',
    params: {
      uuid: operationDetails.value.account.uuid
    }
  })
}

</script>
