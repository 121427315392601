import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { IOperation, IOperationDetails, IOperationParams } from '@/types/operation.d'

export const useOperationStore = defineStore('operation', {

  actions: {
    async getOperations (params: IOperationParams): Promise<{ data: IOperation[], headers: RawAxiosResponseHeaders | AxiosResponseHeaders, error: boolean }> {
      try {
        const response = await axiosClient.get('/operations', { params })
        return { data: response.data, headers: response.headers, error: false }
      } catch (error) {
        console.error(error)
        return { data: [], headers: {}, error: true }
      }
    },

    async getOperationDetails (uuid: string): Promise<IOperationDetails | null> {
      try {
        const { data } = await axiosClient.get(`/operations/${uuid}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getCategories (): Promise<{ label: string, value: string }[]> {
      try {
        const { data } = await axiosClient.get('/operations/categories')
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getStatus (): Promise<{ label: string, value: string }[]> {
      try {
        const { data } = await axiosClient.get('/operations/status')
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    }
  }
})
