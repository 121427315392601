<template>
  <core-date-range-picker v-model:from="computedFromDate"
                          v-model:to="computedToDate"
                          :from-placeholder="t('placeholder.date_format')"
                          :to-placeholder="t('placeholder.date_format')"
                          :from-prepend="t('general.from')"
                          :to-prepend="t('general.to')"
                          :locale="i18n.global.locale.value"
                          :size="ECoreInputSize.Small"
                          clearable
                          monday-first
                          @update:from="updateFromFilters"
                          @update:to="updateToFilters" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDateRangePicker, ECoreInputSize } from '@common/core-ui'
import dayjs from 'dayjs'

import i18n from '@/locales'

const { t } = useI18n()
const props = defineProps<{
  from?: string | null,
  to?: string | null
}>()

const computedFromDate = computed(() => {
  return props.from ? new Date(props.from) : null
})

const computedToDate = computed(() => {
  return props.to ? new Date(props.to) : null
})

const emit = defineEmits<{
  'update:from': [value: string | null],
  'update:to': [value: string | null]
}>()

function updateFromFilters (date: Date | null) {
  emit('update:from', date ? dayjs(date).format('YYYY-MM-DD') : null)
}

function updateToFilters (date: Date | null) {
  emit('update:to', date ? dayjs(date).format('YYYY-MM-DD') : null)
}
</script>
