import { IAccount } from '@/types/account.d'
import { IParams } from '@/types/params.d'
import { IUserLight } from '@/types/user.d'

import { ICurrency } from './currency.d'

export enum EOperationStatus {
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  Done = 'done',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected'
}

enum ECategory {
  ChangeLetter = 'change_letter',
  CreditCard = 'credit_card',
  DepositCash = 'deposit_cash',
  DepositCheck = 'deposit_check',
  Fees = 'fees',
  Irregularities = 'irregularities',
  Other = 'other',
 PaymentCheck = 'payment_check',
  Refund = 'refund',
  Prelevement = 'prelevement',
  Transfer = 'transfer'
}

enum ESubCategory {
  CardMonthlyFee = 'card_monthly_fee',
  IncidentFees = 'incident_fee',
  Card = 'card',
  CreditCardFees = 'credit_card_fees',
  Extourne = 'extourne',
  Month = 'month',
  Free = 'free',
  Interchange = 'interchange',
  Opt = 'opt',
  Subonmain = 'subonmain',
  Tpe = 'tpe',
  Transfer = 'transfer',
  Withdrawal = 'withdrawal',
  OperatingFee = 'operating_fee',
  Other = 'other',
  ReturnRejectedTransfer = 'return_rejected_transfer'
}

export interface IOperation {
  amount: number
  account: IAccount
  category: {
    value: ECategory
    label: string
  }
  created_at: number
  currency: ICurrency
  mcc_category: {
    id: number,
    label: string
  } | null,
  label: string
  status: EOperationStatus
  sub_category: {
    label: string
    value: ESubCategory
  }
  user: IUserLight | null
  uuid: string
}

export interface IOperationData {
  date: number
  label: string
  user?: string
  category: string
  amount: number
  currency: ICurrency
  type: string
  status: EOperationStatus
  uuid: string
  clickable: boolean
}

export interface IOperationParams extends IParams {
  created_at_from?: string
  created_at_to?: string
  amount_min?: number
  amount_max?: number
  account_uuid?: string
  card_uuid?: string
  search?: string
  categories?: string[]
  status?: string[]
}

export enum EBankFeesSubCategory {
  CardFx = 'card_fx',
  CreditCardFees = 'credit_card_fees',
  Withdrawal = 'withdrawal'
}

export enum EClientFeesType {
  CurrencyExchange = 'currency_exchange',
  Withdrawal = 'withdrawal'
}

interface IBankFeesOperations {
  uuid: string
  sub_category: EBankFeesSubCategory
  amount: number
}

interface IPartnerFees {
  operation_uuid: string
  sub_category: EClientFeesType
  amount: number
}

export interface IOperationDetails extends IOperation {
  accounting_at: number | null
  deferred_at: number | null
  card: ICardLight | null
  bank_fees_operations: IBankFeesOperations[]
  partner_fees: IPartnerFees[]
  reject_reason: string | null
}
