import { TranslateResult } from 'vue-i18n'
import { TYPE, useToast } from 'vue-toastification'

import ComponentToast from '@/components/ComponentToast.vue'

const toast = useToast()

const showToast = (type: TYPE, title: string | TranslateResult, message?: string | TranslateResult) => {
  return toast({
    component: ComponentToast,
    props: { type, title, message }
  }, { type })
}

export const showToastSuccess = (title: string | TranslateResult, message?: string | TranslateResult) => {
  showToast(TYPE.SUCCESS, title, message)
}

export const showToastWarning = (title: string | TranslateResult, message?: string | TranslateResult) => {
  showToast(TYPE.WARNING, title, message)
}

export const showToastInfo = (title: string | TranslateResult, message?: string | TranslateResult) => {
  showToast(TYPE.INFO, title, message)
}

export const showToastError = (title: string | TranslateResult) => {
  showToast(TYPE.ERROR, title)
}
