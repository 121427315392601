<template>
  <core-modal active
              :size="ECoreModalSize.Small"
              :title="t('modal.validate_request.title')"
              :closable-button="false"
              data-cy="validate-bank-validation-request.modal-action.container">
    <template #body>
      <p class="pre-line">
        {{ modalContent }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.GrayHigh"
                   :appearance="ECoreButtonAppearance.Outlined"
                   :text="t('action.cancel')"
                   :loading="loading"
                   :disabled="loading"
                   @click="closeModal" />
      <core-button :text="t('action.confirm')"
                   :loading="loading"
                   :disabled="loading"
                   data-cy="validate-bank-validation-request.modal-action.button"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, CoreModal, ECoreButtonAppearance, ECoreButtonTheme, ECoreModalSize } from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { useAppStore } from '@/stores/app'
import { useBankValidationRequestStore } from '@/stores/bank-validation-request'
import { EBankValidationRequestType, IBankValidationRequestDetails } from '@/types/bank-validation-request.d'
import { EBankValidationRequestEvent } from '@/types/eventBus.d'

const { t } = useI18n()

const props = defineProps<{
  bankValidationRequest: IBankValidationRequestDetails
}>()

const appStore = useAppStore()
const bankValidationRequestStore = useBankValidationRequestStore()

const loading = ref(false)

onMounted(async () => {
  bus.on(EBankValidationRequestEvent.Validate, closeModal)
})

onUnmounted(() => {
  bus.off(EBankValidationRequestEvent.Validate, closeModal)
})

const modalContent = computed(() => {
  // TODO
  switch (props.bankValidationRequest.type) {
    case EBankValidationRequestType.CreateUser:
      return t('modal.validate_request.content.create_user', {
        user_name: ''
      })
    case EBankValidationRequestType.CreateCard:
      return t('modal.validate_request.content.order_card_physical', {
        user_name: ''
      })
    case EBankValidationRequestType.UnlockCard:
      return t('modal.validate_request.content.unlock_card', {
        user_name: ''
      })
    case EBankValidationRequestType.CreateUserCard:
      return t('modal.validate_request.content.create_cardholder_user', {
        user_name: ''
      })
    case EBankValidationRequestType.UpdateAccountLimit:
      return t('modal.validate_request.content.update_card_limit', {
        account_label: `${props.bankValidationRequest.account.label}(${props.bankValidationRequest.account.company.name})`
      })
    case EBankValidationRequestType.UpdateCardLimit:
      return t('modal.validate_request.content.update_card_limit', {
        user_name: ''
      })
    case EBankValidationRequestType.UpdateUser:
      return t('modal.validate_request.content.update_user', {
        user_name: ''
      })
    case EBankValidationRequestType.CloseAccount:
      return t('modal.validate_request.content.close_account', {
        account_label: `${props.bankValidationRequest.account.label}(${props.bankValidationRequest.account.company.name})`
      })
    case EBankValidationRequestType.CreateAdmin:
      return t('modal.validate_request.content.create_admin', {
        user_name: ''
      })
    default: return ''
  }
})

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  loading.value = true
  await bankValidationRequestStore.validateBankValidationRequest(props.bankValidationRequest.uuid)
  loading.value = false
}
</script>
