export enum EScaType {
    APP = 'app',
    EMAIL = 'email',
    SMS = 'sms',
}

export interface ISca {
    uuid: string
    created_at: number
    last_used_at: number | null
    type: EScaType
    token_info: {
        device_name?: string
        device_os?: string
        email?: string
        phone?: string
    }
}

export interface IScaData {
    uuid: string
    created_at: number
    last_used_at: number | null
    type: EScaType
    email?: string
    phone?: string
    device_os?: string
    device_name?: string
}
