<template>
  <section-header :title="t('menu.operations')" />
  <section-body>
    <template #body>
      <operations-list :page="page"
                       route="operations" />
    </template>
  </section-body>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import OperationsList from '@/pages/operations/OperationsList.vue'

import SectionBody from '@/components/section/SectionBody.vue'
import SectionHeader from '@/components/section/SectionHeader.vue'

const { t } = useI18n()

defineProps<{
  page: number
}>()
</script>
