import { upperFirst } from 'lodash/string'

import i18n from '@/locales'

export const getCountryName = country => {
  return (new Intl.DisplayNames([i18n.global.locale.value], { type: 'region' })).of(country)
}

export const getLanguageName = language => {
  return upperFirst((new Intl.DisplayNames([i18n.global.locale.value], { type: 'language' })).of(language))
}

export const getCurrencyName = currency => {
  return upperFirst((new Intl.DisplayNames([i18n.global.locale.value], { type: 'currency' })).of(currency))
}
