<template>
  <details-block data-cy="card.settings.email-alerts"
                 :title="t('menu.email_alerts')"
                 :loading="loading && !alertSettingModel"
                 :columns="1">
    <template v-if="alertSettingModel"
              #col-1>
      <card-limit-item v-if="alertSettingModel.hasOwnProperty('alert_spent')"
                       v-model:model-value="alertSettingModel.alert_spent"
                       :label="t('card.alert.spent')"
                       :disabled="isDisabled">
        <template #input-append>
          {{ getCurrencySymbol(currency) }}
        </template>
      </card-limit-item>

      <details-line v-if="alertSettingModel.hasOwnProperty('alert_card_used_enabled')">
        <template #content>
          <core-toggle-switch v-model:model-value="alertSettingModel.alert_card_used_enabled"
                              :label="usedCardAlertLabel"
                              :disabled="isDisabled" />
        </template>
      </details-line>

      <details-line v-if="alertSettingModel.hasOwnProperty('alert_limit_enabled')">
        <template #content>
          <core-toggle-switch v-model:model-value="alertSettingModel.alert_limit_enabled"
                              :label="t('card.alert.reached_limit')"
                              :disabled="isDisabled" />
        </template>
      </details-line>
    </template>

    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveAlertSetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, CoreToggleSwitch, ECoreButtonTheme } from '@common/core-ui'
import _ from 'lodash'

import hasPermission from '@/helpers/permissions'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useCardStore } from '@/stores/card'
import { ICardAlertSetting, ICardDetails, isOneTimeVirtualCardType } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'
import { EPermission } from '@/types/permission.d'

import CardLimitItem from '@/pages/cards/card-id/tabs/settings/blocks/components/CardLimitItem.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string,
  cardDetails: ICardDetails
  currency: ICurrency
}>()

const loading = ref<boolean>(true)
const alertSetting = ref<ICardAlertSetting | null>(null)
const alertSettingModel = ref<ICardAlertSetting | null>(null)

onMounted(async () => {
  alertSetting.value = await cardStore.getCardAlertSetting(props.cardUuid)
  alertSettingModel.value = _.cloneDeep(alertSetting.value)
  loading.value = false
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingAlertUpdate, props.cardDetails)
})

const usedCardAlertLabel = computed(() => {
  return isOneTimeVirtualCardType(props.cardDetails.type) ? t('card.alert.used') : t('card.alert.used_80')
})

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(alertSetting.value, alertSettingModel.value) && !isDisabled.value
})

function cancel () {
  alertSettingModel.value = _.cloneDeep(alertSetting.value)
}

async function saveAlertSetting () {
  if (!alertSettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardAlertSetting(props.cardUuid, alertSettingModel.value)
  if (data) alertSetting.value = data
  loading.value = false
}
</script>
