<template>
  <id-layout :title="t('menu.requests')"
             content-loaded>
    <template #view>
      <router-view :page="page" />
    </template>
  </id-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import IdLayout from '@/components/layout/IdLayout.vue'

const { t } = useI18n()

defineProps<{
  page: number
}>()

</script>
