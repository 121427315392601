import { unref } from 'vue'
import dayjs from 'dayjs'

export type TToApi = {
  exceptsFields?: string[]
  fields?: string[]
  onlyDirty?: boolean
  resetDirty?: boolean
}

interface ModelWithToApi {
  toApi(options: { onlyDirty: boolean }): unknown;
}

type proxyCallback = (prop: string) => void
type proxyTarget = Record<string, unknown>

function proxyHandler (callback: proxyCallback, property: string | null = null) {
  return {
    get (target: proxyTarget, prop: string): unknown {
      if (typeof target[prop] === 'object' && ['Object', 'Array'].includes(target[prop]?.constructor?.name || '') && target[prop] !== null) {
        return new Proxy(target[prop] as proxyTarget, proxyHandler(callback, property || prop))
      } else {
        return Reflect.get(target, prop)
      }
    },
    set (target: proxyTarget, prop: string, value: unknown): boolean {
      if (target[property || prop] !== value) callback(property || prop)

      Reflect.set(target, prop, value)
      return true
    }
  }
}

export class Model {
  private dirtyFields: Set<string> = new Set()
  public apiFields: string[] = []
  public apiCast: { [key: string]: string } = {};
  [key: string]: unknown;

  onConstructed (): this {
    return new Proxy(this, proxyHandler(field => {
      this.dirtyFields.add(field)
    })) as this
  }

  setFields (object: object, resetDirty = false) {
    for (const [key, value] of Object.entries(object)) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        this[key] = value
      }
    }

    if (resetDirty) this.resetDirty()
  }

  isFieldDirty (fieldName: string): boolean {
    return this.dirtyFields.has(fieldName)
  }

  resetDirty (): void {
    this.dirtyFields = new Set()
  }

  toApi (api: TToApi = {} as TToApi): object {
    const fields: string[] = []

    if (!api || !api.fields) { api.fields = Object.keys(this) }

    const apiFields = api.fields.reduce((fields, field) => {
      if ((!api.onlyDirty || this.isFieldDirty(field)) && (!api.exceptsFields || !api.exceptsFields.includes(field))) { fields.push(field) }

      return fields
    }, fields)

    if (api.resetDirty) { this.resetDirty() }

    return this.payload(this.filteredFields(apiFields, !!api.onlyDirty))
  }

  private rawField (keyField: string, onlyDirty: boolean) {
    const field = this[keyField]
    const constructorName = field?.constructor?.name

    if (typeof field === 'object' && constructorName?.includes('Model')) {
      return (field as ModelWithToApi)?.toApi({ onlyDirty })
    }

    for (const [key, value] of Object.entries(this.apiCast)) {
      if (keyField === key) {
        switch (value) {
          case 'timestamp':
            return dayjs(field as string).unix()
        }
      }
    }

    switch (constructorName) {
      case 'Date':
      case 'Dayjs':
        return dayjs(field as string).format('YYYY-MM-DD')
      case 'RefImpl':
        return unref(field)
    }

    return field
  }

  private payload (fields: { [key: string]: unknown }) {
    const files: string[] = []
    let hasFile = false

    for (const [key, value] of Object.entries(this.apiCast)) {
      if (value === 'file') {
        files.push(key)
      }
    }

    files.forEach(file => { if (fields[file]) hasFile = true })
    if (!hasFile) return fields

    const payload: FormData = new FormData()
    files.forEach(fieldName => {
      const fieldValue = fields[fieldName] as { file: File | undefined }

      if (Array.isArray(fieldValue)) {
        fieldValue.forEach(file => {
          if (file?.file) payload.append(`${fieldName}[]`, file.file)
        })
      } else if (fieldValue) {
        if (fieldValue?.file) payload.append(fieldName, fieldValue.file)
      }

      delete fields[fieldName]
    })

    payload.append('jsonBody', JSON.stringify(fields))

    return payload
  }

  private filteredFields (fields: string[], onlyDirty: boolean): { [key: string]: unknown } {
    const result: { [key: string]: unknown } = {}
    return Object.keys(this).filter(field => this.apiFields.includes(field)).reduce((result, key: string) => {
      if (fields.includes(key)) {
        result[(key as string)] = this.rawField(key, onlyDirty)
      }
      return result
    }, result)
  }
}
