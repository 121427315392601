<template>
  <core-dropdown v-bind="props"
                 :error="errorMessage"
                 @blur="handleBlur"
                 @change="handleChange"
                 @update:model-value="value => handleChange(value, !!errorMessage)">
    <template v-for="(_, slotName) in $slots"
              #[slotName]="slotData">
      <slot :name="slotName"
            v-bind="slotData" />
    </template>
  </core-dropdown>
</template>

<script setup lang="ts">
import { CoreDropdown, ECoreInputSize, IDropdownProps } from '@common/core-ui'
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<
    IDropdownProps
>(), {
  action: false,
  addItemEnabled: false,
  bordered: true,
  centered: false,
  checkbox: null,
  clearable: false,
  disabled: false,
  error: '',
  itemsKey: 'value',
  listLoading: false,
  listPlaceholderText: '',
  maxHeight: 300,
  minWidth: 200,
  modelValue: null,
  moreEnabled: false,
  moreLabel: 'Voir plus',
  multiSelection: false,
  name: '',
  placeholder: '',
  pullRight: false,
  searchEnabled: false,
  selectedClassEnabled: true,
  size: ECoreInputSize.Medium,
  toggleOnHover: false
})

const { errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)
</script>
