<template>
  <core-dropdown :model-value="status"
                 min-width="100%"
                 clearable
                 :size="ECoreDropdownSize.Small"
                 :items="getStatusFilter()"
                 :placeholder="t('placeholder.status')"
                 @update:model-value="updateFilterStatus" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownValue } from '@common/core-ui'

import { EBadgeResource, getBadge } from '@/helpers/badge'
import { useOperationStore } from '@/stores/operation'

const { t } = useI18n()
const operationsStore = useOperationStore()
const statusLabel = ref<{ label: string, value: string }[]>([])

defineProps<{
  status?: string[]
}>()

onMounted(async () => {
  statusLabel.value = await operationsStore.getStatus()
})

const emit = defineEmits<{
  'update:status': [value: TDropdownValue[]]
}>()

function updateFilterStatus (item: TDropdownValue) {
  emit('update:status', item ? [item] : [])
}

function getStatusFilter () {
  return statusLabel.value.map((status, index) => {
    return { id: index, label: getBadge(status.value, EBadgeResource.Operations)?.label, value: status.value }
  })
}
</script>
