<template>
  <validation-form ref="settingsLimits"
                   v-slot="{ meta, isSubmitting }"
                   :validation-schema="validationSchema"
                   data-cy="account-settings.fees.form"
                   @submit="onSubmit">
    <details-block :header-size="EDefaultSize.Large"
                   :loading="loading"
                   :title="t('general.customer_fee')"
                   :columns="1">
      <template #col-1>
        <template v-for="(value, cardType, i) in feeSetting"
                  :key="cardType">
          <form-line :title="t(`card.fee`, { card_name: t(`card.${cardType}`) })"
                     :class="{ 'mt-0': i === 0 }" />
          <form-line :title="t('general.annual_fee_per_card')">
            <template #input>
              <select-dropdown v-model="feeSetting[cardType]"
                               :name="`worldline_membership_fees.${cardType}`"
                               :data-cy="`account-settings.fees.${cardType}`"
                               :size="ECoreInputSize.Medium"
                               min-width="100%"
                               :placeholder="t('placeholder.select')"
                               items-key="value"
                               :items="feeList[cardType]">
                <template #label="{ selectedValue, inputClass, isEmpty }">
                  <span class="core-input"
                        :class="[{ placeholder: isEmpty }, inputClass]">
                    {{ feeList[cardType]?.find(item => item.value === selectedValue)?.label || selectedValue }}
                  </span>
                </template>
              </select-dropdown>
            </template>
          </form-line>
        </template>
      </template>
      <template #footer-right>
        <core-button :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.Transparent"
                     class="mr-1"
                     @click="cancelForm" />
        <core-button :text="t('action.validate')"
                     data-cy="account-settings.fees.submit"
                     type="submit"
                     :disabled="!meta.valid || isSubmitting"
                     :theme="ECoreButtonTheme.Primary"
                     :loading="isSubmitting" />
      </template>
    </details-block>
  </validation-form>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  CoreButton,
  ECoreButtonTheme, ECoreInputSize
} from '@common/core-ui'
import _, { cloneDeep } from 'lodash'
import * as yup from 'yup'

import { useAccountStore } from '@/stores/account'
import { useWorldlineStore } from '@/stores/worldline'
import { IAccountDetails, IAccountFeeSettings } from '@/types/account.d'
import { EDefaultSize } from '@/types/size.d'
import { IWorldlineFeeResponse } from '@/types/worldline.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import FormLine from '@/components/form/FormLine.vue'
import SelectDropdown from '@/components/inputs/SelectDropdown.vue'

const router = useRouter()
const accountStore = useAccountStore()
const { t } = useI18n()

const loading = ref(true)

const props = defineProps<{
  accountUuid: string
  accountDetails: IAccountDetails
}>()

const emit = defineEmits<{
  'update:account-details': [value: IAccountDetails]
}>()

const feeList = ref<IWorldlineFeeResponse>({})

const feeSetting = ref<IAccountFeeSettings>(_.cloneDeep(props.accountDetails.worldline_membership_fees))

const validationSchema = computed(() => {
  return yup.object({})
})

async function onSubmit () {
  const payload = cloneDeep(feeSetting.value)
  const data = await accountStore.updateFeeSettings(props.accountUuid, payload)
  if (data) {
    const accountDetails = cloneDeep(props.accountDetails)
    accountDetails.worldline_membership_fees = data
    emit('update:account-details', accountDetails)
  }
}

onMounted(async () => {
  loading.value = true
  const { data } = await useWorldlineStore().getFees({ company_type: props.accountDetails.company.type })
  feeList.value = data
  loading.value = false
})

function cancelForm () {
  router.go(-1)
}
</script>

<style scoped lang="stylus">
.account-settings-limit
  &__input-addon
    display flex
    align-items center
    gap .8rem

    span
      font-size 1.55rem
      color $color-gray-600

  &__currency
    color $color-gray-600
</style>
