<template>
  <div class="login-block">
    <div class="login-block__title">
      <h1 class="title title--md">
        {{ title }}
      </h1>
      <p class="text text--default">
        {{ subtitle }}
      </p>
    </div>
    <div v-if="hasForm"
         class="login-block__form">
      <slot name="form" />
    </div>
    <div v-if="hasLink"
         class="login-block__link core-link core-link--theme-gray-light">
      <slot name="link" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue'

defineProps<{
  title: string,
  subtitle: string
}>()

const slots = useSlots()
const hasForm = computed(() => !!slots.form)
const hasLink = computed(() => !!slots.link)
</script>

<style lang="stylus" scoped>
.login-block
  display flex
  flex-direction column
  align-items center
  background-color $white
  padding 4rem
  width 43rem

  &__title
    max-width auto
    text-align center
    margin-bottom 3.5rem

  .title
    margin 0 0 2.4rem
    text-transform uppercase

  &__form
    width 100%

    &:deep(.core-input-group:last-child)
      margin-bottom 3.4rem

    &:deep(.core-input-group:not(:first-child) .core-input-group__addons)
      border-top none

  &__link
    margin-top 3rem
</style>
