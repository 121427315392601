<template>
  <div style="display: contents">
    <sidepanel-line :label="t('general.user')"
                    :data="user.full_name"
                    clickable
                    @click="goToProfile" />
    <sidepanel-line :label="t('general.proof_of_identity')"
                    :data="hasIdentityProof ? t('general.view_document') : '-'"
                    :clickable="hasIdentityProof"
                    @click="user?.identity_card_documents?.length && appStore.showFileViewer(user.identity_card_documents)" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useAppStore } from '@/stores/app'
import { IAccount } from '@/types/account.d'
import { IUserDetails } from '@/types/user.d'

import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const { t } = useI18n()
const router = useRouter()
const appStore = useAppStore()

const props = defineProps<{
  user: IUserDetails
  account: IAccount
}>()

function goToProfile () {
  router.push({ name: 'user-information', params: { uuid: props.user.uuid }, query: { account_uuid: props.account.uuid } })
}

const hasIdentityProof = computed(() => {
  return !!props.user?.identity_card_documents?.length
})
</script>
