<template>
  <core-dropdown :model-value="type"
                 min-width="100%"
                 clearable
                 :size="ECoreDropdownSize.Small"
                 :items="typeList"
                 :placeholder="t('placeholder.request_type')"
                 @update:model-value="updateFilterType" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownValue } from '@common/core-ui'

import { useBankValidationRequestStore } from '@/stores/bank-validation-request'

const { t } = useI18n()
const typeList = ref<{ label: string, value: string }[]>([])

defineProps<{
  type?: string[]
}>()

const emit = defineEmits<{
  'update:type': [value: TDropdownValue[]]
}>()

function updateFilterType (item: TDropdownValue) {
  emit('update:type', item ? [item] : [])
}

onMounted(async () => {
  const { data, error } = await useBankValidationRequestStore().getBankValidationRequestTypes()
  if (!error) {
    typeList.value = data
  }
})
</script>
