import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import {
  IAccount,
  IAccountDetails,
  IAccountFeeSettings,
  IAccountForm,
  IAccountSettings,
  IAccountsParams
} from '@/types/account.d'
import { EResponseHeader } from '@/types/headers.d'

const { t } = i18n.global

export const useAccountStore = defineStore('account', {

  actions: {
    async getAccounts (params: IAccountsParams): Promise<{ data: IAccount[], headers: RawAxiosResponseHeaders | AxiosResponseHeaders, error: boolean }> {
      try {
        const response = await axiosClient.get('/accounts', { params })
        return { data: response.data, headers: response.headers, error: false }
      } catch (error) {
        console.error(error)
        return { data: [], headers: {}, error: true }
      }
    },

    async getAccount (uuid: string): Promise<IAccountDetails | null> {
      try {
        const response = await axiosClient.get(`/accounts/${uuid}`)
        return response.data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getStatus (): Promise<{ label: string, value: string }[]> {
      try {
        const { data } = await axiosClient.get('/accounts/status')
        return data
      } catch (error) {
        console.error(error)
        return []
      }
    },

    async getLimitSettings (uuid: string): Promise<{data: IAccountSettings | null, headers: RawAxiosResponseHeaders | AxiosResponseHeaders}> {
      try {
        const { data, headers } = await axiosClient.get(`/accounts/${uuid}/settings/transaction_amount_monthly`)
        return { data, headers }
      } catch (error) {
        console.error(error)
        return { data: null, headers: {} }
      }
    },

    async updateLimitSettings (uuid: string, payload: IAccountSettings): Promise<{data: IAccountSettings | null, headers: RawAxiosResponseHeaders | AxiosResponseHeaders}> {
      try {
        const { data, headers } = await axiosClient.put(`/accounts/${uuid}/settings/transaction_amount_monthly`, payload)
        const toastMessage = headers[EResponseHeader.BankValidationRequest] ? t('success.update_account_limits_request') : t('success.update_account_limits')
        showToastSuccess(toastMessage)
        return { data, headers }
      } catch (error) {
        console.error(error)
        return { data: null, headers: {} }
      }
    },

    async updateFeeSettings (uuid: string, payload: IAccountFeeSettings): Promise<IAccountSettings | null> {
      try {
        const { data } = await axiosClient.put(`/accounts/${uuid}/fees`, payload)
        showToastSuccess(t('success.update_account_fee'))
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async createAccount (payload: IAccountForm): Promise<{ data: IAccountDetails | null, error: unknown | null }> {
      try {
        const { data } = await axiosClient.post('/accounts', payload)

        return { data, error: null }
      } catch (error) {
        console.error(error)
        return { data: null, error }
      }
    }
  }
})
