import { defineStore } from 'pinia'

import axiosClient from '@/api'

interface ICountryParams {
  search?: string
}

export const useCountryStore = defineStore('country', {

  actions: {
    async getCountries (params?: ICountryParams, signal: AbortSignal | undefined = undefined) {
      try {
        const response = await axiosClient.get('/countries', {
          params, signal
        })

        return { data: response.data, error: null }
      } catch (error) {
        console.error(error)

        return { data: [], error }
      }
    }
  }
})
