<template>
  <core-date-picker v-bind="props"
                    :model-value="birthDate"
                    :error="errorMessage"
                    @update:model-value="value => handleChange(dayjs(value).format('YYYY-MM-DD'), !!errorMessage)" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CoreDatePicker, ECoreInputSize, IDatePickerProps } from '@common/core-ui'
import dayjs from 'dayjs'
import { useField } from 'vee-validate'

interface IProps extends Omit<IDatePickerProps, 'modelValue'> {
  modelValue: string
}

const props = withDefaults(defineProps<
    IProps
>(), {
  bordered: true,
  clearable: false,
  disabled: false,
  disabledDays: null,
  error: '',
  format: 'dd/MM/yyyy',
  locale: 'en',
  mondayFirst: false,
  monthPicker: false,
  name: '',
  placeholder: '',
  size: ECoreInputSize.Medium
})

const { errorMessage, handleChange } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)

const birthDate = computed(() => {
  return props.modelValue ? dayjs(props.modelValue, 'YYYY-MM-DD').toDate() : null
})
</script>
