<template>
  <img v-if="blobUrl"
       :src="blobUrl"
       :style="elementStyle"
       alt="avatar">
  <div v-else
       :style="elementStyle" />
</template>

<script setup lang="ts">

import { computed, onMounted, ref, watch } from 'vue'

import { downloadPicture } from '@/helpers/utils'
import { IFile } from '@/types/card.d'

const props = withDefaults(defineProps<{
  picture: IFile | null
  size?: number
  rounded?: boolean
  placeholderLabel?: string
}>(), {
  size: 100,
  rounded: false,
  placeholderLabel: ''
})

onMounted(() => {
  getPicture()
})

watch(() => props.picture, getPicture)
watch(() => props.placeholderLabel, getPicture)

const pictureBlob = ref<Blob | null>(null)
const blobUrl = computed(() => {
  return pictureBlob.value ? URL.createObjectURL(pictureBlob.value) : null
})

const elementStyle = computed(() => {
  return {
    height: `${props.size}px`,
    width: `${props.size}px`,
    borderRadius: props.rounded ? `${props.size}px` : '0'
  }
})

async function getPicture () {
  let data = null
  if (props.picture?.url) {
    const result = await downloadPicture(props.picture?.url)
    data = result.data

    if (data) pictureBlob.value = data
  }

  if (!props.picture?.url || !data) {
    getPlaceholderAvatar(props.placeholderLabel)
  }
}

function getPlaceholderAvatar (placeholderLabel: string, size = props.size * 2) {
  const canvas = document.createElement('canvas')
  canvas.width = canvas.height = size

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  // bg
  ctx.rect(0, 0, size, size)
  ctx.fillStyle = stringToHslColor(placeholderLabel, 60, 70)
  ctx.fill()
  // txt
  ctx.fillStyle = '#ffffff'
  const fontSize = size * 0.45
  ctx.font = `${fontSize}px sans-serif`
  const textString = placeholderLabel.split(' ').map((s:string) => s[0]?.toUpperCase() || '').join('')
  const textWidth = ctx.measureText(textString).width
  ctx.fillText(textString, size / 2 - textWidth / 2, size / 2 + fontSize / 2.5)

  canvas.toBlob(blob => { pictureBlob.value = blob })
}

function stringToHslColor (str: string, s: number, l: number) {
  str = replaceAccents(str)
  let hash = 0
  for (let i = 0; i < str.length; i++) { hash += str.charCodeAt(i) }
  const h = hash % 360
  return `hsl(${h}, ${s}%, ${l}%)`
}

function replaceAccents (data: string) {
  return data
    .toLowerCase()
    .replace(/\n/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}
</script>

<style scoped lang="stylus">
div
  display inline-block
  vertical-align middle
  border 1px solid $borderColor
img
  object-fit cover
</style>
