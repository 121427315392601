<template>
  <core-icon :size="size"
             :theme="cardIconTheme"
             :name="ECoreIconBoList.BoCreditCard" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  CoreIcon,
  ECoreIconBoList,
  ECoreIconTheme,
  TCoreIconSize
} from '@common/core-ui'

import { EPhysicalCardType, EVirtualCardType } from '@/types/card.d'

const props = defineProps<{
  size: TCoreIconSize,
  type: EPhysicalCardType | EVirtualCardType
}>()

const cardIconTheme = computed(() => {
  if (props.type === EPhysicalCardType.InfiniteCardConsumer) return ECoreIconTheme.Warning
  if (props.type === EPhysicalCardType.PremierCardConsumer) return ECoreIconTheme.Warning
  if (props.type === EPhysicalCardType.GoldCard) return ECoreIconTheme.Warning
  return ECoreIconTheme.Black
})
</script>
