import { parsePhoneNumber } from 'awesome-phonenumber'
import IBAN from 'iban'
import * as yup from 'yup'

yup.addMethod(yup.string, 'iban', function (errorMessage) {
  return this.test('test-iban', errorMessage, function (value) {
    const { path, createError } = this

    return (
      IBAN.isValid(value || '') ||
      createError({ path, message: errorMessage })
    )
  })
})

yup.addMethod(yup.string, 'phone', function (errorMessage) {
  return this.test('test-phone', errorMessage, function (value) {
    const { path, createError } = this

    return (
      parsePhoneNumber(value || '').valid ||
      createError({ path, message: errorMessage })
    )
  })
})
