import { createApp } from 'vue'
import Toast, { POSITION, useToast } from 'vue-toastification'
import { ToastOptionsAndRequiredContent } from 'vue-toastification/src/types'
import FloatingVue from 'floating-vue'
import { Form } from 'vee-validate'

import 'dayjs/locale/fr'
import './helpers/yup'

import App from './App.vue'
import i18n from './locales'
import router from './router'
import pinia from './stores'

import 'floating-vue/dist/style.css'

const app = createApp({
  ...App
})

// plugins
app
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(Toast, {
    transition: 'toast-animation',
    icon: false,
    position: POSITION.TOP_RIGHT,
    closeOnClick: false,
    draggable: false,
    timeout: 5000,
    toastClassName: 'toast-centered',
    filterBeforeCreate (toast: ToastOptionsAndRequiredContent, toasts: ToastOptionsAndRequiredContent[]) {
      const lastToast = toasts.at(-1)
      if (lastToast?.id && JSON.stringify(lastToast.content) === JSON.stringify(toast.content)) {
        useToast().dismiss(lastToast.id)
      }
      return toast
    }
  })

  .use(FloatingVue, {
    distance: 10,
    triggers: ['hover', 'focus'],
    themes: {
      tooltip: {
        placement: 'bottom'
      },
      'tooltip-sm': {
        $extend: 'tooltip',
        placement: 'bottom'
      },
      'tooltip-md': {
        $extend: 'tooltip',
        placement: 'bottom'
      },
      'tooltip-lg': {
        $extend: 'tooltip',
        placement: 'bottom'
      },
      poptip: {
        placement: 'bottom'
      }
    }
  })

// components
app
  .component('ValidationForm', Form)

app.mount('#app')
