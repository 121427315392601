import { createRouter, createWebHistory } from 'vue-router'

import guard from './guard'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})

router.beforeEach(guard)

export default router
