<template>
  <div style="display: contents">
    <sidepanel-line v-if="card.digits"
                    :label="t('general.card_number')"
                    :data="card.digits"
                    clickable
                    @click="router.push({ name: 'card-information', params: { uuid: card.uuid } })" />
    <sidepanel-line :label="t('general.holder')"
                    :data="`${card.first_name} ${card.last_name}`"
                    :clickable="!!(card.user?.uuid && card.account.uuid)"
                    @click="router.push({ name: 'user-information', params: { uuid: card.user?.uuid }, query: { account_uuid: card.account.uuid } })" />
    <sidepanel-line :label="t('general.card_type')"
                    :data="t(`card.${card.type}`)" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { ICardDetails } from '@/types/card.d'

import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'
const { t } = useI18n()
const router = useRouter()

defineProps<{
  card: ICardDetails
}>()
</script>
