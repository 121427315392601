import i18n from '@/locales'
import { ICurrency } from '@/types/currency.d'

interface IOptions extends Intl.NumberFormatOptions {
  isNegative?: boolean
  maximumFractionDigits?: number
  fixedFractionDigits?: number
}

export const getCurrencyNumberFormat = (currency: ICurrency, options: IOptions = {}) => {
  return new Intl.NumberFormat(
    i18n.global.locale.value,
    { style: 'currency', currency: currency.iso, currencyDisplay: 'narrowSymbol', ...options }
  )
}

export const getCurrencySymbolPosition = (currency: ICurrency) => {
  const parts = getCurrencyNumberFormat(currency).formatToParts(0)

  const index = parts.findIndex(p => p.type === 'currency')

  return index === 0 ? 'left' : 'right'
}

export const getCurrencySymbol = (currency: ICurrency) => {
  const parts = getCurrencyNumberFormat(currency).formatToParts(0)

  return parts.find(p => p.type === 'currency')?.value || ''
}

export function formatAmount (amount: number, currency: ICurrency, options: IOptions = {}): string {
  if (options.isNegative) amount = amount * -1
  delete options.isNegative
  if (Number.isInteger(amount)) {
    options.maximumFractionDigits = 0
  }
  if (options.fixedFractionDigits) {
    options.minimumFractionDigits = options.fixedFractionDigits
    options.maximumFractionDigits = options.fixedFractionDigits
  }
  return getCurrencyNumberFormat(currency, options).format(amount)
}

export const formatPartAmount = (amount: number, currency: ICurrency, options: IOptions = {}) => {
  return getCurrencyNumberFormat(currency, options).formatToParts(amount)
    .reduce((acc, cur) => {
      if (cur.type === 'decimal') { acc.push('') }
      acc[acc.length - 1] += cur.value
      return acc
    }, [''])
}

export const formatPercentage = (value: number) => {
  return new Intl.NumberFormat(i18n.global.locale.value, { style: 'percent', minimumFractionDigits: 2 }).format(value / 100)
}

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat(i18n.global.locale.value).format(value || 0)
}

// Convert bytes into megabytes
export const formatFileSize = (size: number) => {
  // Math.ceil() to round up 0,0001MB => return 0,01MB (and not 0MB)
  return new Intl.NumberFormat(i18n.global.locale.value, { style: 'unit', unit: 'megabyte' }).format(Math.ceil(size / 1e4) / 100)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any) => {
  return !isNaN(parseFloat(value))
}
