<template>
  <core-dropdown clearable
                 min-width="100%"
                 :model-value="departments"
                 :size="ECoreDropdownSize.Small"
                 :items="departmentList"
                 :list-loading="loading"
                 :placeholder="t('placeholder.department')"
                 @update:model-value="updateFilterDepartments" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownItem, TDropdownValue } from '@common/core-ui'

import { useDepartmentStore } from '@/stores/department'

const { t } = useI18n()
const departmentsStore = useDepartmentStore()

const departmentList = ref<TDropdownItem[]>([])
const loading = ref(false)

defineProps<{
  departments?: string[]
}>()

const emit = defineEmits<{
  'update:departments': [value: TDropdownValue]
}>()

onMounted(async () => {
  loading.value = true
  const { data } = await departmentsStore.getDepartments()
  departmentList.value = data.map((item, index) => {
    return { id: index, label: item.label, value: item.uuid }
  })
  loading.value = false
})

function updateFilterDepartments (item: TDropdownValue) {
  emit('update:departments', item ? [item] : [])
}
</script>
