<template>
  <sidepanel-layout class="category-limit">
    <template #body>
      <sidepanel-block :title="t('card.setting.category_param')">
        <template #content>
          <details-label :title="t('card.setting.category_limit', { category: category.label })"
                         class="mb-1" />

          <details-line v-for="(item, key) in categoryModel.limit"
                        :key="key"
                        height="5.6rem">
            <template #content>
              <core-toggle-switch :model-value="item !== null"
                                  :label="labels[key]"
                                  @update:model-value="onToggle(key)" />
            </template>
            <template #param>
              <span v-if="item === null"
                    class="placeholder">
                {{ t('placeholder.no_limit') }}
              </span>

              <core-input-number v-else
                                 :model-value="item === null ? 0 : item"
                                 :name="key"
                                 :disabled="item === null"
                                 @update:model-value="value => onInput(key, value)">
                <template #append>
                  {{ getCurrencySymbol(currency) }}
                </template>
              </core-input-number>
            </template>
          </details-line>
        </template>
      </sidepanel-block>
    </template>

    <template #footer>
      <sidepanel-footer>
        <core-button block
                     class="mr-1"
                     :text="t('action.cancel')"
                     :theme="ECoreButtonTheme.GrayLow"
                     @click="cancel" />
        <core-button block
                     :text="t('action.validate')"
                     :disabled="isValidateButtonDisabled"
                     @click="validate" />
      </sidepanel-footer>
    </template>
  </sidepanel-layout>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, CoreInputNumber, CoreToggleSwitch, ECoreButtonTheme } from '@common/core-ui'
import _ from 'lodash'

import bus from '@/helpers/eventBus'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAppStore } from '@/stores/app'
import { EAuthorizationCategoryPaymentPeriod, IAuthorizationCategory } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'
import { ECardSettingEvent } from '@/types/eventBus.d'

import DetailsLabel from '@/components/details-block/DetailsLabel.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'
import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelFooter from '@/components/sidepanel/SidepanelFooter.vue'
import SidepanelLayout from '@/components/sidepanel/SidepanelLayout.vue'

const { t } = useI18n()
const appStore = useAppStore()

const props = defineProps<{
  category: IAuthorizationCategory
  currency: ICurrency
}>()

const categoryModel = ref<IAuthorizationCategory>(_.cloneDeep(props.category))

const labels = {
  [EAuthorizationCategoryPaymentPeriod.PaymentUnitLimit]: t('general.per_transaction'),
  [EAuthorizationCategoryPaymentPeriod.PaymentDailyLimit]: t('general.daily'),
  [EAuthorizationCategoryPaymentPeriod.PaymentWeeklyLimit]: t('general.weekly'),
  [EAuthorizationCategoryPaymentPeriod.PaymentMonthlyLimit]: t('general.monthly'),
  [EAuthorizationCategoryPaymentPeriod.PaymentAnnuallyLimit]: t('general.annually')
}

function onToggle (key: EAuthorizationCategoryPaymentPeriod) {
  const value = categoryModel.value.limit[key]
  categoryModel.value.limit[key] = value === null ? 0 : null
}

function onInput (key: EAuthorizationCategoryPaymentPeriod, value: number) {
  categoryModel.value.limit[key] = value
}

const isValidateButtonDisabled = computed(() => {
  return _.isEqual(props.category, categoryModel.value)
})

function cancel () {
  appStore.closeSidePanel()
}

function validate () {
  bus.emit(ECardSettingEvent.UpdateAuthorizationCategory, categoryModel.value)
  appStore.closeSidePanel()
}
</script>

<style lang="stylus" scoped>
.category-limit
  .placeholder
    color $color-gray-500

</style>
