import { ECoreBannerTheme } from '@common/core-ui'
import { saveAs } from 'file-saver'

import axiosClient from '@/api'
import { showToastError, showToastSuccess } from '@/helpers/utils/notification'
import { globalTranslation } from '@/locales'
import { useAppStore } from '@/stores/app'

export const getFlatErrorsArray = errors => errors.reduce((acc, cur) => cur.errors && cur.field ? acc.concat(getFlatErrorsArray(cur.errors)) : acc.concat(cur), [])

export const downloadFileFromUrl = async (url, params = {}, successIfNoContent = false) => {
  if (!url) {
    useAppStore().showBanner({ auto_dismiss: false, closable: false, message: 'Adresse du fichier inconnue', theme: ECoreBannerTheme.Danger })
    return
  }

  try {
    const { data, headers, status } = await axiosClient.get(url, { responseType: 'blob', params })
    if (headers['content-disposition']) {
      const filename = headers['content-disposition'].split('filename=')[1].replace(/(^"|"$)/g, '')
      saveAs(data, typeof filename === 'string' ? filename : 'file.pdf')
    } else if (status === 200) {
      const { message } = JSON.parse(await data.text())
      showToastSuccess(message)
    } else if (status === 204) {
      if (successIfNoContent) showToastSuccess(globalTranslation('message.success.operation.export'))
      else showToastError(globalTranslation('alerts.download.empty_response'))
    }
  } catch (e) {
    console.error(e)
  }
}

export const deleteFileFromUrl = async url => {
  try {
    await axiosClient.delete(url)

    return { success: true, error: null }
  } catch (error) {
    console.error(error)
    return { success: false, error }
  }
}

export const downloadPicture = async url => {
  try {
    const { data } = await axiosClient.get(url, { responseType: 'blob' })

    return { data, error: null }
  } catch (error) {
    console.error(error)
    return { data: null, error }
  }
}

export function getColNumber (index, n) {
  return ((index + 1) % n) || n
}
