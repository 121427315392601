import { useAuthStore } from '@/stores/auth'
import { EPermission } from '@/types/permission.d'

type item = {
  permissions?: EPermission[]
} & object

export default function hasPermission (permissions: EPermission | EPermission[], item?: item, strict = false) {
  const permissionItem = item || useAuthStore().boUser
  if (!permissionItem?.permissions) return false

  permissions = Array.isArray(permissions) ? permissions : [permissions]

  if (strict) {
    return permissions.every((permission: EPermission) => permissionItem.permissions?.includes(permission))
  }
  return permissionItem.permissions.some((permission: EPermission) => permissions.includes(permission))
}
