<template>
  <div class="sidepanel-layout">
    <slot>
      <div class="sidepanel-layout__body">
        <slot name="body" />
      </div>
      <slot name="footer" />
    </slot>
  </div>
</template>

<style lang="stylus" scoped>
@import '../../assets/stylesheets/partials/mixins.styl'

.sidepanel-layout
  height 100%
  max-height 100vh
  overflow-y auto
  display flex
  flex-direction column

  &__body
    overflow auto
    custom-scrollbar()
</style>
