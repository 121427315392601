<template>
  <core-dropdown :model-value="modelValue"
                 :list-placeholder-text="listPlaceholderText"
                 :min-width="minWidth"
                 :multi-selection="multiSelection"
                 :items="items"
                 :error="errorMessage"
                 :search-enabled="searchEnabled"
                 :disabled="disabled"
                 :size="size"
                 :name="name"
                 :list-loading="loading"
                 :items-key="itemsKey || 'value'"
                 @blur="handleBlur"
                 @change="handleChange"
                 @focus="onFocus"
                 @search-input="debounceSearch"
                 @update:model-value="value => handleChange(value, !!errorMessage)">
    <template #label="{ isEmpty, inputClass }">
      <span class="core-input"
            :class="[{ placeholder: isEmpty && !error, disabled }, inputClass]">
        <core-loader v-if="initLoading"
                     :theme="ECoreLoaderTheme.GrayMid" />
        <slot v-else
              name="label"
              v-bind="{ selectedItems, isEmpty }" />
      </span>
    </template>
  </core-dropdown>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { CoreDropdown, CoreLoader, ECoreLoaderTheme } from '@common/core-ui'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { useField } from 'vee-validate'

import { useApiStore } from '@/stores/api'

type TModelValue = string | number | object | boolean | null

const props = defineProps<{
  error?: string
  disabled?: boolean
  listPlaceholderText?: string
  itemsKey?: string
  minWidth?: string
  modelValue: string | string[] | null
  multiSelection?: boolean
  name: string
  asyncRequest:(arg: Record<string, unknown>, signal: AbortSignal) => Promise<{ data: unknown[], error: unknown }>
  asyncParams?: Record<string, unknown>
  searchEnabled: boolean
  size: string
}>()

interface TDropdownItem {
  label: string
  [key: string]: string
}

const apiStore = useApiStore()

const selectedItems = ref<TDropdownItem[]>([])
const items = ref<TDropdownItem[]>([])
const hasSearched = ref<boolean>(false)
const loading = ref<boolean>(false)
const initLoading = ref<boolean>(false)
let abortController : AbortController | null = null

const emit = defineEmits<{
  'update:modelValue': [value: TModelValue | TModelValue[]]
  'update-selected-items': [value: TDropdownItem[]]
}>()

const { errorMessage, handleChange, handleBlur } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    validateOnValueUpdate: false
  }
)

watch(() => props.modelValue, setSelectedItems)

onMounted(async () => {
  initLoading.value = true
  await getItems()
  if ((props.multiSelection && props.modelValue?.length) || props.modelValue) {
    setSelectedItems(props.modelValue)
  }
  initLoading.value = false
})

async function getItems (params: { search?: string } = {}) {
  loading.value = true
  items.value = []
  abortController?.abort()
  abortController = apiStore.addAbortController()
  const { data, error } = await props.asyncRequest({ ...props.asyncParams, ...params }, abortController.signal)
  if (data) items.value = data as TDropdownItem[]
  if (params?.search) hasSearched.value = true
  if (error?.code !== AxiosError.ERR_CANCELED) {
    abortController = null
    loading.value = false
  }
}

function onFocus () {
  if (hasSearched.value) {
    getItems()
    hasSearched.value = false
  }
}

function onSearch (searchValue: string) {
  getItems({ search: searchValue })
}

const debounceSearch = _.debounce(onSearch, 300)

function setSelectedItems (value: TModelValue) {
  const key = props.itemsKey || 'value'
  if (props.multiSelection) {
    const index = items.value.findIndex(item => item[key] === value)
    if (index > -1) {
      selectedItems.value.push(items.value[index])
    }
    selectedItems.value = selectedItems.value.filter(item => props.modelValue?.includes(item[key]))
  } else {
    const index = items.value.findIndex(item => item[key] === value)
    if (index > -1) {
      selectedItems.value = [items.value[index]]
    }
  }
  emit('update-selected-items', selectedItems.value)
}
</script>
