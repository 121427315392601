import { ECoreBannerTheme } from '@common/core-ui'
import { uniqueId } from 'lodash/util'

import { Model } from '@/models/Model'
import { EBannerShow, IBanner } from '@/types/banner.d'
import { Nullable, TDatesRange } from '@/types/default.d'

export class ModelBanner extends Model {
  readonly id: string
  readonly auto_dismiss: boolean
  readonly closable: boolean
  readonly dates: TDatesRange<Nullable<string>>
  readonly link: string
  public message: string
  readonly show_web: EBannerShow
  readonly type: ECoreBannerTheme

  private constructor (properties: Partial<IBanner>) {
    super()

    this.id = properties?.id || uniqueId()
    this.auto_dismiss = properties?.auto_dismiss || false
    this.closable = properties?.closable || false
    this.dates = properties?.dates || {} as TDatesRange<Nullable<string>>
    this.link = properties?.link || ''
    this.message = properties?.message || ''
    this.type = properties?.type || ECoreBannerTheme.Info
    this.show_web = properties?.show_web || EBannerShow.All

    return this.onConstructed()
  }

  static create (properties?: Partial<IBanner>): ModelBanner {
    return new ModelBanner(properties || {})
  }
}
