<template>
  <details-line v-if="modelValue"
                class="card-limit-item"
                height="5.6rem">
    <template #content>
      <core-toggle-switch class="core-toggle"
                          :model-value="modelValue.enabled"
                          :label="label"
                          :disabled="disabled"
                          @update:model-value="onToggleClick" />
    </template>

    <template #param>
      <span v-if="!modelValue.enabled && inputPlaceholder"
            class="placeholder">
        {{ inputPlaceholder }}
      </span>

      <core-input-number v-else
                         :model-value="modelValue.limit"
                         :name="label"
                         :disabled="!modelValue.enabled || disabled"
                         @update:model-value="onInputNumber">
        <template v-if="$slots['input-append']"
                  #append>
          <slot name="input-append" />
        </template>
      </core-input-number>
    </template>
  </details-line>
</template>

<script setup lang="ts">
import { CoreInputNumber, CoreToggleSwitch } from '@common/core-ui'
import _ from 'lodash'

import { ICardTransactionSetting } from '@/types/card.d'

import DetailsLine from '@/components/details-block/DetailsLine.vue'

const props = defineProps<{
  disabled: boolean
  label: string
  modelValue: ICardTransactionSetting
  inputPlaceholder?: string
}>()

const emit = defineEmits<{
  'update:model-value': [value: ICardTransactionSetting]
}>()

function onToggleClick (value: boolean) {
  const newValue = _.cloneDeep(props.modelValue)
  newValue.enabled = value
  emit('update:model-value', newValue)
}

function onInputNumber (value: number|null) {
  const newValue = _.cloneDeep(props.modelValue)
  newValue.limit = value || 0
  emit('update:model-value', newValue)
}
</script>

<style scoped lang="stylus">
.card-limit-item
  .core-toggle
    white-space nowrap

  &__param
    .placeholder
      color $color-gray-500
</style>
