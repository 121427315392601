import { markRaw } from 'vue'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state'

import router from '@/router'
import { EResponseHeader } from '@/types/headers.d'
import { Pagination } from '@/types/pagination.d'

const pinia = createPinia()
pinia.use(createPersistedState({ debug: false }))
pinia.use(PiniaSharedState({ enable: false }))
pinia.use(({ store }) => {
  store.router = markRaw(router)
  store.parsePagination = (headers: RawAxiosResponseHeaders | AxiosResponseHeaders): Pagination => ({
    current: parseInt(headers[EResponseHeader.PaginationCurrentPage] || '1'),
    count: parseInt(headers[EResponseHeader.PaginationPageCount] || '1'),
    perPage: parseInt(headers[EResponseHeader.PaginationPerPage] || '50'),
    totalItems: parseInt(headers[EResponseHeader.PaginationTotalCount] || '0')
  })
})

export default pinia
