<template>
  <core-alert v-if="checkRight('owner')"
              class="invitation-rights__owner-alert"
              :theme="ECoreAlertTheme.Info"
              :text="ownerText" />
  <details-block v-if="!loading"
                 data-cy="account-invitations.detail.rights"
                 :title="t('general.access_right', 2)"
                 :columns="2">
    <template v-for="index in [1, 2]"
              :key="`col-${index}`"
              #[`col-${index}`]>
      <div>
        <details-line v-for="right in rightsDetails.filter((item, i) => getColNumber(i, 2) === index)"
                      :key="right.right"
                      :v-if="right.available !== false"
                      :title="right.title"
                      :data="[checkRight(right.right) ? t('general.yes') : t('general.no')]">
          <template #icon>
            <core-icon v-bind="getIconProps(right.right)" />
          </template>
        </details-line>
      </div>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreAlert, CoreIcon, ECoreAlertTheme, ECoreIconBoList, ECoreIconTheme, TCoreIconSize } from '@common/core-ui'

import { getColNumber } from '@/helpers/utils'
import { useInvitationStore } from '@/stores/invitation'
import { IInvitationDetails, IInvitationRights } from '@/types/invitation.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()
const invitationsStore = useInvitationStore()

const props = defineProps<{
  invitationDetails: IInvitationDetails
}>()

const invitationRights = ref<IInvitationRights | null>(null)

const loading = ref(false)

onMounted(async () => {
  loading.value = true
  invitationRights.value = await invitationsStore.getInvitationRights(props.invitationDetails.uuid)
  loading.value = false
})

const rightsDetails = computed(() => {
  return [
    { right: 'operations', title: t('general.operations') },
    { right: 'accounting', title: t('general.accounting') },
    { right: 'cards', title: t('general.cards') },
    { right: 'card_virtual_request_user', title: t('general.card_virtual_request_user') },
    { right: 'statements', title: t('general.statements') },
    { right: 'shared_access', title: t('general.shared_access') }
  ].filter(item => Object.prototype.hasOwnProperty.call(invitationRights.value || {}, item.right))
})

function checkRight (right: string): boolean {
  return invitationRights.value ? invitationRights.value[right] === true : false
}

function getIconProps (right: string): { name: ECoreIconBoList, size: TCoreIconSize, theme: ECoreIconTheme } {
  const hasRight = checkRight(right)
  return {
    name: hasRight ? ECoreIconBoList.BoCheckCircle : ECoreIconBoList.BoXCircle,
    size: 16,
    theme: hasRight ? ECoreIconTheme.Success : ECoreIconTheme.Danger
  }
}

const ownerText = computed(() => {
  return props.invitationDetails.is_main_owner ? t('general.main_owner_rights') : t('general.owner_rights')
})
</script>

<style lang="stylus" scoped>
.invitation-rights
  &__owner-alert
    margin-bottom 2rem
</style>
