<template>
  <div class="sidepanel-block">
    <div class="sidepanel-block__title">
      <h3>{{ title }}</h3>
      <button v-if="collapsable"
              class="collapse-button"
              @click="toggleBlock">
        <core-icon :name="opened ? ECoreIcon.BoChevronUp : ECoreIcon.BoChevronDown"
                   :size="16" />
      </button>
    </div>
    <div v-if="opened"
         class="sidepanel-block__content"
         :class="{ fees: feesBlock }">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { CoreIcon, ECoreIcon } from '@common/core-ui'

defineProps<{
  title: string
  feesBlock?: boolean
  collapsable?: boolean
}>()

const opened = ref<boolean>(true)

function toggleBlock () {
  opened.value = !opened.value
}
</script>

<style scoped lang="stylus">
.sidepanel-block
  width 100%
  display flex
  flex-direction column

  &__title
    min-height 6.4rem
    padding 2rem
    border-bottom 1px solid $color-gray-150
    display flex
    justify-content space-between

    h3
      font-size 1.7rem
      line-height 2.4rem
      font-weight 500
      color #202121
      margin-left 1rem
      margin-bottom 0

    .collapse-button
      border none
      background none

  &__content
    padding 2rem 3rem
    display flex
    flex-direction column
    gap .4rem

    &.fees
      gap 2rem

</style>
