<template>
  <sidepanel-block :title="t('general.modification_details')"
                   collapsable>
    <template #content>
      <template v-if="isRecurringCard">
        <template v-if="isPeriodicityUpdated">
          <sidepanel-line :label="t('general.current_periodicity')"
                          :data="t(`general.${currentPeriodicity}`)"
                          strikethrough />
          <sidepanel-line :label="t('general.new_periodicity')"
                          :data="t(`general.${newPeriodicity}`)" />
        </template>
        <sidepanel-line v-else
                        :label="t('general.periodicity')"
                        :data="t(`general.${currentPeriodicity}`)" />

        <template v-if="isLimitUpdated">
          <sidepanel-line :label="t('general.current_ceiling')"
                          :data="formatAmountLimit(currentLimit)"
                          strikethrough />
          <sidepanel-line :label="t('general.new_ceiling')"
                          :data="formatAmountLimit(newLimit)" />
        </template>
        <sidepanel-line v-else
                        :label="t('general.ceiling')"
                        :data="formatAmountLimit(currentLimit)" />
      </template>
      <template v-else>
        <template v-if="isInitialAmountVisible">
          <sidepanel-line :label="t('general.current_overall_limit')"
                          :data="formatAmountLimit(cardLimitSettingsUpdate.before.initial_amount)"
                          strikethrough />
          <sidepanel-line :label="t('general.new_overall_limit')"
                          :data="formatAmountLimit(cardLimitSettingsUpdate.after.initial_amount)" />
        </template>

        <template v-if="isWithdrawalVisible">
          <sidepanel-line :label="t('general.current_withdrawal_limits')"
                          :data-list="getLimits(cardLimitSettingsUpdate.before.withdrawal_transaction, cardLimitSettingsUpdate.before.withdrawal)"
                          strikethrough />

          <sidepanel-line :label="t('general.new_withdrawal_limits')"
                          :data-list="getLimits(cardLimitSettingsUpdate.after.withdrawal_transaction, cardLimitSettingsUpdate.after.withdrawal)" />
        </template>

        <template v-if="isPaymentVisible">
          <sidepanel-line :label="t('general.current_payment_limits')"
                          :data-list="getLimits(cardLimitSettingsUpdate.before.payment_transaction, cardLimitSettingsUpdate.before.payment)"
                          strikethrough />
          <sidepanel-line :label="t('general.new_payment_limits')"
                          :data-list="getLimits(cardLimitSettingsUpdate.after.payment_transaction, cardLimitSettingsUpdate.after.payment)" />
        </template>
      </template>
    </template>
  </sidepanel-block>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEqual } from 'lodash'

import { formatAmount } from '@/helpers/utils/number'
import {
  ECardPeriod,
  ICardDetails,
  ICardLimitSetting,
  ICardTransactionSetting,
  IPaymentWithdrawalSetting, isRecurringVirtualCardType
} from '@/types/card.d'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'
const { t } = useI18n()

type TCardLimitSettingsUpdate = {
  before: ICardLimitSetting
  after: ICardLimitSetting
}

const props = defineProps<{
  card: ICardDetails
  cardLimitSettingsUpdate: TCardLimitSettingsUpdate
}>()

const currency = { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }

function checkVisibility (before: unknown, after: unknown) {
  return before && after && !isEqual(before, after)
}
const isInitialAmountVisible = checkVisibility(props.cardLimitSettingsUpdate.before.initial_amount, props.cardLimitSettingsUpdate.after.initial_amount)
const isWithdrawalVisible = checkVisibility(props.cardLimitSettingsUpdate.before.withdrawal, props.cardLimitSettingsUpdate.after.withdrawal) || checkVisibility(props.cardLimitSettingsUpdate.before.withdrawal_transaction, props.cardLimitSettingsUpdate.after.withdrawal_transaction)
const isPaymentVisible = checkVisibility(props.cardLimitSettingsUpdate.before.payment, props.cardLimitSettingsUpdate.after.payment) || checkVisibility(props.cardLimitSettingsUpdate.before.payment_transaction, props.cardLimitSettingsUpdate.after.payment_transaction)

const getLimits = function (transactionSetting?: ICardTransactionSetting, periodSetting?: IPaymentWithdrawalSetting) {
  return transactionSetting && periodSetting
    ? [
        {
          label: t('general.per_transaction'),
          value: formatAmountLimit(transactionSetting.limit, transactionSetting.enabled)
        },
        {
          label: t('general.per_day'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Daily].limit, periodSetting[ECardPeriod.Daily].enabled)
        },
        {
          label: t('general.per_week'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Weekly].limit, periodSetting[ECardPeriod.Weekly].enabled)
        },
        {
          label: t('general.per_month'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Monthly].limit, periodSetting[ECardPeriod.Monthly].enabled)
        },
        {
          label: t('general.per_year'),
          value: formatAmountLimit(periodSetting[ECardPeriod.Annually].limit, periodSetting[ECardPeriod.Annually].enabled)
        }
      ]
    : []
}

function formatAmountLimit (amount?: number, enabled = true): string {
  if (amount === undefined || !enabled) return t('general.none_m')
  return formatAmount(amount, currency)
}

// Recurring card
const isRecurringCard = isRecurringVirtualCardType(props.card.type)
function getRecurringPeriodicity (periodSetting?: IPaymentWithdrawalSetting) {
  if (!periodSetting || !isRecurringCard) return null
  return Object.entries(periodSetting).find(([key, value]) => key && value.enabled)?.[0] as ECardPeriod || null
}
const currentPeriodicity: ECardPeriod|null = getRecurringPeriodicity(props.cardLimitSettingsUpdate.before.payment)
const newPeriodicity: ECardPeriod|null = getRecurringPeriodicity(props.cardLimitSettingsUpdate.after.payment)
const currentLimit: number = isRecurringCard ? props.cardLimitSettingsUpdate.before.payment?.[currentPeriodicity].limit || 0 : 0
const newLimit: number = isRecurringCard ? props.cardLimitSettingsUpdate.after.payment?.[newPeriodicity].limit || 0 : 0
const isPeriodicityUpdated: boolean = isRecurringCard ? currentPeriodicity !== newPeriodicity : false
const isLimitUpdated: boolean = isRecurringCard ? currentLimit !== newLimit : false
</script>
