<template>
  <component-filters :show-result="showFiltersResult && !loading"
                     :result-count="resultCount"
                     @clear="clearFilters">
    <filter-type :type="filters.type"
                 :available-types="availableTypes"
                 @update:type="value => updateFilter('type', value)" />
    <filter-status :status="filters.status"
                   @update:status="value => updateFilter('status', value)" />
    <filter-expiration-date :expiration-date="filters.expiration_date"
                            @update:expiration-date="value => updateFilter('expiration_date', value)" />
  </component-filters>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TDropdownValue } from '@common/core-ui'

import { ECardStatus } from '@/types/card.d'

import FilterExpirationDate from '@/pages/accounts/account-id/tabs/cards/filters/FilterExpirationDate.vue'
import FilterStatus from '@/pages/accounts/account-id/tabs/cards/filters/FilterStatus.vue'
import FilterType from '@/pages/accounts/account-id/tabs/cards/filters/FilterType.vue'

import ComponentFilters from '@/components/ComponentFilters.vue'

type TCardsFilters = {
  type: string[]
  status: ECardStatus[]
  expiration_date?: string
}
const props = defineProps<{
  filters: TCardsFilters,
  availableTypes: string[],
  loading: boolean,
  resultCount: number
}>()

const emit = defineEmits<{
  'update:filters': [value: TCardsFilters]
}>()

function updateFilter (key: 'type' | 'status' | 'expiration_date', value: TDropdownValue) {
  const newFilters = {
    ...props.filters,
    [key]: value
  }

  emit('update:filters', newFilters)
}

function clearFilters () {
  emit('update:filters', {
    type: [],
    status: [],
    expiration_date: undefined
  })
}

const showFiltersResult = computed(() => {
  return !!props.filters.type.length ||
         !!props.filters.status.length ||
         !!props.filters.expiration_date
})
</script>
