<template>
  <login-block :title="t('login.title')"
               data-cy="login.view"
               :subtitle="t('login.subtitle')">
    <template v-if="config.loginMethod === ELoginMethod.Credentials"
              #form>
      <credentials-method />
    </template>
    <template v-else-if="config.loginMethod === ELoginMethod.Oauth"
              #form>
      <o-auth-method />
    </template>
    <template v-if="config.loginMethod === ELoginMethod.Credentials"
              #link>
      <router-link :to="{ name: 'password-recovery' }"
                   data-cy="login.password.forgot"
                   class="core-link core-link--theme-gray-light">
        {{ t('login.forgot_password') }}
      </router-link>
    </template>
  </login-block>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import config from '@/config'
import { ELoginMethod } from '@/config/config.d'
import { useAuthStore } from '@/stores/auth'

import CredentialsMethod from '@/pages/login/components/CredentialsMethod.vue'
import LoginBlock from '@/pages/login/components/LoginBlock.vue'
import OAuthMethod from '@/pages/login/components/OAuthMethod.vue'

// DATA
const authStore = useAuthStore()
const { t } = useI18n()

// LIFECYCLE
onMounted(() => {
  authStore.clearInactivityTimeout()
  useAuthStore().resetStores()
})
</script>
