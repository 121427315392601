import { Component, nextTick, shallowRef } from 'vue'
import { ECoreBannerTheme, UploadedFile } from '@common/core-ui'
import { defineStore } from 'pinia'

import { ModelBanner } from '@/models/Banner/ModelBanner'
import { AppState, RetryRequestFunction } from '@/types/app.d'
import { IBanner } from '@/types/banner.d'

export const useAppStore = defineStore('app', {
  state: (): AppState => {
    return {
      banners: [],
      modal: {
        active: false,
        component: shallowRef(null),
        props: {}
      },
      sidePanel: {
        active: false,
        component: shallowRef(null),
        props: {}
      },
      fileViewer: {
        active: false,
        files: []
      },
      sidebar: {
        active: false
      },
      requestsToRetry: []
    }
  },

  getters: {},

  actions: {
    dismissBanner (id: string) {
      this.banners = this.banners.filter(banner => banner.id !== id)
    },

    showBanner (bannerDetails: IBanner) {
      const banner = ModelBanner.create(bannerDetails)
      this.banners.push(banner)

      if (banner.auto_dismiss) {
        setTimeout(() => {
          this.dismissBanner(banner.id)
        }, 3000)
      }
    },

    showBannerError (message:string) {
      this.showBanner({
        closable: true,
        auto_dismiss: true,
        type: ECoreBannerTheme.Danger,
        message
      })
    },

    closeSidePanel (): void {
      if (!this.sidePanel.active) return

      setTimeout(() => {
        this.sidePanel.active = false
      }, 10)
    },

    showSidePanel (component: Component, props: { [propName: string]: unknown } = {}) {
      if (!component) {
        console.error('SidePanel missing params', 'component', component)

        return false
      }
      this.sidePanel = {
        active: true,
        component: shallowRef(component),
        props
      }
    },

    closeFileViewer (): void {
      if (!this.fileViewer.active) return

      setTimeout(() => {
        this.fileViewer.active = false
      }, 10)
    },

    showFileViewer (files: UploadedFile[]) {
      if (!files.length) {
        console.error('FileViewer missing params', 'files', files)

        return false
      }
      this.fileViewer = {
        active: true,
        files
      }
    },

    closeModal (): void {
      this.modal.active = false
    },

    resetModal (): void {
      this.modal.component = null
    },

    async showModal (component: Component, props: { [propName: string]: unknown } = {}) {
      if (!component) {
        console.error('Modal missing params', 'component', component)

        return false
      }

      this.modal = {
        active: false,
        component: shallowRef(component),
        props
      }

      await nextTick()
      this.modal.active = true
    },

    toggleSidebar (state: boolean | null = null) {
      this.sidebar.active = state === null ? !this.sidebar.active : state
    },

    addRetryRequest (request: RetryRequestFunction) {
      this.requestsToRetry.push(request)
    },

    executeAllRetryRequests () {
      while (this.requestsToRetry.length > 0) {
        this.requestsToRetry.shift()?.()
      }
    },

    removeRetryRequests () {
      this.requestsToRetry = []
    }
  }
})
