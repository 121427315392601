<template>
  <details-block data-cy="account-detail.information.block"
                 :title="t('general.information')"
                 :columns="1">
    <template #col-1>
      <details-line :title="t('general.company_name')"
                    :data="[accountDetails.company.name]" />
      <details-line :title="t('general.account_number')"
                    :data="[accountDetails.external_ref]" />
      <details-line :title="t('general.iban')"
                    :data="[accountDetails.iban || '-']" />
      <details-line :title="t('general.holder')"
                    :data="[accountHolder]"
                    :clickable="canSeeUserDetails"
                    @click="canSeeUserDetails && redirectToUserDetails()" />
      <details-line :title="t('general.activated_physical_cards')"
                    :data="[cardsCount.physical.active]"
                    :total="[cardsCount.physical.total]">
        <template #icon>
          <component-card-icon :type="EPhysicalCardType.Card"
                               :size="16" />
        </template>
      </details-line>
      <details-line :title="t('general.activated_virtual_cards')"
                    :data="[cardsCount.virtual.active]"
                    :total="[cardsCount.virtual.total]">
        <template #icon>
          <component-card-icon :type="EPhysicalCardType.Card"
                               :size="16" />
        </template>
      </details-line>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import hasPermission from '@/helpers/permissions'
import { IAccountDetails } from '@/types/account.d'
import { EPhysicalCardType, isVirtualCardType } from '@/types/card.d'
import { EPermission } from '@/types/permission.d'

import ComponentCardIcon from '@/components/ComponentCardIcon.vue'
import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()
const router = useRouter()

const props = defineProps<{
  accountDetails: IAccountDetails
}>()

const accountHolder = computed(() => {
  return props.accountDetails.company.user ? `${props.accountDetails.company.user.full_name}` : '-'
})

const cardsCount = computed(() => {
  const types = props.accountDetails.counts.cards.types
  return Object.entries(types).reduce((acc, [type, { total, active }]) => {
    if (isVirtualCardType(type)) {
      acc.virtual.total += total
      acc.virtual.active += active
    } else {
      acc.physical.total += total
      acc.physical.active += active
    }
    return acc
  }, {
    physical: { total: 0, active: 0 },
    virtual: { total: 0, active: 0 }
  })
})

const canSeeUserDetails = computed(() => {
  return props.accountDetails.company.user ? hasPermission(EPermission.UserRead) : false
})

function redirectToUserDetails () {
  if (!props.accountDetails.company.user) return
  router.push({
    name: 'user-information',
    params: {
      uuid: props.accountDetails.company.user.uuid
    },
    query: {
      account_uuid: props.accountDetails.uuid
    }
  })
}
</script>
