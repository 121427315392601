<template>
  <details-block v-if="cardLimitSetting?.payment"
                 data-cy="card-detail.information.payment-block"
                 :columns="2"
                 :title="t('general.payment', 2)">
    <template #col-1>
      <details-line :title="t(`general.spent`)"
                    :data="[formatAmount(cardConsumed, currency)]"
                    :total="[formatAmount(cardLimit, currency)]">
        <template #content>
          <span class="balance"> / {{ formatAmount(cardLimit, currency) }}</span>
        </template>
      </details-line>
    </template>

    <template #col-2>
      <details-line :title="t(`general.periodicity`)">
        <template #data>
          {{ t(`general.${cardPeriodicity}`) }}
        </template>
      </details-line>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { formatAmount } from '@/helpers/utils/number'
import { ECardPeriod, ICardLimitSetting } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()

const props = defineProps<{
  cardLimitSetting: ICardLimitSetting
}>()

const currency = computed<ICurrency>(() => {
  return { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
})

const cardPeriodicity = computed<ECardPeriod | null>(() => {
  if (!props.cardLimitSetting?.payment) return null
  return Object.entries(props.cardLimitSetting?.payment).find(([key, value]) => key && value.enabled)?.[0] as ECardPeriod || null
})

const cardLimit = computed<number>(() => {
  if (!cardPeriodicity.value || !props.cardLimitSetting?.payment) return 0
  return props.cardLimitSetting?.payment?.[cardPeriodicity.value as ECardPeriod].limit
})

const cardConsumed = computed<number>(() => {
  if (!cardPeriodicity.value || !props.cardLimitSetting?.payment) return 0
  return props.cardLimitSetting?.payment?.[cardPeriodicity.value as ECardPeriod].consumed
})
</script>
