<template>
  <core-modal active
              :size="ECoreModalSize.Small"
              :title="t('modal.set_full_access.title')"
              :closable-button="false"
              data-cy="set-full-access.modal-action.container">
    <template #body>
      <p class="pre-line">
        {{ t('modal.set_full_access.content', { name: props.userName }) }}
      </p>
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.GrayHigh"
                   :appearance="ECoreButtonAppearance.Outlined"
                   :text="t('action.cancel')"
                   @click="closeModal" />
      <core-button :text="t('action.confirm')"
                   :loading="loading"
                   :disabled="loading"
                   data-cy="set-full-access.modal-action.button"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  ECoreButtonAppearance,
  ECoreButtonTheme,
  ECoreModalSize
} from '@common/core-ui'

import bus from '@/helpers/eventBus'
import { useAppStore } from '@/stores/app'
import { useUsersStore } from '@/stores/user'
import { EUserEvent } from '@/types/eventBus.d'

const { t } = useI18n()

const props = defineProps<{
  userName: string,
  userUuid: string,
  accountUuid: string,
}>()

const appStore = useAppStore()
const userStore = useUsersStore()

const loading = ref(false)

onMounted(async () => {
  bus.on(EUserEvent.SetFullAccess, closeModal)
})

onUnmounted(() => {
  bus.off(EUserEvent.SetFullAccess, closeModal)
})

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  loading.value = true
  await userStore.setUserFullAccess(props.userUuid, props.accountUuid)
  loading.value = false
}
</script>
