<template>
  <id-layout :badges="[invitationDetails?.status || '']"
             :badge-resource="EBadgeResource.Invitations"
             go-back-route="account-users"
             :go-back-route-params="{ uuid: accountUuid }"
             :title="invitationDetails?.full_name || ''"
             :content-loaded="!!invitationDetails">
    <template #view>
      <router-view :invitation-details="invitationDetails"
                   :has-bank-validation-request="hasBankValidationRequest" />
    </template>
    <template v-if="items.length"
              #actions>
      <core-button-actions :append-icon="ECoreIcon.BoChevronDown"
                           :theme="ECoreButtonTheme.GrayHigh"
                           data-cy="account-invitations.detail.button-actions"
                           :text="t('general.action')"
                           :items="items" />
    </template>
  </id-layout>
</template>

<script setup lang="ts">
import { Component, computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButtonActions, ECoreButtonTheme, ECoreIcon } from '@common/core-ui'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'

import { EBadgeResource } from '@/helpers/badge'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { useAppStore } from '@/stores/app'
import { useInvitationStore } from '@/stores/invitation'
import { EInvitationEvent } from '@/types/eventBus.d'
import { EResponseHeader } from '@/types/headers.d'
import { IInvitationDetails } from '@/types/invitation.d'
import { EPermission } from '@/types/permission.d'

import IdLayout from '@/components/layout/IdLayout.vue'

import ModalResendInvitation from './modal/ModalResendInvitation.vue'

const invitationStore = useInvitationStore()
const appStore = useAppStore()
const { t } = useI18n()

const props = defineProps<{
  uuid: string,
  accountUuid: string,
}>()

const invitationDetails = ref<IInvitationDetails | null>(null)
const responseHeaders = ref<RawAxiosResponseHeaders | AxiosResponseHeaders | null>(null)

onMounted(async () => {
  const { data, headers } = await invitationStore.getInvitationDetails(props.uuid, props.accountUuid)
  invitationDetails.value = data
  responseHeaders.value = headers
  bus.on(EInvitationEvent.Resend, updateInvitationDetails)
})

onUnmounted(() => {
  bus.off(EInvitationEvent.Resend, updateInvitationDetails)
})

const items = computed(() => {
  return [
    {
      label: t('action.resend_invitation'),
      icon: ECoreIcon.BoMail,
      onClick: () => showModal(ModalResendInvitation),
      permission: hasPermission(EPermission.InvitationRenew)
    }
  ].filter(item => item.permission)
})

const hasBankValidationRequest = computed(() => {
  return !!responseHeaders.value?.[EResponseHeader.BankValidationRequest]
})

function showModal (modal: Component) {
  appStore.showModal(modal, {
    invitationDetails: invitationDetails.value
  })
}

function updateInvitationDetails (updatedInvitation: IInvitationDetails) {
  invitationDetails.value = updatedInvitation
}
</script>
