<template>
  <component-filters :show-result="showFiltersResult && !loading"
                     :result-count="resultCount"
                     @clear="clearFilters">
    <filter-date-range-picker class="bank-validation-requests-filters__date-range-picker"
                              :from="filters.start"
                              :to="filters.end"
                              @update:from="value => updateFilter('start', value)"
                              @update:to="value => updateFilter('end', value)" />
    <filter-type :type="filters.type"
                 class="bank-validation-requests-filters__type"
                 @update:type="value => updateFilter('type', value)" />
  </component-filters>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TDropdownValue } from '@common/core-ui'

import { IBankValidationRequestParams } from '@/types/bank-validation-request.d'
import { IOperationParams } from '@/types/operation.d'

import ComponentFilters from '@/components/ComponentFilters.vue'

import FilterDateRangePicker from './filters/FilterDateRangePicker.vue'
import FilterType from './filters/FilterType.vue'

const props = defineProps<{
  filters: IBankValidationRequestParams,
  loading: boolean,
  resultCount: number
}>()

const emit = defineEmits<{
  'update:filters': [value: IOperationParams]
}>()

function updateFilter (key: 'start' | 'end' | 'type', value: string | number | TDropdownValue[] | null) {
  const newFilters = {
    ...props.filters,
    [key]: value
  }

  emit('update:filters', newFilters)
}

function clearFilters () {
  emit('update:filters', {
    start: undefined,
    end: undefined,
    type: []
  })
}

const showFiltersResult = computed(() => {
  return !!props.filters.start ||
         !!props.filters.end ||
         !!props.filters.type?.length
})
</script>

<style scoped lang="stylus">
.bank-validation-requests-filters
  &__date-range-picker
    grid-column span 2
  &__type
    grid-column span 1
</style>
