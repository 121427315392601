<template>
  <div class="list-block">
    <div v-if="!disabled"
         class="list-block__input">
      <core-input v-model:model-value="input"
                  name="list-block-input"
                  :placeholder="searchPlaceholder || ''"
                  :max-length="30"
                  @key-down="chooseItem"
                  @key-up="submit">
        <template v-if="!items"
                  #button>
          <core-button :append-icon="ECoreIconList.Plus"
                       :theme="ECoreButtonTheme.GrayLow"
                       :disabled="isInputInvalid"
                       @click="submit(null)" />
        </template>
      </core-input>

      <div v-if="availableItems.length"
           class="input-dropdown">
        <div v-for="(item, index) in availableItems"
             :key="item"
             class="input-dropdown__item"
             :class="{ 'input-dropdown__item--focus': index !== null && index === selectedIndex }"
             @click="selectItem(item)">
          <slot name="available-item"
                v-bind="{ item }">
            {{ item }}
          </slot>
        </div>
      </div>
    </div>
    <div class="list-block__body">
      <div v-if="isListEmpty"
           class="list-block__placeholder">
        {{ placeholder || '' }}
      </div>
      <div v-else
           class="list">
        <div v-for="(item, index) in modelValue"
             :key="index"
             class="list__item">
          <slot name="item"
                v-bind="{ item }">
            <core-icon :name="ECoreIconList.CategoryCustom"
                       :theme="ECoreIconTheme.GrayHigh"
                       :size="18" />
            {{ item }}
          </slot>
          <button v-if="!disabled"
                  type="button"
                  class="remove"
                  @click="removeItem(index)">
            <core-icon :name="ECoreIcon.Delete"
                       :size="16"
                       :theme="ECoreIconTheme.GrayLow" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { CoreButton, CoreIcon, CoreInput, ECoreButtonTheme, ECoreIcon, ECoreIconList, ECoreIconTheme } from '@common/core-ui'
import _ from 'lodash'

const props = defineProps<{
  disabled: boolean
  modelValue: string[]
  placeholder?: string
  items?: string[]
  uppercase?: boolean
  searchPlaceholder?: string
  searchFilter?:(itemList: string[], input: string) => string[]
}>()

const emit = defineEmits<{
  'update:model-value': [value: string[]]
}>()

const input = ref<string>('')
const selectedIndex = ref<number|null>(null)

const isInputEmpty = computed<boolean>(() => {
  return input.value === ''
})

const isListEmpty = computed<boolean>(() => {
  return props.modelValue.length === 0
})

const isInputInvalid = computed(() => {
  return isInputEmpty.value || props.modelValue.some(v => v.toLowerCase() === input.value.toLowerCase())
})

const availableItems = computed<string[]>(() => {
  if (!props.items || !props.searchFilter || !input.value) return []
  return props.searchFilter(props.items, input.value)
    .filter(item => !props.modelValue.includes(item))
})

function chooseItem (event: KeyboardEvent) {
  if (!availableItems.value.length) return
  let index = selectedIndex.value
  const max = availableItems.value.length - 1
  if (event.key === 'ArrowDown') index = index === null ? 0 : index + 1
  if (event.key === 'ArrowUp') index = index === null ? max : index - 1
  if (index !== null) selectedIndex.value = index < 0 ? 0 : index > max ? max : index
}

function selectItem (item: string) {
  if (!item) return
  emit('update:model-value', [...props.modelValue, item.toUpperCase()])
  input.value = ''
}

function removeItem (index: number) {
  if (index > props.modelValue.length) return
  const newValue = _.cloneDeep(props.modelValue)
  newValue.splice(index, 1)
  emit('update:model-value', newValue)
}

function submit (event: KeyboardEvent|null = null) {
  if ((event && event?.key !== 'Enter') || isInputInvalid.value) return
  if (availableItems.value && selectedIndex.value !== null) {
    emit('update:model-value', [...props.modelValue, availableItems.value[selectedIndex.value]])
    input.value = ''
    selectedIndex.value = null
  } else if (!props.items) {
    emit('update:model-value', [...props.modelValue, props.uppercase ? input.value.toUpperCase() : input.value])
    input.value = ''
  }
}

</script>

<style scoped lang="stylus">
.list-block
  margin 0.8rem 0
  border 1px solid $borderColor
  padding 0 2rem
  overflow auto
  custom-scrollbar()

  &__input
    position relative
    margin-top 2rem
    .input-dropdown
      position absolute
      z-index 10
      top calc(100% + 0.4rem)
      width 100%
      padding 0.4rem 0
      border 1px solid $borderColor
      background-color $white
      box-shadow $boxShadow

      &__item
        box-sizing border-box
        display block
        cursor pointer
        padding 0.6rem 1.2rem
        &:hover
          color $color-gray-900
        &--focus
          background-color $color-gray-50
          color $color-gray-900

  &__body
    height 20rem

  &__placeholder
    display flex
    align-items center
    justify-content center
    height 100%

  .list
    padding 1.2rem 0

    &__item
      position relative
      border 1px solid $borderColor
      padding 1.2rem 3.2rem 1.2rem 1.2rem
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      &:not(:last-child)
        margin-bottom 1.2rem

      .core-ic
        vertical-align text-bottom
        margin-right 0.4rem

      .remove
        padding 0
        background none
        border none
        position absolute
        right 0.8rem
        top 50%
        transform translateY(-50%)
</style>
