import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import i18n from '@/locales'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)

export const formatDateText = (value: Dayjs | number, format = 'LL') => {
  if (!value) { return }

  if (typeof value === 'number' && Number.isInteger(value)) {
    return dayjs.unix(value).format(format)
  }

  if (value instanceof dayjs) {
    return value.format(format)
  }

  return dayjs.tz(value, 'Europe/Paris').format(format)
}

export const formatDate = (value: Dayjs | number) => {
  return formatDateText(value, 'L')
}

export const formatTime = (value: Dayjs) => {
  return formatDateText(value, 'LT')
}

export const formatDateTime = (value: Dayjs | number) => {
  return formatDateText(value, `LL [${i18n.global.t('general.at')}] LT`)
}
