<template>
  <details-block data-cy="card-detail.information.main-block"
                 :title="t('general.information')"
                 :columns="2">
    <template #col-1>
      <details-line :title="t('general.card_number')"
                    :data="[cardDetails.digits]" />
      <details-line :title="t('general.customer_account', 1)"
                    :data="[`${cardDetails.account.company.name} - ${cardDetails.account.label}`]" />
      <details-line :title="t('general.holder')"
                    :data="[cardDetails.user ? cardDetails.user.full_name : '-']"
                    :clickable="canSeeUserDetails"
                    @click="canSeeUserDetails && redirectToUserDetails()" />
      <details-line :title="t('general.expiration_date')"
                    :data="[formattedExpirationDate]" />
      <details-line :title="t('general.card_type')"
                    :data="[t(`card.${cardDetails.type}`)]">
        <template #icon>
          <component-card-icon :type="props.cardDetails.type"
                               :size="16" />
        </template>
      </details-line>
    </template>
    <template #col-2>
      <details-line :title="t('general.creation_date')"
                    :data="[formattedCreationDate]" />
      <details-line v-if="isDeliveryAddressVisible"
                    :title="t('general.delivery_address')"
                    :data="[t('action.see_details')]"
                    clickable
                    @click="showDeliveryAddress" />
      <details-line :title="t('general.activation_date')"
                    :data="[formattedActivationDate]" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

import hasPermission from '@/helpers/permissions'
import { formatDateText, formatDateTime } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { ICardDetails, isPhysicalCardType } from '@/types/card.d'
import { EPermission } from '@/types/permission.d'

import CardDeliveryAddress from '@/pages/cards/card-id/tabs/information/CardDeliveryAddress.vue'

import ComponentCardIcon from '@/components/ComponentCardIcon.vue'
import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()
const router = useRouter()
const props = defineProps<{
  cardDetails: ICardDetails
}>()

const formattedCreationDate = computed(() => {
  return formatDateTime(props.cardDetails.created_at)
})

const formattedActivationDate = computed(() => {
  if (props.cardDetails.activated_at == null) {
    return '-'
  }
  return formatDateTime(props.cardDetails.activated_at)
})

const formattedExpirationDate = computed(() => {
  return formatDateText(dayjs(props.cardDetails.expiration_date))
})

const canSeeUserDetails = computed(() => {
  return props.cardDetails.user ? hasPermission(EPermission.UserRead) : false
})

function redirectToUserDetails () {
  if (!props.cardDetails.user || !props.cardDetails.account) return
  router.push({
    name: 'user-information',
    params: {
      uuid: props.cardDetails.user.uuid
    },
    query: {
      account_uuid: props.cardDetails.account.uuid
    }
  })
}

const isDeliveryAddressVisible = computed(() => {
  return props.cardDetails.address?.street && isPhysicalCardType(props.cardDetails.type)
})

function showDeliveryAddress () {
  useAppStore().showSidePanel(CardDeliveryAddress, { cardDetails: props.cardDetails })
}

</script>
