<template>
  <details-block data-cy="card.settings.security"
                 :title="t('menu.security_settings')"
                 :columns="2"
                 :loading="loading && !securitySettingModel">
    <template v-for="index in [1, 2]"
              :key="index"
              #[`col-${index}`]>
      <template v-if="securitySettingModel">
        <details-line v-for="setting in (Object.keys(securitySettingModel).filter((v, i) => getColNumber(i, 2) === index) as ECardSecuritySetting[])"
                      :key="setting"
                      height="6rem">
          <template #content>
            <core-toggle-switch v-model:model-value="securitySettingModel[setting]"
                                :label="t(`card.setting.${setting}`)"
                                :disabled="loading || isDisabled" />
          </template>
        </details-line>
      </template>
    </template>
    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveSecuritySetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, CoreToggleSwitch, ECoreButtonTheme } from '@common/core-ui'
import _ from 'lodash'

import hasPermission from '@/helpers/permissions'
import { getColNumber } from '@/helpers/utils'
import { useCardStore } from '@/stores/card'
import { ECardSecuritySetting, ICardDetails, ICardSecuritySetting } from '@/types/card.d'
import { EPermission } from '@/types/permission.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
  cardDetails: ICardDetails
}>()

const loading = ref<boolean>(true)
const securitySetting = ref<ICardSecuritySetting | null>(null)
const securitySettingModel = ref<ICardSecuritySetting | null>(null)

onMounted(async () => {
  securitySetting.value = await cardStore.getCardSecuritySetting(props.cardUuid)
  securitySettingModel.value = _.cloneDeep(securitySetting.value)
  loading.value = false
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingSecurityUpdate, props.cardDetails)
})

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(securitySetting.value, securitySettingModel.value) && !isDisabled.value
})

function cancel () {
  securitySettingModel.value = _.cloneDeep(securitySetting.value)
}

async function saveSecuritySetting () {
  if (!securitySettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardSecuritySetting(props.cardUuid, securitySettingModel.value)
  if (data) securitySetting.value = data
  loading.value = false
}
</script>
