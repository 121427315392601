import { TGlobalConfig } from '@/config/config.d'

const config: TGlobalConfig = {
  results: {
    perPage: 25,
    perPageList: [25, 50, 100]
  }
}

export default config
