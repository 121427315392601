<template>
  <core-dropdown clearable
                 min-width="100%"
                 :model-value="type"
                 :size="ECoreDropdownSize.Small"
                 :items="typeList"
                 :list-loading="loading"
                 :placeholder="t('placeholder.card_type')"
                 @update:model-value="updateFilterType" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownItem, TDropdownValue } from '@common/core-ui'

const { t } = useI18n()

const typeList = ref<TDropdownItem[]>([])
const loading = ref(false)

const props = defineProps<{
  availableTypes: string[]
  type?: string[]
}>()

const emit = defineEmits<{
  'update:type': [value: TDropdownValue]
}>()

onMounted(async () => {
  loading.value = true
  typeList.value = props.availableTypes.map((type, index) => {
    return { id: index, label: t((`card.${type}`)), value: type }
  })
  loading.value = false
})

function updateFilterType (item: TDropdownValue) {
  emit('update:type', item ? [item] : [])
}
</script>
