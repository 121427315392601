export interface IHeaderResponse {
    [key: string]: string | string[] | undefined
}

export enum ERequestHeader {
    ClientBrand = 'X-Client-Brand',
    BackOfficeVersion = 'X-Back-Office-Version',
    ClientDateTime = 'X-Client-DateTime',
    RequestedWith = 'X-Requested-With',
    AcceptLanguage = 'Accept-Language',
}

export enum EResponseHeader {
    AuthorizationToken = 'x-authorization-token',
    PaginationCurrentPage = 'x-pagination-current-page',
    PaginationPageCount = 'x-pagination-page-count',
    PaginationPerPage = 'x-pagination-per-page',
    PaginationTotalCount = 'x-pagination-total-count',
    Redirect = 'x-redirect',
    BankValidationRequest = 'x-bank-validation-request'
}
