<template>
  <details-block data-cy="account-users.detail.information"
                 :title="t('general.information')"
                 :columns="2">
    <template #col-1>
      <details-line :title="t('general.civility')"
                    :data="[gender]" />
      <details-line :title="t('general.first_name')"
                    :data="[firstName]" />
      <details-line :title="t('general.last_name')"
                    :data="[lastName]" />
      <details-line :title="t('general.email')"
                    :data="[mail]" />
      <details-line :title="t('general.phone')"
                    :data="[phone]" />
      <details-line :title="t('general.birth_date')"
                    :data="[birthDate]" />
    </template>
    <template #col-2>
      <details-line v-if="hasPermission(EPermission.UserIdentityCardRead)"
                    :title="t('general.proof_of_identity')"
                    :data="[proofOfIdentity]"
                    :clickable="hasProofOfIdentity"
                    @click="hasProofOfIdentity && appStore.showFileViewer(userDetails.identity_card_documents)" />
      <details-line :title="t('general.access_right', 2)">
        <template #data>
          <account-user-access-rights :access-rights="userDetails.rights"
                                      :is-main-owner="userDetails.is_main_owner" />
        </template>
      </details-line>
      <details-line :title="t('general.number_of_cards')"
                    :data="[getUserCardsCount()]" />
      <details-line :title="t('general.creation_date')"
                    :data="[creationDate]" />
      <details-line :title="t('general.last_login_date')"
                    :data="[lastLoginDate]" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import hasPermission from '@/helpers/permissions'
import { formatDateText, formatDateTime } from '@/helpers/utils/date'
import { useAppStore } from '@/stores/app'
import { EPermission } from '@/types/permission.d'
import { EUserGender, IUserDetails } from '@/types/user.d'

import AccountUserAccessRights from '@/pages/accounts/account-id/tabs/users/AccountUserAccessRights.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()
const appStore = useAppStore()

const props = defineProps<{
  userDetails: IUserDetails
}>()

const gender = computed(() => {
  if (!props.userDetails.gender) return '-'
  return t(`general.${EUserGender[props.userDetails.gender]?.toLowerCase()}`)
})

const firstName = computed(() => {
  return props.userDetails.first_name
})

const lastName = computed(() => {
  return props.userDetails.last_name
})

const mail = computed(() => {
  return props.userDetails.email
})

const phone = computed(() => {
  if (!props.userDetails.phone) return '-'
  return props.userDetails.phone
})

const birthDate = computed(() => {
  if (!props.userDetails.birth_date) return '-'
  return formatDateText(dayjs(props.userDetails.birth_date))
})

const hasProofOfIdentity = computed(() => {
  return !!props.userDetails.identity_card_documents?.length
})

const proofOfIdentity = computed(() => {
  return hasProofOfIdentity.value ? t('general.view_document') : '-'
})

const creationDate = computed(() => {
  return formatDateTime(props.userDetails.created_at)
})

const lastLoginDate = computed(() => {
  if (!props.userDetails.last_login_at) return '-'
  return formatDateTime(props.userDetails.last_login_at)
})

function getUserCardsCount (): number {
  return Object.values(props.userDetails.counts.cards.types)
    .reduce((acc, cur) => (acc as number) + (cur as {total: number}).total, 0) as number
}
</script>
