<template>
  <details-block data-cy="card.settings.categories"
                 :title="t('menu.categories')"
                 :loading="loading && !authorizationCategorySettingModel?.authorization_categories"
                 :columns="2"
                 split>
    <template v-for="index in [1, 2]"
              :key="index"
              #[`col-${index}`]>
      <template v-if="authorizationCategorySettingModel">
        <details-line v-for="(item, i) in authorizationCategorySettingModel.authorization_categories.filter((v, i) => getColNumber(i, 2) === index)"
                      :key="i"
                      height="5.6rem">
          <template #content>
            <div class="authorization-category">
              <core-toggle-switch v-model:model-value="item.enabled"
                                  :disabled="isDisabled" />
              <core-icon :name="`category-${item.name}` as ECoreIcon"
                         :theme="item.enabled ? ECoreIconTheme.GrayHigh : ECoreIconTheme.GrayLower" />
              <span :class="{ disabled: !item.enabled }">{{ item.label }}</span>
            </div>
          </template>
          <template v-if="item.enabled"
                    #param>
            <core-button :theme="ECoreButtonTheme.Transparent"
                         :size="ECoreButtonSize.Small"
                         :text="t('action.configure')"
                         :disabled="isDisabled"
                         @click="showCategoryLimits(item)" />
          </template>
        </details-line>
      </template>
    </template>
    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveAuthorizationCategorySetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreIcon,
  CoreToggleSwitch,
  ECoreButtonSize,
  ECoreButtonTheme,
  ECoreIcon,
  ECoreIconTheme
} from '@common/core-ui'
import _ from 'lodash'

import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { getColNumber } from '@/helpers/utils'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { IAuthorizationCategory, ICardAuthorizationCategorySetting, ICardDetails } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'
import { ECardSettingEvent } from '@/types/eventBus.d'
import { EPermission } from '@/types/permission.d'

import CategoryLimit from '@/pages/cards/card-id/tabs/settings/blocks/components/CategoryLimit.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const appStore = useAppStore()
const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
  cardDetails: ICardDetails
  currency: ICurrency
}>()

const loading = ref<boolean>(true)
const authorizationCategorySetting = ref<ICardAuthorizationCategorySetting | null>(null)
const authorizationCategorySettingModel = ref<ICardAuthorizationCategorySetting | null>(null)

onMounted(async () => {
  authorizationCategorySetting.value = await cardStore.getCardAuthorizationCategorySetting(props.cardUuid)
  authorizationCategorySettingModel.value = _.cloneDeep(authorizationCategorySetting.value)

  bus.on(ECardSettingEvent.UpdateAuthorizationCategory, onUpdateAuthorizationCategory)

  loading.value = false
})

onUnmounted(() => {
  bus.off(ECardSettingEvent.UpdateAuthorizationCategory, onUpdateAuthorizationCategory)
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingAuthorizationCategoryUpdate, props.cardDetails)
})

function showCategoryLimits (category: IAuthorizationCategory) {
  appStore.showSidePanel(CategoryLimit, { category, currency: props.currency })
}

function onUpdateAuthorizationCategory (category: IAuthorizationCategory) {
  if (!authorizationCategorySettingModel.value) return
  const index = authorizationCategorySettingModel.value.authorization_categories
    .findIndex(c => c.name === category.name)
  if (index !== -1) {
    authorizationCategorySettingModel.value.authorization_categories[index].limit = category.limit
  }
}

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(authorizationCategorySetting.value, authorizationCategorySettingModel.value) && !isDisabled.value
})

function cancel () {
  authorizationCategorySettingModel.value = _.cloneDeep(authorizationCategorySetting.value)
}

async function saveAuthorizationCategorySetting () {
  if (!authorizationCategorySettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardAuthorizationCategorySetting(props.cardUuid, authorizationCategorySettingModel.value)
  if (data) authorizationCategorySetting.value = data
  loading.value = false
}
</script>

<style scoped lang="stylus">
.authorization-category
  display flex

  .core-ic
    margin-left 1.6rem

  > span
    margin-left 1rem
    margin-right 1rem
    color $color-gray-900
    white-space nowrap
    &.disabled
      color $color-gray-500
</style>
