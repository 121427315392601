<template>
  <div class="gauge">
    <div class="gauge__bar"
         :class="color"
         :style="{ width: `${valueWidth}%` }" />
    <div v-if="alert"
         v-tooltip="{ content: t('general.alert'), theme: 'tooltip' }"
         class="gauge__cursor-wrapper"
         :style="{ left: `${alertOffset}%` }">
      <div class="gauge__cursor" />
    </div>
    <div v-if="excess"
         v-tooltip="{ content: t('general.ceiling'), theme: 'tooltip' }"
         class="gauge__cursor-wrapper"
         :style="{ left: `${limitOffset}%` }">
      <div class="gauge__cursor" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
    value: number,
    alert?: number,
    limit: number,
    excess?: number,
}>()

const color = computed(() => {
  if (valueWidth.value <= 50) {
    return 'green'
  } else if (valueWidth.value <= 80) {
    return 'yellow'
  } else {
    return 'red'
  }
})

const limitExceeded = computed(() => {
  return props.value > props.limit
})

const valueWidth = computed(() => {
  if (limitExceeded.value && !props.excess) {
    return 100
  } else if (limitExceeded.value && props.excess) {
    return (props.value / (props.limit + (props.limit * (props.excess / 100)))) * 100
  }
  return (props.value / props.limit) * 100
})

const limitOffset = computed(() => {
  if (!props.excess) {
    return 0
  }
  return (props.limit / (props.limit + (props.limit * (props.excess / 100)))) * 100
})

const alertOffset = computed(() => {
  if (!props.alert) {
    return 0
  } else if (props.excess) {
    return (limitOffset.value * (props.alert / 100))
  }
  return props.alert
})
</script>

<style scoped lang="stylus">
.gauge
  width 100%
  height .8rem
  position relative
  border-radius 36rem
  background-color $color-gray-150

  &__bar
    border-radius 36rem
    height 100%

    &.green
      background-color $color-green-300

    &.yellow
      background-color $color-yellow-300

    &.red
      background-color $color-red-300

  &__cursor-wrapper
    position absolute
    cursor pointer
    top -0.3rem
    left 0
    bottom -0.3rem
    width 1.4rem
    transform translateX(-50%)
    pointer-events auto

  &__cursor
    position absolute
    top 0
    left 50%
    bottom 0
    height 1.4rem
    width .2rem
    transform translateX(-50%)
    border-radius 9999999rem
    background-color $color-gray-800
    pointer-events none
</style>
