<template>
  <core-date-picker format="MM/yyyy"
                    :placeholder="t('placeholder.expiration_date')"
                    :locale="i18n.global.locale.value"
                    :model-value="computedExpirationDate"
                    :size="ECoreInputSize.Small"
                    clearable
                    :month-picker="true"
                    @update:model-value="updateExpirationDateFilter" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDatePicker, ECoreInputSize } from '@common/core-ui'
import dayjs from 'dayjs'

import i18n from '@/locales'

const { t } = useI18n()
const props = defineProps<{
  expirationDate?: string | null
}>()

const computedExpirationDate = computed(() => {
  return props.expirationDate ? new Date(props.expirationDate) : null
})

const emit = defineEmits<{
  'update:expiration-date': [value: string | null]
}>()

function updateExpirationDateFilter (date: Date | null) {
  emit('update:expiration-date', date ? dayjs(date).format('YYYY-MM') : null)
}
</script>
