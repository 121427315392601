import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

async function Guard (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const authStore = useAuthStore()
  const apiStore = useApiStore()
  const appStore = useAppStore()

  apiStore.abortAllRequests()

  if (appStore.modal.active) appStore.closeModal()
  if (appStore.sidebar.active) appStore.toggleSidebar(false)
  if (appStore.sidePanel.active) appStore.closeSidePanel()

  // check if token is expired or null
  if (authStore.isTokenExpired && to.meta.requiresAuth && to.name !== 'login') {
    next({ name: 'login' })
  }

  const nextAllowedLocation = authStore.getBoUserNextPathBasedOnPermissions(to)
  to.name !== nextAllowedLocation.name
    ? next(nextAllowedLocation)
    : next()
}

export default Guard
