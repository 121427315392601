<template>
  <core-dropdown :model-value="selectStatus"
                 min-width="100%"
                 clearable
                 :size="ECoreDropdownSize.Small"
                 :items="statusFilter"
                 :placeholder="t('placeholder.status')"
                 @update:model-value="updateFilterStatus" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownValue } from '@common/core-ui'

import { EBadgeResource, getBadge } from '@/helpers/badge'
import { useCardStore } from '@/stores/card'
import { ECardStatus } from '@/types/card.d'

const { t } = useI18n()
const cardsStore = useCardStore()
const statusLabel = ref<{ label: string, value: string }[]>([])

const props = defineProps<{
  status?: ECardStatus[]
}>()

onMounted(async () => {
  statusLabel.value = await cardsStore.getStatus()
})

const lockedValue = [
  ECardStatus.StatusLocked,
  ECardStatus.StatusLockedProof,
  ECardStatus.StatusLockedPartner,
  ECardStatus.StatusLockedService
]

const emit = defineEmits<{
  'update:status': [value: TDropdownValue[]]
}>()

const selectStatus = computed(() => {
  if (!props.status || props.status.length === 0) {
    return undefined
  }

  if (props.status?.every(status => lockedValue.includes(status))) {
    return ECardStatus.StatusLocked
  }
  return props.status
})

const statusFilter = computed(() => {
  return statusLabel.value.filter(status => ![
    ECardStatus.StatusLockedProof,
    ECardStatus.StatusLockedPartner,
    ECardStatus.StatusLockedService
  ].includes(status.value as ECardStatus)).map((status, index) => {
    return {
      id: index,
      label: getBadge(status.value, EBadgeResource.Cards)?.label,
      value: status.value
    }
  })
})

function updateFilterStatus (item: TDropdownValue) {
  if (!item) {
    return emit('update:status', [])
  }

  if (item === ECardStatus.StatusLocked) {
    return emit('update:status', lockedValue)
  }

  return emit('update:status', [item])
}
</script>
