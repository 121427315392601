<template>
  <details-block data-cy="card.settings.smart-card"
                 :title="t('menu.smart_card')"
                 :loading="loading && !smartCardSettingModel">
    <template v-if="smartCardSettingModel"
              #body>
      <details-line class="smart-card-enable">
        <template #content>
          <core-toggle-switch v-model:model-value="smartCardSettingModel.smart_card"
                              :label="t('card.setting.smart_card.enable_feature')"
                              :disabled="isDisabled" />
        </template>
        <template #param>
          <core-dropdown v-model:model-value="smartCardSettingModel.smart_card_option"
                         min-width="100%"
                         :items="optionList"
                         :placeholder="t('placeholder.select')"
                         :disabled="!smartCardSettingModel.smart_card || isDisabled" />
        </template>
      </details-line>

      <template v-if="smartCardSettingModel.smart_card">
        <details-line v-if="isFileOptionSelected">
          <template #content>
            {{ t('card.setting.smart_card.file') }}
          </template>
          <template #param>
            <core-dropdown min-width="100%"
                           :items="[
                             { label: t('action.view_file'), onClick: viewFile },
                             { label: t('action.delete_file'), onClick: deleteFile }
                           ]"
                           toggle-on-hover
                           :disabled="!smartCardSettingModel.smart_card_file || isDisabled">
              <template #trigger>
                <core-button block
                             :text="fileLabel"
                             :theme="ECoreButtonTheme.GrayMid"
                             :appearance="ECoreButtonAppearance.Outlined"
                             :disabled="isDisabled"

                             @click="addFile" />
              </template>
            </core-dropdown>
            <input ref="smartCardFileRef"
                   class="file-input"
                   type="file"
                   @change="onFileChange">
          </template>
        </details-line>

        <details-line v-else-if="isLinkOptionSelected">
          <template #content>
            {{ t('card.setting.smart_card.link') }}
          </template>
          <template #param>
            <core-input v-model:model-value="smartCardSettingModel.smart_card_link"
                        placeholder=""
                        :disabled="isDisabled" />
          </template>
        </details-line>

        <div v-else-if="isVcardOptionSelected"
             class="vcard">
          <h4 class="vcard__title">
            {{ t('card.setting.smart_card.vcard') }}
          </h4>

          <div v-if="smartCardSettingModel.smart_card_vcard"
               class="vcard-picture">
            <component-picture class="vcard-picture__img"
                               :picture="smartCardSettingModel.smart_card_vcard?.picture || null"
                               :placeholder-label="`${smartCardSettingModel.smart_card_vcard?.first_name} ${smartCardSettingModel.smart_card_vcard?.last_name}`"
                               rounded />

            <core-dropdown class="vcard-picture__action"
                           min-width="100%"
                           :items="[
                             { label: t('action.delete_picture'), onClick: deleteVCardPicture }
                           ]"
                           toggle-on-hover
                           :disabled="!smartCardSettingModel.smart_card_vcard?.picture || isDisabled">
              <template #trigger>
                <core-button block
                             :text="vCardPictureLabel"
                             :theme="ECoreButtonTheme.GrayMid"
                             :appearance="ECoreButtonAppearance.Outlined"
                             :disabled="isDisabled"

                             @click="addVCardPicture" />
              </template>
            </core-dropdown>
            <input ref="vCardPictureRef"
                   class="file-input"
                   type="file"
                   accept="image/png, image/jpeg"
                   @change="onVCardPictureChange">
          </div>

          <div>
            <details-label class="vcard__subtitle"
                           :title="t('general.general_information')" />
            <div class="split">
              <div>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.first_name') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.first_name"
                                :placeholder="t('placeholder.first_name')"
                                name="first_name"
                                :disabled="isDisabled" />
                  </template>
                </details-line>

                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.company_name') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.company_name"
                                :placeholder="t('placeholder.company_name')"
                                name="company_name"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
              </div>
              <div>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.last_name') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.last_name"
                                :placeholder="t('placeholder.last_name')"
                                name="last_name"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.function') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.role"
                                :placeholder="t('placeholder.function')"
                                name="role"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
              </div>
            </div>
          </div>

          <div>
            <details-label class="vcard__subtitle"
                           :title="t('general.contact')" />
            <div class="split">
              <div>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.phone') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.phone"
                                placeholder=""
                                name="phone"
                                :disabled="isDisabled" />
                  </template>
                </details-line>

                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.email') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.email"
                                :placeholder="t('placeholder.email')"
                                name="email"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
              </div>
              <div>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.secondary_phone') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.phone2"
                                placeholder=""
                                name="phone2"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
              </div>
            </div>
          </div>

          <div>
            <details-label class="vcard__subtitle"
                           :title="t('card.setting.smart_card.social_network_and_website')" />
            <div class="split">
              <div>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.linkedin') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.social_media.linkedin"
                                placeholder=""
                                name="linkedin"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.website') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.website"
                                placeholder=""
                                name="website"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
              </div>
              <div>
                <details-line height="5.6rem">
                  <template #content>
                    {{ t('general.twitter') }}
                  </template>
                  <template #param>
                    <core-input v-model:model-value="smartCardSettingModel.smart_card_vcard.social_media.twitter"
                                placeholder=""
                                name="twitter"
                                :disabled="isDisabled" />
                  </template>
                </details-line>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveSmartCardSetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreDropdown,
  CoreInput,
  CoreToggleSwitch,
  ECoreButtonAppearance,
  ECoreButtonTheme
} from '@common/core-ui'
import _ from 'lodash'

import hasPermission from '@/helpers/permissions'
import { deleteFileFromUrl } from '@/helpers/utils'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ESmartCardOption, ICardDetails, ICardSmartCardSetting } from '@/types/card.d'
import { EPermission } from '@/types/permission.d'

import ComponentPicture from '@/components/ComponentPicture.vue'
import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLabel from '@/components/details-block/DetailsLabel.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const cardStore = useCardStore()
const appStore = useAppStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
  cardDetails: ICardDetails
}>()

const loading = ref<boolean>(true)
const smartCardSetting = ref<ICardSmartCardSetting | null>(null)
const smartCardSettingModel = ref<ICardSmartCardSetting | null>(null)
const smartCardFileRef = ref<HTMLInputElement|null>(null)
const vCardPictureRef = ref<HTMLInputElement|null>(null)
const uploadedSmartCardFile = ref<File|null>(null)
const uploadedVCardPicture = ref<File|null>(null)

const optionList = Object.values(ESmartCardOption)
  .map(item => ({
    label: t(`card.setting.smart_card.option.${item}`),
    value: item
  }))

onMounted(async () => {
  smartCardSetting.value = await cardStore.getCardSmartCardSetting(props.cardUuid)
  smartCardSettingModel.value = _.cloneDeep(smartCardSetting.value)
  loading.value = false
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingSmartCardUpdate, props.cardDetails)
})

const isFileOptionSelected = computed<boolean>(() => {
  return smartCardSettingModel.value?.smart_card_option === ESmartCardOption.File
})
const isLinkOptionSelected = computed<boolean>(() => {
  return smartCardSettingModel.value?.smart_card_option === ESmartCardOption.Link
})
const isVcardOptionSelected = computed<boolean>(() => {
  return smartCardSettingModel.value?.smart_card_option === ESmartCardOption.Vcard
})

const isSaveButtonVisible = computed(() => {
  return (!_.isEqual(smartCardSetting.value, smartCardSettingModel.value) ||
      uploadedSmartCardFile.value ||
      uploadedVCardPicture.value) && !isDisabled.value
})

// FILE OPTION
const fileLabel = computed(() => {
  if (uploadedSmartCardFile.value) return uploadedSmartCardFile.value.name
  if (smartCardSettingModel.value?.smart_card_file) return smartCardSettingModel.value.smart_card_file.filename
  return t('action.add_file')
})

function cancel () {
  smartCardSettingModel.value = _.cloneDeep(smartCardSetting.value)
  uploadedSmartCardFile.value = null
  uploadedVCardPicture.value = null
}

function addFile () {
  smartCardFileRef.value?.click()
}

function onFileChange (event: Event) {
  const target = event.target as HTMLInputElement
  uploadedSmartCardFile.value = target.files?.[0] || null
  target.value = ''
}

async function viewFile () {
  if (smartCardSettingModel.value?.smart_card_file && smartCardSetting.value?.smart_card_file) {
    appStore.showFileViewer([smartCardSettingModel.value.smart_card_file])
  }
}

async function deleteFile () {
  uploadedSmartCardFile.value = null
  if (smartCardSettingModel.value?.smart_card_file && smartCardSetting.value?.smart_card_file) {
    const { success } = await deleteFileFromUrl(smartCardSettingModel.value.smart_card_file.url)
    if (success) {
      smartCardSettingModel.value.smart_card_file = null
      smartCardSetting.value.smart_card_file = null
    }
  }
}

// VCARD OPTION
const vCardPictureLabel = computed(() => {
  if (uploadedVCardPicture.value) return uploadedVCardPicture.value.name
  if (smartCardSettingModel.value?.smart_card_vcard?.picture) return smartCardSettingModel.value.smart_card_vcard.picture.filename
  return t('action.add_picture')
})

function addVCardPicture () {
  vCardPictureRef.value?.click()
}

function onVCardPictureChange (event: Event) {
  const target = event.target as HTMLInputElement
  uploadedVCardPicture.value = target.files?.[0] || null
  target.value = ''
}

async function deleteVCardPicture () {
  uploadedVCardPicture.value = null
  if (smartCardSettingModel.value?.smart_card_vcard?.picture && smartCardSetting.value?.smart_card_vcard.picture) {
    const { success } = await deleteFileFromUrl(smartCardSettingModel.value.smart_card_vcard.picture.url)
    if (success) {
      smartCardSettingModel.value.smart_card_vcard.picture = null
      smartCardSetting.value.smart_card_vcard.picture = null
    }
  }
}

async function saveSmartCardSetting () {
  if (!smartCardSettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardSmartCardSetting(props.cardUuid, smartCardSettingModel.value, { file: uploadedSmartCardFile.value, picture: uploadedVCardPicture.value })
  if (data) {
    smartCardSetting.value = data
    if (data?.smart_card_file?.filename) {
      smartCardSettingModel.value.smart_card_file = _.cloneDeep(smartCardSetting.value.smart_card_file)
      uploadedSmartCardFile.value = null
    }
    if (data?.smart_card_vcard?.picture?.filename) {
      smartCardSettingModel.value.smart_card_vcard = _.cloneDeep(data?.smart_card_vcard)
      uploadedVCardPicture.value = null
    }
  }
  loading.value = false
}

</script>

<style scoped lang="stylus">
.vcard
  &-picture
    display flex
    align-items center
    justify-content flex-start
    &__action
      margin-left 2rem
      max-width 30rem
  &__title
    font-size 1.8rem
    font-weight 500
    margin-bottom 2rem

  &__subtitle
    display block
    margin-bottom 1.2rem

  > div:not(:last-child)
    margin-bottom 3.2rem

  .split
    vertical-split(2.4rem, true)

.file-input
  display none
</style>
