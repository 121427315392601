<template>
  <div class="filters">
    <div class="filters__list">
      <slot />
    </div>
    <div v-if="showResult"
         class="filters__result">
      <span>{{ t('general.results', resultCount) }}</span>
      <span @click="emit('clear')">{{ t('action.clear_filters') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps<{
  showResult: boolean,
  resultCount: number
}>()

const emit = defineEmits<{
  'clear': []
}>()
</script>

<style lang="stylus">
.filters
  display flex
  flex-direction column
  gap 2rem

  &__list
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 1rem

  &__result
    display flex
    justify-content space-between

    & > span:first-child
      font-size 1.4rem
      font-weight 500
      color $color-gray-800

    & > span:last-child
      font-size 1.4rem
      font-weight 400
      color $color-gray-600
      text-decoration underline

      &:hover
        cursor pointer
</style>
