<template>
  <header class="header">
    <button class="menu-button"
            @click="appStore.toggleSidebar(true)">
      <core-icon :name="ECoreIconBoList.BoMenu"
                 :size="20" />
    </button>
    <div class="header__content">
      <core-input v-model="search"
                  data-cy="accounts.search-bar"
                  centered
                  :size="ECoreInputSize.Large"
                  :placeholder="t('header.search.placeholder')"
                  @keydown.enter="onSearch">
        <template #button>
          <CoreButton :prepend-icon="ECoreIconBoList.BoSearch"
                      :size="ECoreButtonSize.Large"
                      @click="onSearch" />
        </template>
      </core-input>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { CoreButton, CoreIcon, CoreInput, ECoreButtonSize, ECoreIconBoList, ECoreInputSize } from '@common/core-ui'

import { useAppStore } from '@/stores/app'

const { t } = useI18n()
const search = ref(useRoute().query.search || '')
const router = useRouter()
const route = useRoute()
const appStore = useAppStore()

function onSearch () {
  router.push({ name: 'accounts', query: { search: search.value } })
}

watch(() => route.query.search, newSearch => {
  search.value = newSearch || ''
})

</script>

<style scoped lang="stylus">
.header
  height 10rem
  width 100%
  background-color $white
  border-bottom 1px solid $color-gray-150
  display flex
  align-items center
  padding 2.5rem 4rem

  &__content
    width 100%
</style>
