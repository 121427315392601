<template>
  <div class="oauth-method">
    <core-button v-for="(item, index) in clients"
                 :key="index"
                 :data-cy="`login.oauth.${item}`"
                 block
                 :disabled="loading"
                 :loading="loading"
                 :text="t(`login.oauth.${item}`)"
                 @click="auth(item)" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { CoreButton } from '@common/core-ui'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { isOfTypeOAuthClient, isOfTypeOauthParams, OAuthClient, OAuthParams } from '@/types/auth.d'

const { t } = useI18n()
const route = useRoute()

const authStore = useAuthStore()
const appStore = useAppStore()

const clients = ref<OAuthClient[]>([])
const loading = ref(false)

onMounted(async () => {
  authStore.clearInactivityTimeout()
  useAuthStore().resetStores()

  const client = route.params.client

  if (isOfTypeOAuthClient(client)) {
    const { code, state, session_state } = route.query
    const params = { code, state, session_state }

    if (isOfTypeOauthParams(params)) {
      return await auth(client as OAuthClient, params)
    }

    appStore.showBannerError(t('error.login'))
  }

  return await getOauthClients()
})

async function getOauthClients () {
  clients.value = await authStore.getOauthClients()
}

async function auth (client: OAuthClient, params?: OAuthParams) {
  loading.value = true
  const response = await authStore.authWithOAuth(client, params)

  if (!response) {
    await getOauthClients()
  }

  loading.value = false
}
</script>

<style lang="stylus" scoped>
.oauth-method
  display flex
  flex-direction column
  width 100%
  gap 1rem
</style>
