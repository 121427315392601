import { ECoreBadgeTheme } from '@common/core-ui'

import i18n from '@/locales'

const { t } = i18n.global

export enum EBadgeResource {
  Accounts = 'accounts',
  Cards = 'cards',
  Users = 'users',
  Invitations = 'invitations',
  Operations = 'operations',
  BankValidationRequests = 'bank-validation-requests'
}

export function getBadge (status: string, resource?: EBadgeResource): { label: string; theme: ECoreBadgeTheme } | null {
  switch (resource) {
    case EBadgeResource.Operations:
      switch (status) {
        case 'cancelled' : return { label: t('status.canceled_f'), theme: ECoreBadgeTheme.Danger }
        case 'deleted' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'done' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Success }
        case 'pending' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'processing' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Info }
        case 'rejected' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
      } break
    case EBadgeResource.Users:
      switch (status) {
        case 'accepted' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Success }
        case 'active' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Success }
        case 'awaiting_proof' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'cancelled' : return { label: t('status.canceled_m'), theme: ECoreBadgeTheme.Danger }
        case 'deleted' : return { label: t('status.revoked_m'), theme: ECoreBadgeTheme.Danger }
        case 'done' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Success }
        case 'in_progress' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'pending' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'processing' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Info }
        case 'rejected' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Danger }
        case 'to_approve' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'to_validate' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
      } break
    case EBadgeResource.Invitations:
      switch (status) {
        case 'deleted': return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'expired': return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'pending': return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'refused': return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'to_validate': return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'validated': return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Success }
        case 'to_validate_by_bank' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Info }
      } break
    case EBadgeResource.Accounts:
      switch (status) {
        case 'blocked' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Danger }
        case 'closed' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Danger }
        case 'closing' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'deleted' : return { label: t(`status.${status}_m`), theme: ECoreBadgeTheme.Danger }
        case 'valid' : return { label: t('status.active_m'), theme: ECoreBadgeTheme.Success }
        case 'validating' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Info }
      } break
    case EBadgeResource.Cards:
      switch (status) {
        case 'active' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Success }
        case 'blocked' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'cancelled' : return { label: t('status.canceled_f'), theme: ECoreBadgeTheme.Danger }
        case 'deferred_order' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'expired' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'locked' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Warning }
        case 'locked_partner' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'locked_proof' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Danger }
        case 'locked_service' : return { label: t(`status.${status}_f`), theme: ECoreBadgeTheme.Warning }
        case 'order_in_progress' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Info }
        case 'pending' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'to_activate' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'to_generate' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'to_send' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'to_validate' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Warning }
        case 'to_validate_by_bank' : return { label: t(`status.${status}`), theme: ECoreBadgeTheme.Info }
      } break
    case EBadgeResource.BankValidationRequests:
      switch (status) {
        case 'to_validate' : return { label: t('status.to_validate'), theme: ECoreBadgeTheme.Info }
        case 'validated' : return { label: t('status.validated_f'), theme: ECoreBadgeTheme.Success }
        case 'rejected' : return { label: t('status.rejected_f'), theme: ECoreBadgeTheme.Danger }
        case 'canceled' : return { label: t('status.canceled_f'), theme: ECoreBadgeTheme.GrayLow }
      } break
  }
  return null
}
