<template>
  <details-block v-if="cardLimitSetting?.initial_amount"
                 data-cy="card-detail.information.payment-block"
                 :columns="1"
                 :title="t('general.payment', 2)">
    <template #col-1>
      <details-line :title="t(`card.setting.initial_amount`)">
        <template #data>
          {{ formatAmount(cardLimitSetting.initial_amount, currency) }}
        </template>
      </details-line>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { formatAmount } from '@/helpers/utils/number'
import { ICardLimitSetting } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()

defineProps<{
  cardLimitSetting: ICardLimitSetting
}>()

const currency = computed<ICurrency>(() => {
  return { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
})
</script>
