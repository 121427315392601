<template>
  <div class="card-settings">
    <card-setting-nav :nav-items="sections" />
    <router-view :card-uuid="cardUuid"
                 :card-details="cardDetails"
                 :currency="currency" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import hasPermission from '@/helpers/permissions'
import { ICardDetails } from '@/types/card.d'

import CardSettingNav from '@/pages/cards/card-id/tabs/settings/CardSettingNav.vue'

const route = useRoute()

const props = defineProps<{
  cardUuid: string,
  cardDetails: ICardDetails
}>()

const currency = {
  iso: 'EUR',
  label: 'Euro',
  fraction_digits: 2,
  flag_url: 'none'
}

const sections = computed(() => {
  return (route.matched.find(r => r.meta.navs)?.children || [])
    .filter(r => r?.meta?.permissions && hasPermission(r?.meta?.permissions, props.cardDetails))
})

onMounted(() => {
  const router = useRouter()
  if (route.name === 'card-setting' && sections.value?.[0]) {
    router.replace({ name: sections.value[0].name })
  }
})
</script>

<style scoped lang="stylus">
.card-settings
  display flex
  align-items flex-start
</style>
