import { RouteLocationNormalized } from 'vue-router'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { decodeJwt, JWTPayload } from 'jose'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import hasPermission from '@/helpers/permissions'
import { showToastSuccess } from '@/helpers/utils/notification'
import i18n from '@/locales'
import { useApiStore } from '@/stores/api'
import { IBoUser, OAuthClient, OAuthParams } from '@/types/auth.d'
import { Nullable } from '@/types/default.d'
import { ERequestHeader, EResponseHeader } from '@/types/headers.d'

import { useAppStore } from './app'

type State = {
  boUser: IBoUser,
  inactivityTimeout: Nullable<ReturnType<typeof setTimeout>>,
}

export const useAuthStore = defineStore('auth', {
  state: (): State => {
    return {
      boUser: {
        brand: '',
        loggedIn: false,
        token: null,
        decodedToken: null,
        first_name: '',
        last_name: '',
        email: '',
        permissions: [],
        uuid: ''
      },

      inactivityTimeout: null
    }
  },

  persist: true,

  getters: {
    boUserFullName: state => `${state.boUser.first_name} ${state.boUser.last_name}`.trim(),
    isTokenExpired: state => (state.boUser.decodedToken?.exp || 0) <= (Math.floor(new Date().getTime() / 1000) + useApiStore().deltaTime)
  },

  actions: {
    async authWithCredentials (username: string, password: string) {
      try {
        const { data } = await axiosClient.get('/bo-users/auth', { auth: { password, username } })
        await this.onBoAuthUserSuccess(data)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async authWithOAuth (client: OAuthClient, params?: OAuthParams) {
      try {
        const { data } = await axiosClient.get(`/oauth/login/${client}`, {
          headers: { [ERequestHeader.RequestedWith]: 'XMLHttpRequest' },
          params,
          withCredentials: true
        })
        await this.onBoAuthUserSuccess(data)
        return true
      } catch (e) {
        const error = e as AxiosError
        if (error.response?.status === 302 && error.response?.headers[EResponseHeader.Redirect]) {
          window.location.href = error.response?.headers[EResponseHeader.Redirect]
        }

        console.error(error)
        return false
      }
    },

    clearInactivityTimeout () {
      if (this.inactivityTimeout) {
        clearTimeout(this.inactivityTimeout)
      }

      this.inactivityTimeout = null
    },

    async getOauthClients (): Promise<OAuthClient[]> {
      try {
        const { data } = await axiosClient.get('/oauth/clients')
        return data.clients
      } catch (e) {
        console.error(e)
        return []
      }
    },

    async logout () {
      try {
        await axiosClient.delete('/bo-users/auth')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async onBoAuthUserSuccess (data: IBoUser) {
      this.resetStores()
      this.patchBoUser(data)
      await this.router.push({ name: 'dashboard' })
    },

    patchBoUser (data: IBoUser) {
      this.$patch(state => {
        state.boUser.loggedIn = true
        state.boUser.token = data.token
        if (data.token) {
          state.boUser.decodedToken = decodeJwt(data.token)
        }
        state.boUser.brand = data.brand
        state.boUser.first_name = data.first_name
        state.boUser.last_name = data.last_name
        state.boUser.email = data.email
        state.boUser.permissions = data.permissions
        state.boUser.uuid = data.uuid
      })

      if (this.boUser.decodedToken) {
        this.setInactivityTimeout(this.boUser.decodedToken)
      }
    },

    replaceToken (newToken: string) {
      if (!this.boUser.token) {
        return
      }

      const decodedNewToken = decodeJwt(newToken)
      if (decodedNewToken.exp && decodedNewToken.exp > (this.boUser.decodedToken?.exp || 0)) {
        this.setBoUserToken(newToken)
      }
    },

    resetStores (clearAuth = true) {
      if (clearAuth) {
        this.$reset()
      }
    },

    setInactivityTimeout (decodedNewToken: JWTPayload) {
      if (this.inactivityTimeout) {
        clearTimeout(this.inactivityTimeout)
      }

      const time = ((decodedNewToken.exp || 0) - dayjs().unix() - 30 - useApiStore().deltaTime) * 1000
      this.inactivityTimeout = setTimeout(() => {
        if (this.router.requireAuth()) {
          console.warn('implement inactivity modal')
        }
      }, time)
    },

    setBoUserToken (token: string) {
      this.boUser.token = token
      this.boUser.decodedToken = token ? decodeJwt(token) : null
    },

    getBoUserNextPathBasedOnPermissions (to: RouteLocationNormalized): RouteLocationNormalized {
      const requiredPermissions = to.meta.permissions
      if (!requiredPermissions || requiredPermissions.length === 0) {
        return to
      }

      if (hasPermission(requiredPermissions, this.boUser, true)) {
        return to
      }

      const routes = this.router.getRoutes().filter(route => route.name && this.router.options.routes.some(r => r.path === route.path))
      for (const route of routes) {
        if (hasPermission(route.meta.permissions, this.boUser, true)) {
          return route
        }
      }

      useAppStore().showBannerError(i18n.global.t('error.no_accessible_routes'))
      throw new Error('No accessible routes found for this bo-user.')
    },

    async passwordRecovery (email: string) {
      try {
        const { data } = await axiosClient.post('/bo-users/password-recovery', { email })
        showToastSuccess(data.message)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async passwordUpdate (token: string, password: string, password_repeat: string) {
      try {
        const { data } = await axiosClient.put('/bo-users/password-recovery/password',
          { password, password_repeat },
          { headers: { Authorization: `Bearer ${token}` } })
        showToastSuccess(data.message)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
})
