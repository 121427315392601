<template>
  <details-block data-cy="card.settings.days"
                 :title="t('menu.days_and_time_ranges')"
                 :loading="loading && !authorizationRangeSettingModel"
                 :columns="1">
    <template v-if="authorizationRangeSettingModel"
              #col-1>
      <div class="authorization-days">
        <div v-for="(item, key) in authorizationRangeSettingModel.authorization_range"
             :key="key"
             class="authorization-days__item">
          <template v-if="authorizationRangeSettingModel?.authorization_range[key]">
            <span class="label">{{ t(`general.day.full.${key}`) }}</span>
            <core-toggle-switch v-model:model-value="authorizationRangeSettingModel.authorization_range[key].enabled"
                                class="toggle"
                                label=""
                                :disabled="isDisabled" />
            <component-time-range-selector v-model:start="authorizationRangeSettingModel.authorization_range[key].start"
                                           v-model:end="authorizationRangeSettingModel.authorization_range[key].end"
                                           :disabled="!authorizationRangeSettingModel.authorization_range[key].enabled || isDisabled" />
          </template>
        </div>
      </div>

      <div class="holidays">
        <details-line>
          <template #content>
            <core-toggle-switch v-model:model-value="authorizationRangeSettingModel.non_working_day"
                                :label="t('card.setting.authorize_card_during_non_working_day')"
                                :disabled="isDisabled" />
          </template>
          <template #param>
            <core-dropdown v-model="authorizationRangeSettingModel.country_holidays"
                           :items="countryList"
                           :disabled="isDisabled" />
          </template>
          <div />
        </details-line>
      </div>
    </template>
    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveAuthorizationRangeSetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, CoreDropdown, CoreToggleSwitch, ECoreButtonTheme } from '@common/core-ui'
import _ from 'lodash'

import hasPermission from '@/helpers/permissions'
import { getCountryName } from '@/helpers/utils/intl'
import { useCardStore } from '@/stores/card'
import { ICardAuthorizationRangeSetting, ICardDetails } from '@/types/card.d'
import { EPermission } from '@/types/permission.d'

import ComponentTimeRangeSelector from '@/components/ComponentTimeRangeSelector.vue'
import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
  cardDetails: ICardDetails
}>()

const countryList = ['FR', 'DE', 'ES', 'GB', 'IT', 'PT']
  .map(el => ({ label: getCountryName(el), value: el }))

const loading = ref<boolean>(true)
const authorizationRangeSetting = ref<ICardAuthorizationRangeSetting|null>(null)
const authorizationRangeSettingModel = ref<ICardAuthorizationRangeSetting|null>(null)

onMounted(async () => {
  authorizationRangeSetting.value = await cardStore.getCardAuthorizationRangeSetting(props.cardUuid)
  authorizationRangeSettingModel.value = _.cloneDeep(authorizationRangeSetting.value)
  loading.value = false
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingAuthorizationRangeUpdate, props.cardDetails)
})

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(authorizationRangeSetting.value, authorizationRangeSettingModel.value) && !isDisabled.value
})

function cancel () {
  authorizationRangeSettingModel.value = _.cloneDeep(authorizationRangeSetting.value)
}

async function saveAuthorizationRangeSetting () {
  if (!authorizationRangeSettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardAuthorizationRangeSetting(props.cardUuid, authorizationRangeSettingModel.value)
  if (data) authorizationRangeSetting.value = data
  loading.value = false
}
</script>

<style scoped lang="stylus">
.authorization-days
  display flex
  justify-content space-between
  margin-left -1rem
  margin-right -1rem

  &__item
    padding-right 1rem
    padding-left 1rem
    flex 1
    display flex
    flex-direction column
    align-items center

    .label
      margin-bottom 0.8rem
    .toggle
      margin-bottom 1.6rem

.holidays
  margin-top 3rem
</style>
