<template>
  <div class="sidepanel-line"
       :class="{ 'details-category': !data && !dataList }">
    <span class="sidepanel-line__label">{{ label }}</span>
    <div v-if="data"
         class="sidepanel-line__data"
         :class="clickable ? 'core-link core-link--theme-primary' : ''"
         @click="onClick">
      <span :class="{ strikethrough }">{{ data }}</span>
      <core-icon v-if="clickable"
                 class="sidepanel-line__chevron"
                 :name="ECoreIconBoList.BoChevronRight"
                 :size="18"
                 :theme="ECoreIconTheme.Primary" />
    </div>
    <div v-if="dataList"
         class="sidepanel-line__data-list">
      <div v-for="(item, i) in dataList"
           :key="i">
        <span class="label">{{ item.label }}</span>
        <span class="value"
              :class="{ strikethrough }">{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CoreIcon, ECoreIconBoList, ECoreIconTheme } from '@common/core-ui'
const props = defineProps<{
  label: string
  data?: string
  clickable?: boolean
  dataList?: {label: string, value: string}[]
  strikethrough?: boolean
}>()

const emit = defineEmits(['click'])

function onClick () {
  if (props.clickable) {
    emit('click')
  }
}
</script>

<style scoped lang="stylus">
.sidepanel-line
  width 100%
  padding 1rem 0
  display flex

  &.details-category
    height 3.3rem

    span
      font-size 1.3rem
      line-height 1.67rem
      font-weight 500
      text-transform uppercase
      color $color-gray-600

  &__label
    font-size 1.55rem
    line-height 2.2rem
    font-weight 400
    color $color-gray-800
    margin-right auto
    padding-right 1.6rem

  &__data
    display flex
    align-items center
    text-align right
    max-width 30rem

    span
      font-size 1.55rem
      line-height 2.2rem
      font-weight 450
      color $color-gray-800

    &.core-link--theme-primary
      span
        color inherit

    svg
      margin-left .6rem

  &__data-list
    width 65%
    flex-shrink 0

    > div
      display flex
      justify-content space-between

      .label
        color $color-gray-600

      .value
        font-weight 500
        color $color-gray-800

.strikethrough
  text-decoration line-through
  color $color-gray-400 !important

</style>
