<template>
  <details-block data-cy="account-detail.information.limits-block"
                 :title="t('general.expenses_and_limits')"
                 :columns="1">
    <template #col-1>
      <details-line :title="t('general.outstanding_current_month')"
                    :data="[formatAmount(settings.consumed, currency)]"
                    :total="[settings.limit ? formatAmount(settings.limit, currency) : undefined]"
                    :exceeded="balanceExceeded" />
      <details-line :title="t('general.outstanding_balance')"
                    :data="[remaining]" />
      <details-line :title="t('general.alert_threshold')"
                    :data="[alertThreshold]" />
      <details-line>
        <template #content>
          <component-gauge v-if="accountDetails.settings && settings.limit"
                           class="block-details__body__row__gauge"
                           :value="settings.consumed"
                           :limit="settings.limit"
                           :excess="settings.excess"
                           :alert="settings.alert" />
        </template>
      </details-line>
      <template v-if="currentMonthExcess && isMonthExcessVisible">
        <details-line :title="t('general.exceed')"
                      :data="[formatAmount(currentMonthExcess.current, currency)]"
                      :total="currentMonthExcess.limit ? [formatAmount(currentMonthExcess.limit, currency)] : undefined" />
      </template>
      <template v-else>
        <details-line />
      </template>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import hasPermission from '@/helpers/permissions'
import { formatAmount } from '@/helpers/utils/number'
import { IAccountDetails } from '@/types/account.d'
import { ICurrency } from '@/types/currency.d'
import { EPermission } from '@/types/permission.d'

import ComponentGauge from '@/components/component-gauge/ComponentGauge.vue'
import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()

const props = defineProps<{
  accountDetails: IAccountDetails
}>()

const settings = computed(() => {
  return {
    limit: props.accountDetails.settings?.transaction_amount_monthly_limit ?? 0,
    consumed: props.accountDetails.settings?.transaction_amount_monthly_consumed ?? 0,
    alert: props.accountDetails.settings?.transaction_amount_monthly_alert_threshold_percentage,
    excess: props.accountDetails.settings?.transaction_amount_monthly_excess_allowed_percentage ?? 0
  }
})

const balanceExceeded = computed(() => {
  if (!settings.value.limit) {
    return false
  }
  return settings.value.consumed > settings.value.limit
})

const currency = computed<ICurrency>(() => {
  return { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
})

const remaining = computed(() => {
  if (!settings.value.limit) {
    return '-'
  }
  const remaining = settings.value.limit - settings.value.consumed
  if (remaining < 0) {
    return formatAmount(0, currency.value)
  }
  return formatAmount(remaining, currency.value)
})

const alertThreshold = computed(() => {
  if (!settings.value.alert || !settings.value.limit) {
    return '-'
  }
  const percentage = settings.value.alert
  const value = settings.value.limit * (percentage / 100)
  return `${percentage} % (${formatAmount(value, currency.value)})`
})

const currentMonthExcess = computed(() => {
  if (!settings.value.excess && settings.value.consumed <= settings.value.limit) {
    return null
  }

  const current = settings.value.consumed - settings.value.limit
  const limit = settings.value.limit * (settings.value.excess / 100)

  return {
    current: current > 0 ? current : 0,
    limit
  }
})

const isMonthExcessVisible = computed(() => {
  return hasPermission(EPermission.AccountTransactionAmountMonthlyExcessAllowedRead)
})
</script>
