<template>
  <sidepanel-layout class="request-details">
    <template #body>
      <sidepanel-header :icon="ECoreIconBoList.BoTruck"
                        :label="title" />
      <sidepanel-block :title="t('general.address_information')">
        <template #content>
          <sidepanel-line :label="t('general.address_line', { n: 1 })"
                          :data="cardDetails.address.street" />
          <sidepanel-line :label="t('general.address_line', { n: 2 })"
                          :data="cardDetails.address.details || '-'" />
          <sidepanel-line :label="t('general.zip')"
                          :data="cardDetails.address.zip" />
          <sidepanel-line :label="t('general.city')"
                          :data="cardDetails.address.city" />
          <sidepanel-line :label="t('general.country')"
                          :data="getCountryName(cardDetails.address.country)" />
        </template>
      </sidepanel-block>
    </template>
  </sidepanel-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ECoreIconBoList } from '@common/core-ui'

import { getCountryName } from '@/helpers/utils/intl'
import { ICardDetails } from '@/types/card.d'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelHeader from '@/components/sidepanel/SidepanelHeader2.vue'
import SidepanelLayout from '@/components/sidepanel/SidepanelLayout.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const { t } = useI18n()
const props = defineProps<{
  cardDetails: ICardDetails
}>()

const title = `${t('general.card_delivery')} **${props.cardDetails.last_digits}`
</script>
