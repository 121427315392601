<template>
  <details-block v-if="cardLimitSetting?.payment"
                 data-cy="card-detail.information.payment-block"
                 :columns="1"
                 :title="t('general.payment', 2)">
    <template #col-1>
      <details-line v-if="cardLimitSetting.initial_amount"
                    :title="t(`card.setting.initial_amount`)"
                    :data="[formatAmount(cardLimitSetting.initial_amount, currency)]" />

      <template v-for="payment in payments"
                :key="payment.period">
        <details-line v-if="payment"
                      :title="t(`general.${payment.period}`)"
                      :data="[formatAmount(payment.consumed, currency)]"
                      :total="payment.enabled ? [formatAmount(payment.limit, currency)] : undefined"
                      :exceeded="payment.consumed > payment.limit">
          <template #content>
            <span v-if="payment.enabled"
                  class="balance"> / {{ formatAmount(payment.limit, currency) }}</span>
          </template>
        </details-line>
      </template>
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { formatAmount } from '@/helpers/utils/number'
import { ECardPeriod, ICardLimitSetting } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLine from '@/components/details-block/DetailsLine.vue'

const { t } = useI18n()

const props = defineProps<{
  cardLimitSetting: ICardLimitSetting
}>()

const currency = computed<ICurrency>(() => {
  return { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }
})

const payments = computed(() => {
  const periods = [ECardPeriod.Daily, ECardPeriod.Weekly, ECardPeriod.Monthly, ECardPeriod.Annually]
  return periods.map(period => props.cardLimitSetting?.payment?.[period]
    ? ({
        period,
        consumed: props.cardLimitSetting?.payment[period].consumed ?? 0,
        limit: props.cardLimitSetting?.payment[period].limit ?? 0,
        enabled: props.cardLimitSetting?.payment[period].enabled ?? false
      })
    : null)
})
</script>
