<template>
  <ValidationForm v-slot="{ isSubmitting }"
                  :validation-schema="schema"
                  @invalid-submit="onInvalidSubmit"
                  @submit="onSubmit">
    <div>
      <input-text v-model:model-value="values.email"
                  name="email"
                  data-cy="login.credentials.email"
                  :placeholder="t('placeholder.email')" />
      <input-text v-model:model-value="values.password"
                  name="password"
                  :type="passwordType"
                  data-cy="login.credentials.password"
                  :placeholder="t('placeholder.password')">
        <template #append>
          <core-icon :name="passwordVisibility ? ECoreIconBoList.BoEyeOff : ECoreIconBoList.BoEye"
                     :theme="ECoreIconTheme.GrayLow"
                     :size="16"
                     @click="togglePasswordType" />
        </template>
      </input-text>
    </div>
    <core-button :text="t('action.login')"
                 block
                 :loading="isSubmitting"
                 type="submit"
                 :size="ECoreButtonSize.Large"
                 data-cy="login.credentials.submit" />
  </ValidationForm>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreIcon,
  ECoreButtonSize,
  ECoreIconBoList,
  ECoreIconTheme
} from '@common/core-ui'
import * as yup from 'yup'

import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import InputText from '@/components/inputs/InputText.vue'

// DATA
const appStore = useAppStore()
const authStore = useAuthStore()
const { t } = useI18n()

const schema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required()
})

const values = reactive({
  email: import.meta.env.VITE_LOGIN_USER || '',
  password: import.meta.env.VITE_LOGIN_PASS || ''
})

function removeOnePasswordElements () {
  document.querySelector('com-1password-button')?.remove()
  document.querySelector('com-1password-menu')?.remove()
}

const passwordType = ref<'password' | 'text'>('password')

function togglePasswordType () {
  passwordType.value = (passwordType.value === 'password' ? 'text' : 'password')
}

const passwordVisibility = computed(() => {
  return passwordType.value === 'password'
})

function onInvalidSubmit () {
  appStore.showBannerError(t('error.login'))
}

async function onSubmit () {
  removeOnePasswordElements()
  await authStore.authWithCredentials(values.email, values.password)
}
</script>
