import { ECoreBannerTheme } from '@common/core-ui'

import { Nullable, TDatesRange } from '@/types/default.d'

export enum EBannerShow {
  LoginOnly = 'login_only',
  All = 'all'
}

export interface IBanner {
  id?: string
  show_web?: EBannerShow
  closable?: boolean
  auto_dismiss?: boolean
  link?: Nullable<string>
  type?: ECoreBannerTheme
  message: string
  dates?: TDatesRange<Nullable<string>>
}
