<template>
  <main class="section-body">
    <slot name="body" />
  </main>
</template>

<style scoped lang="stylus">
.section-body
    padding 4rem

    &:has(.page-loading)
      height 100%
</style>
