<template>
  <div v-if="title"
       class="details-line"
       :style="lineStyle">
    <details-label v-if="title"
                   :title="title" />
    <details-data v-if="data && !$slots.data"
                  :data="data"
                  :clickable="clickable"
                  :exceeded="exceeded"
                  :total="total"
                  @click="$emit('click')">
      <template v-if="$slots.icon"
                #icon>
        <slot name="icon" />
      </template>
    </details-data>
    <slot name="data" />
  </div>
  <div v-else
       class="details-line details-line--content"
       :class="{ 'details-line--param': $slots.param }"
       :style="lineStyle">
    <div class="content">
      <slot name="content" />
    </div>
    <div v-if="$slots.param"
         class="param">
      <slot name="param" />
    </div>
  </div>
</template>

<script setup lang="ts">
import DetailsData from '@/components/details-block/DetailsData.vue'
import DetailsLabel from '@/components/details-block/DetailsLabel.vue'

const props = defineProps<{
  title?: string
  data?: Array<string | number | undefined>
  total?: Array<string | number | undefined>
  clickable ?: boolean
  exceeded?: boolean
  height?: string
}>()

defineEmits(['click'])

const lineStyle = props.height ? `height: ${props.height}` : ''
</script>

<style scoped lang="stylus">
.details-line
  display grid
  grid-template-columns 1fr 1fr
  height 4.2rem
  align-items center

  &--content
    grid-template-columns 1fr

    .content
      padding-right 3.2rem
    .param
      display flex
      &:deep(> *)
        margin-left auto
        max-width 30rem

  &--param
    grid-template-columns 1fr 1fr
</style>
