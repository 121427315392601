<template>
  <div class="card-settings-nav">
    <core-vertical-tabs :items="formattedItems" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteRecordRaw } from 'vue-router'
import { CoreVerticalTabs } from '@common/core-ui'

const { t } = useI18n()

const props = defineProps<{
  navItems: RouteRecordRaw[]
}>()

const formattedItems = computed(() => props.navItems
  .map(item => ({
    label: t(`menu.${item.meta?.nav?.label}`),
    routeName: item.name as string
  })))
</script>

<style scoped lang="stylus">
.card-settings-nav
  min-width 22rem
  margin-right 4rem
</style>
