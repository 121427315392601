import { defineStore } from 'pinia'

import { IError } from '@/types/error.d'

interface State {
  abortControllers: AbortController[],
  deltaTime: number,
  error: IError | null,
  hideProgressBar: boolean,
  loading: boolean,
  openedRequests: number
}

export const useApiStore = defineStore('api', {
  state: (): State => {
    return {
      abortControllers: [],
      deltaTime: 0,
      hideProgressBar: false,
      error: null,
      loading: false,
      openedRequests: 0
    }
  },

  getters: {
    hasOpenRequests: state => state.openedRequests !== 0
  },

  actions: {
    abortAllRequests () {
      while (this.abortControllers.length) {
        this.abortControllers?.shift()?.abort()
      }
    },

    addAbortController () {
      const controller = new AbortController()
      this.abortControllers.push(controller)
      return controller
    },

    addError (error: IError) {
      this.error = error
    },

    decrementOpenedRequests () {
      this.openedRequests--
    },

    incrementOpenedRequests () {
      this.openedRequests++
    },

    removeAbortController (signal: AbortSignal) {
      const abortControllerIndex = this.abortControllers.findIndex(controller => controller.signal === signal)
      if (abortControllerIndex !== -1) {
        this.abortControllers.splice(abortControllerIndex, 1)
      }
    },

    resetError () {
      this.error = null
    },

    toggleHideProgressBar (value: boolean) {
      this.hideProgressBar = value
    },

    setDeltaTime (deltaTime: number) {
      this.deltaTime = deltaTime
    },

    setLoading (value: boolean) {
      this.loading = value
    }
  }
})
