<template>
  <sidepanel-block :title="t('general.modification_details')"
                   collapsable>
    <template #content>
      <sidepanel-line :label="t('general.current_monthly_limit')"
                      :data-list="getLimitData(accountLimitSettingsUpdate.before)"
                      strikethrough />
      <sidepanel-line :label="t('general.new_monthly_limit')"
                      :data-list="getLimitData(accountLimitSettingsUpdate.after)" />
    </template>
  </sidepanel-block>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import hasPermission from '@/helpers/permissions'
import { formatAmount, formatPercentage } from '@/helpers/utils/number'
import { IAccountSettings } from '@/types/account.d'
import { EPermission } from '@/types/permission.d'

import SidepanelBlock from '@/components/sidepanel/SidepanelBlock.vue'
import SidepanelLine from '@/components/sidepanel/SidepanelLine.vue'

const currency = { iso: 'EUR', label: 'Euro', fraction_digits: 2, flag_url: 'none' }

type TAccountLimitSettingsUpdate = {
  before: IAccountSettings
  after: IAccountSettings
}

const { t } = useI18n()

defineProps<{
  accountLimitSettingsUpdate: TAccountLimitSettingsUpdate
}>()

function formatPercentageText (amount: number, enabled = true, disabledText = ''): string {
  if (!enabled) return disabledText
  return formatPercentage(amount)
}

function getLimitData (setting: IAccountSettings): {label: string, value: string}[] {
  const data = [
    {
      label: t('account.setting.limit.monthly_limit'),
      value: formatAmount(setting.transaction_amount_monthly_limit, currency)
    },
    {
      label: t('account.setting.limit.monthly_alert_threshold'),
      value: formatPercentageText(setting.transaction_amount_monthly_alert_threshold_percentage || 0, setting.transaction_amount_monthly_alert_threshold_percentage_enabled, t('general.off_m'))
    }
  ]
  if (hasPermission(EPermission.AccountTransactionAmountMonthlyExcessAllowedUpdate)) {
    data.push({
      label: t('account.setting.limit.monthly_excess_allowed'),
      value: formatPercentageText(setting.transaction_amount_monthly_excess_allowed_percentage || 0, setting.transaction_amount_monthly_excess_allowed_percentage_enabled, t('general.off_m'))
    })
  }
  return data
}
</script>
