<template>
  <core-banner-transition>
    <core-banner v-if="banner"
                 :key="banner.id"
                 data-cy="banner"
                 :text="banner.message"
                 :link="banner.link"
                 :closable="banner.closable"
                 :theme="banner.type"
                 @close="onClose()" />
  </core-banner-transition>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { CoreBanner, CoreBannerTransition } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { ModelBanner } from '@/models/Banner/ModelBanner'
import { useAppStore } from '@/stores/app'
const appStore = useAppStore()
const { banners } = storeToRefs(appStore)

const banner = ref<ModelBanner>()

function getBanner () {
  const priorityNotification = banners.value.find(banner => banner.auto_dismiss)
  const firstBanner = banners.value[0]
  const nonClosable = banners.value.find(banner => !banner.closable)

  banner.value =
      priorityNotification as ModelBanner ||
      firstBanner as ModelBanner ||
      nonClosable as ModelBanner ||
      null
}

function onClose () {
  if (banner.value) {
    appStore.dismissBanner(banner.value.id)
  }
}

watch(() => banners.value.length, () => {
  getBanner()
})
</script>
