<template>
  <core-dropdown clearable
                 min-width="100%"
                 :model-value="transactionMonthlyLimit"
                 :size="ECoreDropdownSize.Small"
                 :items="getTransactionAmountMonthlyLimit()"
                 :placeholder="t('placeholder.transaction_amount_monthly_limit')"
                 @update:model-value="emit('update:transaction-monthly-limit', $event)" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CoreDropdown, ECoreDropdownSize, TDropdownValue } from '@common/core-ui'

import { ETransactionMonthlyLimit } from '@/types/account.d'

const { t } = useI18n()

defineProps<{
  transactionMonthlyLimit?: string
}>()

const emit = defineEmits<{
  'update:transaction-monthly-limit': [value: TDropdownValue]
}>()

function getTransactionAmountMonthlyLimit () {
  return Object.entries(ETransactionMonthlyLimit).map(([, value], index) => {
    return { id: index, label: t(`transaction_amount_monthly_limit.${value}`), value }
  })
}
</script>
