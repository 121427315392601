import { EPhysicalCardType, EVirtualCardType } from '@/types/card.d'
import { EInvitationStatus } from '@/types/invitation.d'
import { IParams } from '@/types/params.d'

import { IAccountCompany } from './account.d'
import { IFile } from './file.d'
import { EPermission } from './permission.d'

export enum EUserAccess {
  AccountDocuments = 'account_documents',
  Accounting = 'accounting',
  Beneficiaries = 'beneficiaries',
  Cards = 'cards',
  CardHolder = 'card_holder',
  CardVirtualRequestUser = 'card_virtual_request_user',
  Credits = 'credits',
  Dashboard = 'dashboard',
  Debits = 'debits',
  ExpenseRequests = 'expense_requests',
  Mileages = 'mileages',
  Operations = 'operations',
  Owner = 'owner',
  PhysicalCards = 'physical_cards',
  PhysicalCardsRead = 'physical_cards_read',
  PhysicalCardsWrite = 'physical_cards_write',
  Refunds = 'refunds',
  SharedAccessRead = 'shared_access_read',
  Statements = 'statements',
  Transfers = 'transfers',
  Treasury = 'treasury',
  VirtualCards = 'virtual_cards',
  VirtualCardsRead = 'virtual_cards_read',
  VirtualCardsWrite = 'virtual_cards_write',
}

export enum EUserStatus {
  Accepted = 'accepted',
  Active = 'active',
  AwaitingProof = 'awaiting_proof',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  Done = 'done',
  InProgress = 'in_progress',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  ToApprove = 'to_approve',
  ToValidate = 'to_validate',
}

export enum EUserGender {
  Mr = 1,
  Mrs = 2,
  Other = 3,
}

export interface IUserRights {
  accounting: boolean
  card_virtual_request_user: boolean
  cards: boolean
  operations: boolean
  owner: boolean
  shared_access: boolean
  statements?: boolean
  [key: string]: boolean | undefined
}

export interface IUserLight {
  first_name: string
  full_name: string
  last_name: string
  email: string
  uuid: string
}

export interface IUserParams extends IParams {
  account_uuid: string
  search: string
  status?: EUserStatus[]
}

export interface IUser {
  uuid: string
  first_name: string
  last_name: string
  full_name: string
  email: string
  rights: IUserRights
  counts: {
    cards: {
      types: {
        [EPhysicalCardType | EVirtualCardType]: {
          total: number
          active: number
        }
      }
    }
  }
  is_main_owner: boolean
  status: EUserStatus
}

export interface IUserDetails extends IUser {
  gender: EUserGender | null
  phone: string | null
  birth_date: string | null
  identity_card?: IFile | null
  identity_card_documents?: IFile[] | null
  created_at: number
  last_login_at: number | null
  company: IAccountCompany
  permissions: EPermission[]
}

export interface IUserData {
  uuid: string
  name: string
  email: string
  cardsCount: number
  accessRights: IUserRights
  isMainOwner: boolean
  status: EUserStatus | EInvitationStatus
  clickable: boolean
}
