import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { IWorldlineFeeParams, IWorldlineFeeResponse } from '@/types/worldline.d'

export const useWorldlineStore = defineStore('worldline', {

  actions: {
    async getFees (params: IWorldlineFeeParams): Promise<{data: IWorldlineFeeResponse, error: unknown}> {
      try {
        const { data } = await axiosClient.get('/worldline/fees', { params })

        return { data, error: null }
      } catch (error) {
        return { data: {}, error }
      }
    }

  }
})
