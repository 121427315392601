<template>
  <details-block data-cy="card.settings.advanced"
                 class="card-advanced-setting"
                 :title="t('menu.advanced_settings')"
                 :loading="loading && !advancedSettingModel">
    <template v-if="advancedSettingModel"
              #body>
      <div class="card-advanced-setting__content">
        <div>
          <details-label :title="t('card.setting.blocked_keywords')" />
          <list-block v-model:model-value="advancedSettingModel.blocked_keywords"
                      :placeholder="t('placeholder.no_keyword')"
                      :search-placeholder="t('placeholder.type_keyword')"
                      uppercase
                      :disabled="isDisabled" />
        </div>
        <div>
          <details-label :title="t('card.setting.authorize_merchants')" />
          <list-block v-model:model-value="advancedSettingModel.authorization_merchants"
                      :placeholder="t('placeholder.no_merchant')"
                      :search-placeholder="t('placeholder.type_merchant_name')"
                      uppercase
                      :disabled="isDisabled" />
        </div>
      </div>

      <div class="card-advanced-setting__content">
        <div>
          <details-label :title="t('card.setting.blocked_countries')" />
          <list-block v-model:model-value="advancedSettingModel.blocked_countries"
                      :placeholder="t('placeholder.no_country')"
                      :items="countryList"
                      :search-filter="filterCountryList"
                      :search-placeholder="t('placeholder.type_country')"
                      uppercase
                      :disabled="isDisabled">
            <template #available-item="{ item }">
              <core-country-flag-icon :name="item as ECoreCountryFlagIconList" />
              {{ getCountryName(item) }}
            </template>
            <template #item="{ item }">
              <core-country-flag-icon :name="item as ECoreCountryFlagIconList" />
              {{ getCountryName(item) }}
            </template>
          </list-block>
        </div>
        <div>
          <details-label :title="t('card.setting.check_countries')" />
          <list-block v-model:model-value="advancedSettingModel.check_countries"
                      :placeholder="t('placeholder.no_country')"
                      :items="countryList"
                      :search-filter="filterCountryList"
                      :search-placeholder="t('placeholder.type_country')"
                      uppercase
                      :disabled="isDisabled">
            <template #available-item="{ item }">
              <core-country-flag-icon :name="item as ECoreCountryFlagIconList" />
              {{ getCountryName(item) }}
            </template>
            <template #item="{ item }">
              <core-country-flag-icon :name="item as ECoreCountryFlagIconList" />
              {{ getCountryName(item) }}
            </template>
          </list-block>
        </div>
      </div>

      <div>
        <card-limit-item v-model:model-value="advancedSettingModel.auto_block_spent"
                         :label="t('card.setting.auto_block_spent')"
                         :input-placeholder="t('placeholder.no_limit')"
                         :disabled="isDisabled">
          <template #input-append>
            {{ getCurrencySymbol(currency) }}
          </template>
        </card-limit-item>
      </div>
      <div>
        <details-label :title="t('card.setting.limit_transaction')" />
        <card-limit-item v-for="(item, period) in advancedSettingModel.limit_transaction"
                         :key="`${period}-payment`"
                         v-model:model-value="advancedSettingModel.limit_transaction[period]"
                         :label="t(`general.${period}`)"
                         :input-placeholder="t('placeholder.no_limit')"
                         :disabled="isDisabled" />
      </div>
    </template>
    <template v-if="isSaveButtonVisible"
              #footer-right>
      <core-button :text="t('action.cancel')"
                   :theme="ECoreButtonTheme.Transparent"
                   class="mr-2"
                   @click="cancel" />
      <core-button :text="t('action.save')"
                   :loading="loading"
                   @click="saveAdvancedSetting" />
    </template>
  </details-block>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreButton, CoreCountryFlagIcon, ECoreButtonTheme, ECoreCountryFlagIconList } from '@common/core-ui'
import _ from 'lodash'

import hasPermission from '@/helpers/permissions'
import { countryList, getCountryName } from '@/helpers/utils/country'
import { getCurrencySymbol } from '@/helpers/utils/number'
import { useCardStore } from '@/stores/card'
import { ICardAdvancedSetting, ICardDetails } from '@/types/card.d'
import { ICurrency } from '@/types/currency.d'
import { EPermission } from '@/types/permission.d'

import CardLimitItem from '@/pages/cards/card-id/tabs/settings/blocks/components/CardLimitItem.vue'
import ListBlock from '@/pages/cards/card-id/tabs/settings/blocks/components/ListBlock.vue'

import DetailsBlock from '@/components/details-block/DetailsBlock.vue'
import DetailsLabel from '@/components/details-block/DetailsLabel.vue'

const cardStore = useCardStore()
const { t } = useI18n()

const props = defineProps<{
  cardUuid: string
  cardDetails: ICardDetails
  currency: ICurrency
}>()

const loading = ref<boolean>(true)
const advancedSetting = ref<ICardAdvancedSetting | null>(null)
const advancedSettingModel = ref<ICardAdvancedSetting | null>(null)

onMounted(async () => {
  advancedSetting.value = await cardStore.getCardAdvancedSetting(props.cardUuid)
  advancedSettingModel.value = _.cloneDeep(advancedSetting.value)
  loading.value = false
})

const isDisabled = computed(() => {
  return !hasPermission(EPermission.CardSettingAdvancedUpdate, props.cardDetails)
})

const isSaveButtonVisible = computed(() => {
  return !_.isEqual(advancedSetting.value, advancedSettingModel.value) && !isDisabled.value
})

function filterCountryList (countryList: string[], input: string) {
  if (input.length < 3) return []
  return countryList.filter(country => {
    const countryName = getCountryName(country) || ''
    return countryName ? countryName.toLowerCase().match(input.toLowerCase()) : false
  })
}

function cancel () {
  advancedSettingModel.value = _.cloneDeep(advancedSetting.value)
}

async function saveAdvancedSetting () {
  if (!advancedSettingModel.value) return
  loading.value = true
  const data = await cardStore.updateCardAdvancedSetting(props.cardUuid, advancedSettingModel.value)
  if (data) {
    advancedSetting.value = data
    advancedSettingModel.value = _.cloneDeep(advancedSetting.value)
  }
  loading.value = false
}
</script>

<style scoped lang="stylus">
.card-advanced-setting__content
  vertical-split(1.6rem, false)
  > div
    width 0

  .list-block
    .core-country-flag-ic
      vertical-align text-bottom
      margin-right 1.2rem
</style>
